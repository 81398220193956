import React from "react";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../utils/utils";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthTokens,
  setUser,
  setUserDetails,
} from "../reducers/authReducer";
import { jwtDecode } from "jwt-decode";

const MicrosoftLogin = (props) => {
  const { instance } = useMsal();
  const history = useHistory();
  const { setLoginError, setIsLoggedIn, setSocialLoggingIn, redir } = props;
  const userDetails = useSelector((state) => state.auth.userDetails);
  const dispatch = useDispatch();
  const Login = () => {
    instance
      .loginPopup({
        scopes: ["User.Read"],
        prompt: "select_account",
      })
      .then((response) => {
        setLoginError("Logging in with Microsoft...");
        setSocialLoggingIn(true);
        const accessToken = response.accessToken;

        fetch(baseUrl + "api/microsoft/login/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            access_token: accessToken,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data && data.access && data.refresh) {
              dispatch(setAuthTokens(data));
              dispatch(setUser(jwtDecode(data.access)));
              setIsLoggedIn(true);
              if (redir && userDetails) {
                history.push(redir);
              } else {
                dispatch(setUserDetails(null));
                setTimeout(() => history.push("/welcome"), 800);
              }
            } else if (data && data.error) {
              setLoginError(data.error);
              setSocialLoggingIn(false);
            }
          })
          .catch((err) => {
            setLoginError("Error on Microsoft login request");
            setSocialLoggingIn(false);
            console.log(err);
          });
      })
      .catch((err) => {
        setLoginError("Error on Microsoft login request");
        setSocialLoggingIn(false);
        console.log(err);
      });
  };

  return <i className="fab fa-microsoft" onClick={Login} />;
};

export default MicrosoftLogin;

MicrosoftLogin.propTypes = {
  setLoginError: PropTypes.func.isRequired,
  setIsLoggedIn: PropTypes.func.isRequired,
  setSocialLoggingIn: PropTypes.func.isRequired,
  redir: PropTypes.string,
};
