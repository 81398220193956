import { grayColor } from "assets/jss/material-dashboard-pro-react";

const actionBarStyle = () => ({
  actionBarWrapper: {
    position: "absolute",
    zIndex: "1030",
    top: "-10px",
    left: "50%",
    width: "auto",
    transform: "translate(-50%, 0)",
    display: "inline",
    marginBottom: "0",
  },
  actionBarCardBody: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  actionsBarSpinnerWrapper: {
    position: "absolute",
    left: "0",
    width: "100%",
    height: "100%",
    borderRadius: "6px",
    backgroundColor: "#ffffffaa",
  },
  actionsBarSpinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  actionBarSmWrapper: {
    marginTop: "0",
    marginBottom: "0",
  },
  actionBarSmCardBody: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  actionBarSmCardName: {
    color: grayColor[0],
    "& span:first-child": {
      lineHeight: "2.5",
    },
  },
  actionBarSmSpinner: {
    position: "absolute",
    top: "50%",
    right: "20px",
    transform: "translate(0, -50%)",
  },
});

export default actionBarStyle;
