import {
  cardTitle,
  grayColor,
  warningColor,
} from "assets/jss/material-dashboard-pro-react.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";

const userManagmentStyle = (theme, clientColor) => ({
  ...customSelectStyle(theme, clientColor),
  ...customInputStyle(clientColor),

  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardButton: {
    padding: "0 !important",
    margin: "15px 0 0 10px !important",
    height: "22px !important",
    width: "30px !important",
    minWidth: "0 !important",
    position: "relative",
    transition: "all 0.2s",
    backgroundColor: clientColor + " !important",
  },
  cardButtonIcon: {
    margin: "0 !important",
    position: "absolute !important",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%, -50%)",
    width: "18px !important",
    height: "18px !important",
    color: "#FFFFFF",
  },

  actionDisabled: {
    opacity: "0.5 !important",
    pointerEvents: "none",
  },

  toolTipRole: {
    fontSize: "1.1em",
    fontWeight: "bold",
  },
  toolTipDescription: {
    paddingLeft: "1em",
    display: "block",
    fontWeight: "100 !important",
  },

  "@keyframes loadingAnimation": {
    "0%": { backgroundColor: grayColor[4] },
    "25%": { backgroundColor: grayColor[3] },
    "50%": { backgroundColor: grayColor[4] },
    "75%": { backgroundColor: grayColor[3] },
    "100%": { backgroundColor: grayColor[4] },
  },
  loadingAnimation: {
    animation: "$loadingAnimation 4.0s ease-in-out infinite",
  },

  selectFormControlOverride: {
    margin: "3px 0 5px 0 !important",
    paddingTop: "1px",
    paddingBottom: "1px",
    "& > div": {
      "&:before": {
        borderBottomWidth: "0.8px !important",
      },
    },
  },

  tableHeadCell: {
    fontWeight: "300",
    fontSize: "15px",
  },
  tableBodyCell: {
    fontSize: "12px !important",
    fontWeight: "300",
    borderBottom: `1px solid rgba(0, 0, 0, 0.05)`,
  },
  compactTable: {
    "& .MuiTableCell-root": {
      padding: "1px 1px !important",
      lineHeight: "0.1 !important",
      height: "5px !important",
      //overflow: "hidden",
    },
    "& .MuiTableRow-root": {
      height: "40px",
    },
  },
  tableSpinner: {
    height: "100%",
    paddingTop: "4px",
    "& > svg": {
      width: "40px !important",
    },
  },

  attention: {
    backgroundColor: warningColor[0] + " !important",
    animation: "$attention 3s ease infinite",
  },
  "@keyframes attention": {
    "10%": {
      transform: "scale(1.1)",
    },
    "13.33%,20%": {
      transform: "rotate(-10deg) scale(1.1)",
    },
    "16.67%": {
      transform: "rotate(10deg) scale(1.1)",
    },
    "23.33%": {
      transform: "rotate(0deg) scale(1.1)",
    },
    "33%": {
      transform: "scale(1)",
    },
  },
});

export default userManagmentStyle;
