import {
  container,
  cardTitle,
  integrationColor,
  whiteColor,
  grayColor,
  defaultFont,
  dangerColor,
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = (theme) => ({
  ...integrationColor,
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
    },
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.75rem 0",
  },
  loginErrorMessage: {
    ...defaultFont,
    minHeight: "22px",
    marginTop: "5px",
    textAlign: "center",
    fontSize: "14px",
    color: dangerColor[0],
    "&.nonCritical": {
      color: grayColor[0],
    },
  },
  buttonDisabled: {
    opacity: "0.65",
    cursor: "wait !important",
  },
  invisible: {
    visibility: "hidden",
  },
  preSplash: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    background: "#eee",
    opacity: "0",
    zIndex: "-1",
    transition: "opacity 600ms",
    "&.loggedIn": {
      opacity: "1",
      zIndex: "9999",
    },
  },
});

export default loginPageStyle;
