import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    authTokens: null,
    user: null,
    userDetails: null,
  },
  reducers: {
    setAuthTokens(state, action) {
      state.authTokens = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setUserDetails(state, action) {
      state.userDetails = action.payload;
    },
  },
});

export const { setAuthTokens, setUser, setUserDetails } = authSlice.actions;

export default authSlice.reducer;
