import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import AuthContext from "../../contexts/AuthProvider";
import { client } from "../../services/axiosClient";
import axios from "axios";
import "moment/locale/es";
import "moment/locale/pt";
import cx from "classnames";
import { Slide, Slide as ToastSlide, toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import qs from "query-string";
import { getOom } from "../../utils/utils";
import { useScreenshot, createFileName } from "use-react-screenshot";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowRightArrowLeft,
  faArrowTrendDown,
  faArrowTrendUp,
  faCaretDown,
  faCaretUp,
  faChevronDown,
  faChevronUp,
  faClone,
  faFilter,
  faPenToSquare,
  faX,
} from "@fortawesome/free-solid-svg-icons";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import {
  AddCircle,
  AttachMoney,
  BookmarkBorder,
  GetApp,
  HelpOutline,
  PhotoCamera,
  Sync,
} from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import FilterTag from "components/FilterTag/FilterTag";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import ActionDropdown from "components/ActionDropdown/ActionDropdown";
import CustomInput from "components/CustomInput/CustomInput";
import ActionBar from "components/ActionBar/ActionBar";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";

import styles from "../../assets/jss/material-dashboard-pro-react/views/plExplorerStyle";
import { grayColor } from "../../assets/jss/material-dashboard-pro-react";
import ChartistGraph from "react-chartist";
import { linePercentageChart } from "../../variables/charts";

library.add(
  faChevronUp,
  faChevronDown,
  faCaretUp,
  faCaretDown,
  faPenToSquare,
  faFilter,
  faClone,
  faArrowRightArrowLeft,
  faX,
  faArrowTrendUp,
  faArrowTrendDown,
);

const maxPLs = 6;
const refreshPLDelay = 500;

const stripText = (text) => {
  return text.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
};

const orderedScenarios = (scn) => {
  return scn.sort(function (a, b) {
    let x = a["name"];
    let y = b["name"];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

const getIndexes = (filters, values) => {
  return values.map((item) => {
    return filters.indexOf(item);
  });
};

export default function PLExplorer(props) {
  let { userDetails } = useContext(AuthContext);
  const client_styles = (theme) => ({
    ...styles(theme, userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();
  const formatter = new Intl.NumberFormat("us-EN");
  const { t, i18n } = useTranslation();
  // const { height, width } = useWindowDimensions();
  const { width } = useWindowDimensions();
  const get_params = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  const notify = (text, type = "info", time = 3000) =>
    toast(t(text), {
      position: "top-right",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      type: type,
      transition: ToastSlide,
    });

  const notifyCopy = () =>
    toast(t("results.scenarios.url_sent_to_clipboard"), {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      type: "info",
      transition: Slide,
    });

  const filtersList = useRef(["period", "client"]);
  const filtersNames = useRef({});
  const filtersItems = useRef({});
  const [filtersItemsAfterCommonFilters, setFiltersItemsAfterCommonFilters] =
    useState({});
  const filtersGroups = useRef({});
  const volumeUnit = useRef(null);
  const currencyUnitOptions = useRef([]);
  const plStructure = useRef([]);
  const netRevenueAnchor = useRef(null);
  const serveMarginAnchor = useRef(null);
  const revenueAnchors = useRef([]);
  const volumeL12M = useRef(null);
  const initialFiltersObjects = useRef({});
  const initialFiltersStrings = useRef({});

  const plNameInput = useRef(null);
  const scenarioNameInput = useRef(null);
  const timersAllowed = useRef(false);

  const [numPLs, setNumPLs] = useState(0);
  const [oom, setOom] = useState({
    div: 1e6,
    x: " " + t("oom.m"),
  });
  const [showValueByVolumeDecimals, setShowValueByVolumeDecimals] =
    useState(false);

  const [commonFilters, setCommonFilters] = useImmer({});
  const [commonFiltersIndexes, setCommonFiltersIndexes] = useImmer({});
  const [commonFiltersMode, setCommonFiltersMode] = useImmer({});
  const [commonFiltersSequence, setCommonFiltersSequence] = useState([
    "period",
  ]);
  const [plFilters, setPlFilters] = useImmer({});
  const [plFiltersIndexes, setPlFiltersIndexes] = useImmer({});
  const [plFiltersMode, setPlFiltersMode] = useImmer({});
  const [plFiltersSequence, setPlFiltersSequence] = useImmer({ 1: [] });

  const [currencyUnit, setCurrencyUnit] = useState(null);
  const [showValueType, setShowValueType] = useState("value");

  const [plScenarios, setPlScenarios] = useState([]);
  const [plsToRefresh, setPlsToRefresh] = useState([]);
  const [plsOnError, setPlsOnError] = useState([]);
  const [drillDownsOpen, setDrillDownsOpen] = useState([]);

  const [plNameModalOpen, setPlNameModalOpen] = useState(false);
  const [plNames, setPlNames] = useImmer({ 1: "" });
  const [plNameModalCurrentNum, setPLNameModalCurrentNum] = useState(0);
  const [plNameModalInput, setPlNameModalInput] = useState("");
  const [plShow, setPlShow] = useState([]);

  const [plValues, setPlValues] = useImmer({});

  const [currentPlScenario, setCurrentPlScenario] = useState(null);
  const [scenarioNameModalOpen, setScenarioNameModalOpen] = useState(false);
  const [scenarioNameModalInput, setScenarioNameModalInput] = useState("");
  const [scenarioSaveMode, setScenarioSaveMode] = useState("");
  const [scenarioBusy, setScenarioBusy] = useState(false);
  const [shareViewModalOpen, setShareViewModalOpen] = useState(false);
  const [loadingScenario, setLoadingScenario] = useState(false);
  const [metaLoaded, setMetaLoaded] = useState(false);

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filterModalScope, setFilterModalScope] = useState(null);
  const [filterModalCurrentPl, setFilterModalCurrentPl] = useState(0);
  const [filterModalCurrentFilter, setFilterModalCurrentFilter] = useState("");
  const [filterModalHeader, setFilterModalHeader] = useState("");
  const [filterModalFilterName, setFilterModalFilterName] = useState("");
  const [filterModalItemsChoices, setFilterModalItemsChoices] = useState([]);
  const [filterModalItemsSelected, setFilterModalItemsSelected] = useState([]);
  const [filterModalGroupChoices, setFilterModalGroupChoices] = useState([]);
  const [filterModalGroupSelected, setFilterModalGroupSelected] = useState("");
  const [filterModalFilterMode, setFilterModalFilterMode] = useState("");

  const [refreshDelayHandler, setRefreshDelayHandler] = useImmer({
    1: {
      delay_handler: null,
      controller: new AbortController(),
    },
  });

  const [mouseOverPLLine, setMouseOverPLLine] = useState(null);
  const [mouseOverVal, setMouseOverVal] = useState(null);
  const [mouseOverPLLineFade, setMouseOverPLLineFade] = useState(null);
  const [delayHandler, setDelayHandler] = useState(null);

  const [plValDetailsPl, setPlValDetailsPl] = useState(1);
  const [plValDetailsName, setPlValDetailsName] = useState(null);
  const [delayHandlerDetails, setDelayHandlerDetails] = useState(null);
  const [plValDetailsVars, setPlValDetailsVars] = useState([]);
  const [plValDetailsPromoters, setPlValDetailsPromoters] = useState({});
  const [plValDetailsDetractors, setPlValDetailsDetractors] = useState({});
  const [detailsMonthlyEvolution, setDetailsMonthlyEvolution] = useState({
    ...linePercentageChart,
    data: {
      labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
    },
  });
  const [loadingPlValDetails, setLoadingPlValDetails] = useState(false);

  const resultsRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [image, takeScreenShot] = useScreenshot({
    type: "image/png",
    quality: 1.0,
  });
  const download = (
    image,
    { name = "results_screenshot", extension = "png" } = {},
  ) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };
  const downloadScreenshot = () =>
    takeScreenShot(resultsRef.current).then(download);

  useEffect(() => {
    i18n.changeLanguage(i18n.language.slice(0, 2));
    let data;
    try {
      data = get_params?.scenario
        ? JSON.parse(atob(get_params.scenario))
        : null;
      if (data) setLoadingScenario(true);
    } catch (_) {
      notify(t("results.scenarios.invalid_scenario_string"), "error", 5000);
    }
    client
      .post("api/results_pl_explorer_meta/")
      .then((response) => {
        // filters
        const filters = response.data.filters;
        filtersList.current = filters.list;
        filtersNames.current = filters.names;
        filtersItems.current = filters.items;
        setFiltersItemsAfterCommonFilters(filters.items);
        filtersGroups.current = filters.groups;
        let initialCommonFiltersObjects = {};
        let initialCommonFiltersStrings = {};
        Object.keys(filters.names).forEach((name) => {
          initialCommonFiltersObjects[name] = [];
          initialCommonFiltersStrings[name] = "";
        });

        initialFiltersObjects.current = initialCommonFiltersObjects;
        initialFiltersStrings.current = initialCommonFiltersStrings;

        if (data) {
          setCommonFilters(data.commonFilters);
          setCommonFiltersMode(data.commonFiltersMode);
          setCommonFiltersSequence(data.commonFiltersSequence);
          let common_filters_indexes = {};
          filters.list.forEach((filter) => {
            if (data.commonFiltersMode[filter] === "group") {
              common_filters_indexes[filter] = getIndexes(
                Object.keys(filters.groups[filter]),
                data.commonFilters[filter],
              );
            } else if (data.commonFiltersMode[filter] === "items") {
              common_filters_indexes[filter] = getIndexes(
                filters.items[filter],
                data.commonFilters[filter],
              );
            } else {
              common_filters_indexes[filter] = [];
            }
          });
          setCommonFiltersIndexes(common_filters_indexes);
          setPlFilters(data.plFilters);
          setPlFiltersMode(data.plFiltersMode);
          setPlFiltersSequence(data.plFiltersSequence);
          let pl_filters_indexes = {};
          Object.keys(data.plFilters).forEach((numPL) => {
            pl_filters_indexes[numPL] = {};
            filters.list.forEach((filter) => {
              if (data.plFiltersMode[numPL][filter] === "group") {
                pl_filters_indexes[numPL][filter] = getIndexes(
                  Object.keys(filters.groups[filter]),
                  data.plFilters[numPL][filter],
                );
              } else if (data.plFiltersMode[numPL][filter] === "items") {
                pl_filters_indexes[numPL][filter] = getIndexes(
                  filters.items[filter],
                  data.plFilters[numPL][filter],
                );
              } else {
                pl_filters_indexes[numPL][filter] = [];
              }
            });
          });
          setPlFiltersIndexes(pl_filters_indexes);
        } else {
          setCommonFilters((draft) => {
            Object.entries(initialCommonFiltersObjects).forEach(
              ([key, val]) => (draft[key] = key === "period" ? ["l12m"] : val),
            );
          });
          setCommonFiltersMode((draft) => {
            Object.entries(initialCommonFiltersStrings).forEach(
              ([key, val]) => (draft[key] = key === "period" ? "group" : val),
            );
          });
          setCommonFiltersIndexes((draft) => {
            Object.entries(initialCommonFiltersObjects).forEach(
              ([key, val]) => (draft[key] = key === "period" ? 0 : val),
            );
          });
          setPlFilters((draft) => {
            draft[1] = initialCommonFiltersObjects;
          });
          setPlFiltersMode((draft) => {
            draft[1] = initialCommonFiltersStrings;
          });
          setPlFiltersIndexes((draft) => {
            draft[1] = initialCommonFiltersObjects;
          });
        }

        // p&L
        const pl = response.data.pl;
        volumeUnit.current = pl.volume_unit;
        currencyUnitOptions.current = pl.currency_unit_options;
        plStructure.current = pl.pl_structure;
        netRevenueAnchor.current = pl.net_revenue_anchor;
        serveMarginAnchor.current = pl.serve_margin_anchor;
        revenueAnchors.current = pl.revenue_anchors;
        volumeL12M.current = pl.volume_l12m;
        if (width >= 1536) {
          // Set the drill-downs open if screen is XL at loading time
          let drillDowns = [];
          for (let i = 0; i < pl.pl_structure.length; i++) {
            if (pl.pl_structure[i].is_parent) {
              drillDowns.push(pl.pl_structure[i]["meta_name"]);
            }
          }
          setDrillDownsOpen(drillDowns);
        }
        if (data) {
          setCurrencyUnit(data.currencyUnit);
          setPlNames(data.plNames);
          setShowValueType(data.showValueType);
          setNumPLs(data.numPLs);
          let blank_objects = {};
          [...Array(data.numPLs)].map((_, i) => (blank_objects[i + 1] = {}));
          setPlValues(blank_objects);
          setPlsToRefresh([...Array(data.numPLs)].map((_, i) => i + 1));
        } else {
          setCurrencyUnit(pl.default_currency_unit);
          setNumPLs(1);
          setPlValues({ 1: {} });
          setPlsToRefresh([1]);
        }
        // scenarios
        setPlScenarios(response.data.scenarios);

        setMetaLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    [...Array(numPLs)].map((_, i) =>
      refreshPL(
        i + 1,
        commonFilters,
        commonFiltersMode,
        commonFiltersSequence,
        plFilters[i + 1],
        plFiltersMode[i + 1],
        plFiltersSequence[i + 1],
        true,
      ),
    );
    setPlShow([...Array(numPLs).keys()].map((i) => i + 1));
  }, [metaLoaded]);
  useLayoutEffect(() => {
    // changes the order of magnitude in which all the P&Ls are presented
    //    - fired when the plValues or language is modified
    //    - store results in 'oom' object
    let serve_margins = [];
    let serve_margins_per_volume = [];
    let median = 0;
    let median_per_volume = 0;
    Object.values(plValues).forEach((value) => {
      if (Object.keys(value).includes(serveMarginAnchor.current)) {
        serve_margins.push(value[serveMarginAnchor.current]);
        serve_margins_per_volume.push(
          value[serveMarginAnchor.current] / value["volume"],
        );
      }
    });
    if (serve_margins.length > 0) {
      const sorted = Array.from(serve_margins).sort((a, b) => a - b);
      const sorted_per_volume = Array.from(serve_margins_per_volume).sort(
        (a, b) => a - b,
      );
      const middle = Math.floor(sorted.length / 2);
      const middle_per_volume = Math.floor(sorted_per_volume.length / 2);
      median =
        sorted.length % 2 === 0
          ? (sorted[middle - 1] + sorted[middle]) / 2
          : sorted[middle];
      median_per_volume =
        sorted_per_volume.length % 2 === 0
          ? (sorted_per_volume[middle_per_volume - 1] +
              sorted_per_volume[middle_per_volume]) /
            2
          : sorted_per_volume[middle_per_volume];
    }
    let { div, x } = getOom(median);
    x = x === "" ? x : " " + t("oom." + x);
    setOom({ div: div, x: x });
    setShowValueByVolumeDecimals(median_per_volume < 100);
  }, [plValues, i18n.language]);
  useEffect(() => {
    if (loadingScenario && plsToRefresh.length === 0) {
      setScenarioBusy(false);
      setLoadingScenario(false);
      notify(t("results.scenarios.scenario_loaded"), "success", 4000);
    }
  }, [plsToRefresh]);
  useEffect(() => {
    // Update available filter items after applying utils filters
    if (metaLoaded) {
      let params = {
        currency_unit: currencyUnit,
        common_filters: commonFilters,
        common_filters_mode: commonFiltersMode,
        common_filters_sequence: commonFiltersSequence,
      };
      client
        .post("api/results_pl_explorer_update_available_items/", params)
        .then((response) => {
          setFiltersItemsAfterCommonFilters(response.data.items);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [commonFilters, currencyUnit]);

  const getPlPercentValue = (numPL, name) => {
    if (plValues[numPL][netRevenueAnchor.current]) {
      return (
        (
          (100 * plValues[numPL][name]) /
          plValues[numPL][netRevenueAnchor.current]
        ).toFixed(1) + "%"
      );
    } else {
      return "-";
    }
  };
  const getUnitOptions = () => {
    let options = [];
    currencyUnitOptions.current.map((opt) => {
      options.push({
        identified: {
          currency: opt,
          value_type: "value",
        },
        text: opt,
        active: opt === currencyUnit && showValueType === "value",
      });
    });
    options.push({ divider: true });
    currencyUnitOptions.current.map((opt) => {
      options.push({
        identified: {
          currency: opt,
          value_type: "per_unit",
        },
        text: opt + "/" + volumeUnit.current,
        active: opt === currencyUnit && showValueType === "per_unit",
      });
    });
    return options;
  };
  const handleUnitSelection = (opt) => {
    const { currency, value_type } = opt;
    if (value_type !== showValueType) {
      setShowValueType(value_type);
    }
    if (currency !== currencyUnit) {
      setCurrencyUnit(currency);
      setPlsToRefresh(Object.keys(plValues).map((val) => parseInt(val)));
      Object.keys(plValues).map((numPL) => {
        refreshPL(
          numPL,
          commonFilters,
          commonFiltersMode,
          commonFiltersSequence,
          plFilters[numPL],
          plFiltersMode[numPL],
          plFiltersSequence[numPL],
          true,
          currency,
        );
      });
    }
  };

  const getScenariosOptions = () => {
    let options = [];
    if (plScenarios.length > 0) {
      options.push({
        explainer: true,
        text: t("results.scenarios.saved_scenarios"),
      });
      orderedScenarios(plScenarios).forEach((scenario) => {
        options.push({
          identified: scenario.id,
          text: scenario.name,
          active: currentPlScenario && scenario.name === currentPlScenario.name,
        });
      });
    } else {
      options.push({
        explainer: true,
        text: t("results.scenarios.no_scenarios_saved"),
      });
    }
    options.push({ divider: true });
    options.push({
      identified: "action_save_scenario",
      text: t("results.scenarios.save_scenario"),
    });
    if (currentPlScenario) {
      options.push({
        identified: "action_save_scenario_as",
        text: t("results.scenarios.save_scenario_as"),
      });
      options.push({
        identified: "action_delete_scenario",
        text: t("results.scenarios.delete_scenario"),
      });
    } else {
      options.push({
        disabled: true,
        text: t("results.scenarios.save_scenario_as"),
      });
      options.push({
        disabled: true,
        text: t("results.scenarios.delete_scenario"),
      });
    }
    options.push({ divider: true });
    options.push({
      identified: "action_share_view",
      text: t("results.scenarios.share_view"),
    });
    return options;
  };
  const handleScenarioSelection = (id) => {
    if (id === "action_save_scenario") {
      setScenarioSaveMode("save");
      if (currentPlScenario) {
        setScenarioBusy(true);
        saveOrDeleteScenario(
          "save",
          currentPlScenario.name,
          currentPlScenario.id,
        );
      } else {
        setScenarioNameModalInput("");
        setScenarioNameModalOpen(true);
        if (scenarioNameInput.current)
          setTimeout(() => scenarioNameInput.current.firstChild.focus(), 100);
      }
    } else if (id === "action_save_scenario_as") {
      setScenarioSaveMode("save_as");
      setScenarioNameModalInput("");
      setScenarioNameModalOpen(true);
      if (scenarioNameInput.current)
        setTimeout(() => scenarioNameInput.current.firstChild.focus(), 100);
    } else if (id === "action_delete_scenario") {
      if (currentPlScenario) {
        setScenarioBusy(true);
        saveOrDeleteScenario(
          "delete",
          currentPlScenario.name,
          currentPlScenario.id,
        );
      }
    } else if (id === "action_share_view") {
      setShareViewModalOpen(true);
    } else {
      // load scenario
      let scn, data;
      plScenarios.forEach((scenario) => {
        if (scenario.id === id) {
          scn = scenario;
          data = JSON.parse(scenario.scenario_data);
        }
      });
      setCommonFilters(data.commonFilters);
      setCommonFiltersMode(data.commonFiltersMode);
      let common_filters_indexes = {};
      filtersList.current.forEach((filter) => {
        if (data.commonFiltersMode[filter] === "group") {
          common_filters_indexes[filter] = getIndexes(
            Object.keys(filtersGroups.current[filter]),
            data.commonFilters[filter],
          );
        } else if (data.commonFiltersMode[filter] === "items") {
          common_filters_indexes[filter] = getIndexes(
            filtersItems.current[filter],
            data.commonFilters[filter],
          );
        } else {
          common_filters_indexes[filter] = [];
        }
      });
      setCommonFiltersIndexes(common_filters_indexes);
      setCommonFiltersSequence(data.commonFiltersSequence);
      setPlFilters(data.plFilters);
      setPlFiltersMode(data.plFiltersMode);
      let pl_filters_indexes = {};
      Object.keys(data.plFilters).forEach((numPL) => {
        pl_filters_indexes[numPL] = {};
        filtersList.current.forEach((filter) => {
          if (data.plFiltersMode[numPL][filter] === "group") {
            pl_filters_indexes[numPL][filter] = getIndexes(
              Object.keys(filtersGroups.current[filter]),
              data.plFilters[numPL][filter],
            );
          } else if (data.plFiltersMode[numPL][filter] === "items") {
            pl_filters_indexes[numPL][filter] = getIndexes(
              filtersItems.current[filter],
              data.plFilters[numPL][filter],
            );
          } else {
            pl_filters_indexes[numPL][filter] = [];
          }
        });
      });
      setPlFiltersIndexes(pl_filters_indexes);
      setPlFiltersSequence(data.plFiltersSequence);
      setPlNames(data.plNames);
      setCurrencyUnit(data.currencyUnit);
      setShowValueType(data.showValueType);
      setNumPLs(data.numPLs);
      setPlShow([...Array(data.numPLs).keys()].map((i) => i + 1));
      setPlsToRefresh([...Array(data.numPLs)].map((_, i) => i + 1));
      setScenarioBusy(true);
      setLoadingScenario(true);
      let blank_objects = {};
      [...Array(data.numPLs)].map((_, i) => (blank_objects[i + 1] = {}));
      setPlValues(blank_objects);
      [...Array(data.numPLs)].map((_, i) => {
        refreshPL(
          i + 1,
          data.commonFilters,
          data.commonFiltersMode,
          data.commonFiltersSequence,
          data.plFilters[i + 1],
          data.plFiltersMode[i + 1],
          data.plFiltersSequence[i + 1],
          true,
          data.currencyUnit,
        );
      });
      setCurrentPlScenario(scn);
    }
  };
  const handleScenarioNameChange = (event) => {
    setScenarioNameModalInput(event.target.value);
  };
  const handleScenarioNameChangeApply = () => {
    let name = stripText(scenarioNameModalInput);
    let load = true;
    setScenarioNameModalInput(name);
    if (name === "") return false;
    plScenarios.forEach((scenario) => {
      if (scenario.name.toLowerCase() === name.toLowerCase()) {
        notify("results.scenarios.scenario_already_exists", "error", 5000);
        load = false;
      }
    });
    if (load) {
      setScenarioBusy(true);
      saveOrDeleteScenario(scenarioSaveMode, name, currentPlScenario?.id);
    }
  };
  const buildScenarioString = (toShare = false) => {
    let str = JSON.stringify({
      numPLs,
      commonFilters,
      commonFiltersMode,
      commonFiltersSequence,
      plFilters,
      plFiltersMode,
      plFiltersSequence,
      plNames,
      currencyUnit,
      showValueType,
    });
    if (toShare)
      str =
        window.location.href.split(/[?#]/)[0] +
        "?scenario=" +
        encodeURIComponent(btoa(str));
    return str;
  };
  const copyScenarioURL = () => {
    navigator.clipboard.writeText(buildScenarioString(true));
    notifyCopy();
  };
  const saveOrDeleteScenario = (action, name, id) => {
    let params = { action, name, id, scenario_data: "" };
    if (action === "save" || action === "save_as") {
      params.scenario_data = buildScenarioString();
    }
    client
      .post("api/results_pl_explorer_save_delete_scenario/", params)
      .then((response) => {
        if (response.data.success) {
          let new_scenarios = [];
          plScenarios.forEach((scenario) => {
            if (scenario.name.toLowerCase() !== name.toLowerCase())
              new_scenarios.push(scenario);
          });
          if (action === "save" || action === "save_as") {
            new_scenarios.push({
              name,
              id: response.data.id,
              scenario_data: params.scenario_data,
            });
            setPlScenarios(new_scenarios);
            setCurrentPlScenario({ name, id: response.data.id });
            setScenarioSaveMode("save");
            notify(t("results.scenarios.scenario_saved"));
          } else {
            setPlScenarios(new_scenarios);
            setCurrentPlScenario(null);
            setScenarioSaveMode("");
            notify(t("results.scenarios.scenario_deleted"));
          }
          setScenarioNameModalOpen(false);
        } else {
          notify(t("results.scenarios." + response.data.error), "error", 5000);
        }
        setScenarioBusy(false);
      })
      .catch((error) => {
        console.log(error);
        notify(t("results.scenarios.scenario_save_error"), "error", 5000);
        setScenarioBusy(false);
      });
  };

  const filtersListTranslated = () => {
    return filtersList.current.map((filter) => {
      if (Object.keys(filtersNames.current).includes(filter)) {
        return filtersNames.current[filter][i18n.language];
      } else {
        return filter;
      }
    });
  };
  const filtersActiveList = (scope, numPL) => {
    let activeList = [];
    if (scope === "common") {
      Object.keys(filtersNames.current).map((filter, key) => {
        if (commonFilters[filter].length > 0) activeList.push(key);
      });
    } else if (scope === "pl") {
      Object.keys(filtersNames.current).map((filter, key) => {
        if (plFilters[numPL][filter].length > 0) activeList.push(key);
      });
    }
    return activeList;
  };
  const filtersSequence = (numPL) => {
    if (numPL === "common") {
      return filtersList.current.map((filter) => {
        return commonFiltersSequence.indexOf(filter) === -1
          ? ""
          : commonFiltersSequence.indexOf(filter) > 9
            ? "N"
            : (commonFiltersSequence.indexOf(filter) + 1).toString();
      });
    } else {
      return filtersList.current.map((filter) => {
        return plFiltersSequence[numPL].indexOf(filter) === -1
          ? ""
          : plFiltersSequence[numPL].indexOf(filter) > 9
            ? "N"
            : (plFiltersSequence[numPL].indexOf(filter) + 1).toString();
      });
    }
  };
  const removeCommonFilter = (filter, item, index, all = false) => {
    let new_filter_items;
    if (filter === "period" && commonFiltersMode[filter] === "items") {
      let month_item = filtersItems.current[filter][index];
      new_filter_items = all
        ? []
        : commonFilters[filter].filter(
            (items) => JSON.stringify(items) !== JSON.stringify(month_item),
          );
    } else {
      new_filter_items = all
        ? []
        : commonFilters[filter].filter((items) => items !== item);
    }

    if (filter === "period" && new_filter_items.length === 0) {
      notify(
        "results.toasts.you_must_keep_at_least_one_period_filter",
        "warning",
      );
      return;
    }
    let common_filters = {
      ...commonFilters,
      [filter]: new_filter_items,
    };
    let common_filters_mode = commonFiltersMode;
    setCommonFilters((draft) => {
      draft[filter] = new_filter_items;
    });
    let common_filters_sequence = commonFiltersSequence;
    if (new_filter_items.length === 0) {
      setCommonFiltersIndexes((draft) => {
        draft[filter] = [];
      });
      common_filters_mode = {
        ...commonFiltersMode,
        [filter]: "",
      };
      setCommonFiltersMode((draft) => {
        draft[filter] = "";
      });
      common_filters_sequence = commonFiltersSequence.filter(
        (f) => f !== filter,
      );
      setCommonFiltersSequence(common_filters_sequence);
    } else {
      setCommonFiltersIndexes((draft) => {
        draft[filter] = commonFiltersIndexes[filter].filter(
          (indexes) => indexes !== index,
        );
      });
    }
    setPlsToRefresh(Object.keys(plValues).map((val) => parseInt(val)));
    Object.keys(plValues).map((numPL) => {
      refreshPL(
        numPL,
        common_filters,
        common_filters_mode,
        common_filters_sequence,
        plFilters[numPL],
        plFiltersMode[numPL],
        plFiltersSequence[numPL],
      );
    });
  };
  const getFilterItems = (raw_items, filter, mode) => {
    if (filter === "period" && mode === "items") {
      return raw_items.map((month) => {
        return (
          t("months." + month[1].toString()) +
          "-" +
          month[0].toString().slice(-2)
        );
      });
    } else {
      return raw_items;
    }
  };
  const filterMenuClickHandler = (scope, numPL) => (event) => {
    setFilterModalScope(scope);
    const selectedFilter =
      typeof event === "string" ? event : filtersList.current[event];
    setFilterModalCurrentFilter(selectedFilter);
    if (scope === "common") {
      setFilterModalHeader(t("results.common_filters"));
    } else if (scope === "pl") {
      setFilterModalHeader(
        t("results.pl_filters") +
          " (" +
          (plNames[numPL] !== "" ? plNames[numPL] : "P&L" + numPL) +
          ")",
      );
    }
    setFilterModalFilterName(
      filtersNames.current[selectedFilter][i18n.language],
    );
    if (Object.keys(filtersGroups.current).includes(selectedFilter)) {
      let choicesObject = filtersGroups.current[selectedFilter];
      let choicesTranslated = [];
      for (const [key, value] of Object.entries(choicesObject)) {
        choicesTranslated.push([key, value[i18n.language]]);
      }
      setFilterModalGroupChoices(choicesTranslated);
    } else {
      setFilterModalGroupChoices([]);
    }
    let choicesList = filtersItems.current[selectedFilter];
    if (selectedFilter === "period") {
      choicesList = choicesList.map((month) => {
        return (
          t("months." + month[1].toString()) +
          "-" +
          month[0].toString().slice(-2)
        );
      });
    }
    setFilterModalItemsChoices(choicesList);
    if (scope === "common") {
      setFilterModalFilterMode(commonFiltersMode[selectedFilter]);
      if (commonFiltersMode[selectedFilter] === "items") {
        setFilterModalGroupSelected("");
        setFilterModalItemsSelected(commonFiltersIndexes[selectedFilter]);
      } else if (commonFiltersMode[selectedFilter] === "group") {
        setFilterModalItemsSelected([]);
        setFilterModalGroupSelected(
          commonFilters[selectedFilter].length > 0
            ? commonFilters[selectedFilter][0]
            : "",
        );
      } else {
        setFilterModalGroupSelected("");
        setFilterModalItemsSelected([]);
      }
      setFilterModalCurrentPl(0);
    } else if (scope === "pl") {
      setFilterModalFilterMode(plFiltersMode[numPL][selectedFilter]);
      if (plFiltersMode[numPL][selectedFilter] === "items") {
        setFilterModalGroupSelected("");
        setFilterModalItemsSelected(plFiltersIndexes[numPL][selectedFilter]);
      } else if (plFiltersMode[numPL][selectedFilter] === "group") {
        setFilterModalItemsSelected([]);
        setFilterModalGroupSelected(
          plFilters[numPL][selectedFilter].length > 0
            ? plFilters[numPL][selectedFilter][0]
            : "",
        );
      } else {
        setFilterModalGroupSelected("");
        setFilterModalItemsSelected([]);
      }
      setFilterModalCurrentPl(numPL);
    }
    setFilterModalOpen(true);
  };
  const filterModalItemsMenuItems = () => {
    let root_class_base = classes.selectMenuItem;
    let selected_class_base = classes.selectMenuItemSelected;
    let root_class, selected_class;
    let menu_items = [];
    filterModalItemsChoices.map((item, key) => {
      let display_item = item === "" ? "● " + t("results.blank") + " ●" : item;
      let item_meta = filtersItems.current[filterModalCurrentFilter][key];
      let available = true;
      if (filterModalScope !== "common") {
        if (filterModalCurrentFilter === "period") {
          available = false;
          filtersItemsAfterCommonFilters["period"].forEach((i) => {
            if (JSON.stringify(i) === JSON.stringify(item_meta)) {
              available = true;
              return false;
            }
          });
        } else {
          available =
            filtersItemsAfterCommonFilters[filterModalCurrentFilter].includes(
              item_meta,
            );
        }
      }
      root_class = root_class_base + (available ? "" : " unavailable");
      selected_class = selected_class_base + (available ? "" : " unavailable");
      menu_items.push(
        <MenuItem
          classes={{
            root: root_class,
            selected: selected_class,
          }}
          value={key}
          key={key}
        >
          {display_item}
        </MenuItem>,
      );
    });
    return menu_items;
  };
  const filterModalGroupMenuItems = () => {
    return filterModalGroupChoices.map((item, key) => (
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        value={item[0]}
        key={key}
      >
        {item[1]}
      </MenuItem>
    ));
  };

  const handleFilterModalItemsMenuSelect = (event) => {
    setFilterModalFilterMode(event.target.value.length > 0 ? "items" : "");
    setFilterModalItemsSelected(event.target.value);
    setFilterModalGroupSelected("");
  };
  const handleFilterModalGroupMenuSelect = (event) => {
    setFilterModalFilterMode("group");
    setFilterModalGroupSelected(event.target.value);
    setFilterModalItemsSelected([]);
  };
  const handleFilterModalItemsSelectAll = () => {
    setFilterModalFilterMode("items");
    setFilterModalItemsSelected(
      filterModalItemsChoices.map((_, key) => {
        return key;
      }),
    );
    setFilterModalGroupSelected("");
  };
  const handleFilterModalItemsSelectNone = () => {
    setFilterModalItemsSelected([]);
    if (filterModalFilterMode === "items") setFilterModalFilterMode("");
  };
  const handleFilterModalItemsInvertSelection = () => {
    let newSelection = [];
    filterModalItemsChoices.map((_, key) => {
      if (!filterModalItemsSelected.includes(key)) newSelection.push(key);
    });
    if (newSelection.length > 0) {
      setFilterModalFilterMode("items");
      setFilterModalItemsSelected(newSelection);
      setFilterModalGroupSelected("");
    } else {
      setFilterModalItemsSelected([]);
      if (filterModalFilterMode === "items") setFilterModalFilterMode("");
    }
  };
  const handleFilterModalClearFilterButton = () => {
    if (
      filterModalCurrentFilter !== "period" &&
      filterModalScope === "common"
    ) {
      let common_filters = {
        ...commonFilters,
        [filterModalCurrentFilter]: [],
      };
      setCommonFilters((draft) => {
        draft[filterModalCurrentFilter] = [];
      });
      setCommonFiltersIndexes((draft) => {
        draft[filterModalCurrentFilter] = [];
      });
      let common_filters_mode = {
        ...commonFiltersMode,
        [filterModalCurrentFilter]: "",
      };
      setCommonFiltersMode((draft) => {
        draft[filterModalCurrentFilter] = "";
      });
      let common_filters_sequence = commonFiltersSequence.filter(
        (f) => f !== filterModalCurrentFilter,
      );
      setCommonFiltersSequence(common_filters_sequence);
      setPlsToRefresh(Object.keys(plValues).map((val) => parseInt(val)));
      Object.keys(plValues).map((numPL) => {
        refreshPL(
          numPL,
          common_filters,
          common_filters_mode,
          common_filters_sequence,
          plFilters[numPL],
          plFiltersMode[numPL],
          plFiltersSequence[numPL],
        );
      });
      setFilterModalOpen(false);
    } else if (filterModalScope === "pl") {
      let pl_filters = {
        ...plFilters[filterModalCurrentPl],
        [filterModalCurrentFilter]: [],
      };
      setPlFilters((draft) => {
        draft[filterModalCurrentPl][filterModalCurrentFilter] = [];
      });
      setPlFiltersIndexes((draft) => {
        draft[filterModalCurrentPl][filterModalCurrentFilter] = [];
      });
      let pl_filters_mode = {
        ...plFiltersMode[filterModalCurrentPl],
        [filterModalCurrentFilter]: "",
      };
      setPlFiltersMode((draft) => {
        draft[filterModalCurrentPl][filterModalCurrentFilter] = "";
      });
      let pl_filters_sequence = plFiltersSequence[filterModalCurrentPl].filter(
        (f) => f !== filterModalCurrentFilter,
      );
      setPlFiltersSequence((draft) => {
        draft[filterModalCurrentPl] = pl_filters_sequence;
      });
      setPlsToRefresh(
        plsToRefresh.includes(filterModalCurrentPl)
          ? plsToRefresh
          : [...plsToRefresh, filterModalCurrentPl],
      );
      refreshPL(
        filterModalCurrentPl,
        commonFilters,
        commonFiltersMode,
        commonFiltersSequence,
        pl_filters,
        pl_filters_mode,
        pl_filters_sequence,
      );
    }
    setFilterModalOpen(false);
  };
  const handleFilterModalApplyButton = () => {
    if (filterModalFilterMode === "" && filterModalGroupChoices.length > 0) {
      notify("results.toasts.you_must_select_at_least_one_item");
      return;
    } else if (
      filterModalFilterMode === "items" ||
      filterModalGroupChoices.length === 0
    ) {
      if (filterModalScope === "common") {
        setCommonFiltersIndexes((draft) => {
          draft[filterModalCurrentFilter] = filterModalItemsSelected;
        });
        let common_filters = {
          ...commonFilters,
          [filterModalCurrentFilter]: filterModalItemsSelected.map(
            (index) => filtersItems.current[filterModalCurrentFilter][index],
          ),
        };
        setCommonFilters((draft) => {
          draft[filterModalCurrentFilter] = filterModalItemsSelected.map(
            (index) => filtersItems.current[filterModalCurrentFilter][index],
          );
        });
        let common_filters_mode = {
          ...commonFiltersMode,
          [filterModalCurrentFilter]: "items",
        };
        setCommonFiltersMode((draft) => {
          draft[filterModalCurrentFilter] = "items";
        });
        let common_filters_sequence = commonFiltersSequence;
        if (!commonFiltersSequence.includes(filterModalCurrentFilter)) {
          common_filters_sequence.push(filterModalCurrentFilter);
          setCommonFiltersSequence(common_filters_sequence);
        }
        setPlsToRefresh(Object.keys(plValues).map((val) => parseInt(val)));
        Object.keys(plValues).map((numPL) => {
          refreshPL(
            numPL,
            common_filters,
            common_filters_mode,
            common_filters_sequence,
            plFilters[numPL],
            plFiltersMode[numPL],
            plFiltersSequence[numPL],
          );
        });
      } else if (filterModalScope === "pl") {
        setPlFiltersIndexes((draft) => {
          draft[filterModalCurrentPl][filterModalCurrentFilter] =
            filterModalItemsSelected;
        });
        let pl_filters = {
          ...plFilters[filterModalCurrentPl],
          [filterModalCurrentFilter]: filterModalItemsSelected.map(
            (index) => filtersItems.current[filterModalCurrentFilter][index],
          ),
        };
        setPlFilters((draft) => {
          draft[filterModalCurrentPl][filterModalCurrentFilter] =
            filterModalItemsSelected.map(
              (index) => filtersItems.current[filterModalCurrentFilter][index],
            );
        });
        let pl_filters_mode = {
          ...plFiltersMode[filterModalCurrentPl],
          [filterModalCurrentFilter]: "items",
        };
        setPlFiltersMode((draft) => {
          draft[filterModalCurrentPl][filterModalCurrentFilter] = "items";
        });
        let pl_filters_sequence = structuredClone(
          plFiltersSequence[filterModalCurrentPl],
        );
        if (
          !plFiltersSequence[filterModalCurrentPl].includes(
            filterModalCurrentFilter,
          )
        ) {
          pl_filters_sequence.push(filterModalCurrentFilter);
          setPlFiltersSequence((draft) => {
            draft[filterModalCurrentPl] = pl_filters_sequence;
          });
        }
        setPlsToRefresh(
          plsToRefresh.includes(filterModalCurrentPl)
            ? plsToRefresh
            : [...plsToRefresh, filterModalCurrentPl],
        );
        refreshPL(
          filterModalCurrentPl,
          commonFilters,
          commonFiltersMode,
          commonFiltersSequence,
          pl_filters,
          pl_filters_mode,
          pl_filters_sequence,
        );
      }
    } else if (filterModalFilterMode === "group") {
      if (filterModalScope === "common") {
        setCommonFiltersIndexes((draft) => {
          draft[filterModalCurrentFilter] = [];
        });
        let common_filters = {
          ...commonFilters,
          [filterModalCurrentFilter]: [filterModalGroupSelected],
        };
        setCommonFilters((draft) => {
          draft[filterModalCurrentFilter] = [filterModalGroupSelected];
        });
        let common_filters_mode = {
          ...commonFiltersMode,
          [filterModalCurrentFilter]: "group",
        };
        setCommonFiltersMode((draft) => {
          draft[filterModalCurrentFilter] = "group";
        });
        let common_filters_sequence = commonFiltersSequence;
        if (!commonFiltersSequence.includes(filterModalCurrentFilter)) {
          common_filters_sequence.push(filterModalCurrentFilter);
          setCommonFiltersSequence(common_filters_sequence);
        }
        setPlsToRefresh(Object.keys(plValues).map((val) => parseInt(val)));
        Object.keys(plValues).map((numPL) => {
          refreshPL(
            numPL,
            common_filters,
            common_filters_mode,
            common_filters_sequence,
            plFilters[numPL],
            plFiltersMode[numPL],
            plFiltersSequence[numPL],
          );
        });
      } else if (filterModalScope === "pl") {
        setPlFiltersIndexes((draft) => {
          draft[filterModalCurrentPl][filterModalCurrentFilter] = [];
        });
        let pl_filters = {
          ...plFilters[filterModalCurrentPl],
          [filterModalCurrentFilter]: [filterModalGroupSelected],
        };
        setPlFilters((draft) => {
          draft[filterModalCurrentPl][filterModalCurrentFilter] = [
            filterModalGroupSelected,
          ];
        });
        let pl_filters_mode = {
          ...plFiltersMode[filterModalCurrentPl],
          [filterModalCurrentFilter]: "group",
        };
        setPlFiltersMode((draft) => {
          draft[filterModalCurrentPl][filterModalCurrentFilter] = "group";
        });
        let pl_filters_sequence = structuredClone(
          plFiltersSequence[filterModalCurrentPl],
        );
        if (
          !plFiltersSequence[filterModalCurrentPl].includes(
            filterModalCurrentFilter,
          )
        ) {
          pl_filters_sequence.push(filterModalCurrentFilter);
          setPlFiltersSequence((draft) => {
            draft[filterModalCurrentPl] = pl_filters_sequence;
          });
        }
        setPlsToRefresh(
          plsToRefresh.includes(filterModalCurrentPl)
            ? plsToRefresh
            : [...plsToRefresh, filterModalCurrentPl],
        );
        refreshPL(
          filterModalCurrentPl,
          commonFilters,
          commonFiltersMode,
          commonFiltersSequence,
          pl_filters,
          pl_filters_mode,
          pl_filters_sequence,
        );
      }
    }
    setFilterModalOpen(false);
  };
  const getFiltersNamesInSequence = (scope) => {
    if (scope === "common") {
      return commonFiltersSequence.filter((f) =>
        Object.keys(filtersNames.current).includes(f),
      );
    } else {
      return plFiltersSequence[scope].filter((f) =>
        Object.keys(filtersNames.current).includes(f),
      );
    }
  };
  const currentCommonFilters = () => {
    return getFiltersNamesInSequence("common").map((filter, key) => {
      return (
        <FilterTag
          key={key}
          filtersList={filtersList.current}
          filtersNames={filtersNames.current}
          filtersGroups={filtersGroups.current}
          filter={filter}
          items={getFilterItems(
            commonFilters[filter],
            filter,
            commonFiltersMode[filter],
          )}
          indexes={commonFiltersIndexes[filter]}
          mode={commonFiltersMode[filter]}
          nameClickHandler={filterMenuClickHandler("common")}
          removeItemHandler={removeCommonFilter}
          color={"gray"}
        />
      );
    });
  };

  const addPL = (clone_pl = 0) => {
    if (numPLs >= maxPLs) return false;
    if (plsToRefresh.length > 0) return false;
    if (clone_pl > 0) {
      // add new P&L at the end
      // iterate "moving" each P&L forward until the cloned P&L
      let newNumPLs = numPLs + 1;
      let new_pl_values = {};
      let new_pl_filters_indexes = {};
      let new_pl_filters = {};
      let new_pl_filters_mode = {};
      let new_pl_filters_sequence = {};
      let new_pl_names = {};
      for (let numPL = newNumPLs; numPL > clone_pl; numPL--) {
        new_pl_values = {
          ...new_pl_values,
          [numPL]: plValues[numPL - 1],
        };
        new_pl_filters_indexes = {
          ...new_pl_filters_indexes,
          [numPL]: plFiltersIndexes[numPL - 1],
        };
        new_pl_filters = {
          ...new_pl_filters,
          [numPL]: plFilters[numPL - 1],
        };
        new_pl_filters_mode = {
          ...new_pl_filters_mode,
          [numPL]: plFiltersMode[numPL - 1],
        };
        new_pl_filters_sequence = {
          ...new_pl_filters_sequence,
          [numPL]: plFiltersSequence[numPL - 1],
        };
        if (numPL === clone_pl + 1) {
          new_pl_names = {
            ...new_pl_names,
            [numPL]: "",
          };
        } else {
          new_pl_names = {
            ...new_pl_names,
            [numPL]: plNames[numPL - 1],
          };
        }
      }
      setPlValues((draft) => {
        Object.entries(new_pl_values).forEach(
          ([key, val]) => (draft[key] = val),
        );
      });
      setPlFiltersIndexes((draft) => {
        Object.entries(new_pl_filters_indexes).forEach(
          ([key, val]) => (draft[key] = val),
        );
      });
      setPlFilters((draft) => {
        Object.entries(new_pl_filters).forEach(
          ([key, val]) => (draft[key] = val),
        );
      });
      setPlFiltersMode((draft) => {
        Object.entries(new_pl_filters_mode).forEach(
          ([key, val]) => (draft[key] = val),
        );
      });
      setPlFiltersSequence((draft) => {
        Object.entries(new_pl_filters_sequence).forEach(
          ([key, val]) => (draft[key] = val),
        );
      });
      setPlNames((draft) => {
        Object.entries(new_pl_names).forEach(
          ([key, val]) => (draft[key] = val),
        );
      });
      setRefreshDelayHandler((draft) => {
        draft[newNumPLs] = {
          delay_handler: null,
          controller: new AbortController(),
        };
      });
      setNumPLs(newNumPLs);
      setPlShow(
        [...Array(newNumPLs).keys()]
          .map((i) => i + 1)
          .filter((pl) => pl !== clone_pl + 1),
      );
      setTimeout(
        () => setPlShow((pls) => [...pls, clone_pl + 1]),
        clone_pl + 1 === newNumPLs ? 0 : 200,
      );
    } else {
      let newNumPLs = numPLs + 1;
      setPlValues((draft) => {
        draft[newNumPLs] = {};
      });
      setPlFiltersIndexes((draft) => {
        draft[newNumPLs] = initialFiltersObjects.current;
      });
      setPlFilters((draft) => {
        draft[newNumPLs] = initialFiltersObjects.current;
      });
      setPlFiltersMode((draft) => {
        draft[newNumPLs] = initialFiltersStrings.current;
      });
      setPlFiltersSequence((draft) => {
        draft[newNumPLs] = [];
      });
      setPlNames((draft) => {
        draft[newNumPLs] = "";
      });
      setRefreshDelayHandler((draft) => {
        draft[newNumPLs] = {
          delay_handler: null,
          controller: new AbortController(),
        };
      });
      setNumPLs(newNumPLs);
      setTimeout(
        () => setPlShow([...Array(newNumPLs).keys()].map((i) => i + 1)),
        0,
      );
      setPlsToRefresh([...plsToRefresh, newNumPLs]);
      refreshPL(
        newNumPLs,
        commonFilters,
        commonFiltersMode,
        commonFiltersSequence,
        initialFiltersObjects.current,
        initialFiltersStrings.current,
        [],
      );
    }
  };
  const removePL = (removePL) => {
    if (numPLs === 1) return false;
    if (plsToRefresh.length > 0) return false;

    // iterate "moving" each P&L backward
    let newNumPLs = numPLs - 1;
    let new_pl_values = {};
    let new_pl_filters_indexes = {};
    let new_pl_filters = {};
    let new_pl_filters_mode = {};
    let new_pl_names = {};
    for (let numPL = removePL; numPL <= newNumPLs; numPL++) {
      new_pl_values = {
        ...new_pl_values,
        [numPL]: plValues[numPL + 1],
      };
      new_pl_filters_indexes = {
        ...new_pl_filters_indexes,
        [numPL]: plFiltersIndexes[numPL + 1],
      };
      new_pl_filters = {
        ...new_pl_filters,
        [numPL]: plFilters[numPL + 1],
      };
      new_pl_filters_mode = {
        ...new_pl_filters_mode,
        [numPL]: plFiltersMode[numPL + 1],
      };
      new_pl_names = {
        ...new_pl_names,
        [numPL]: plNames[numPL + 1],
      };
    }
    setPlValues((draft) => {
      Object.entries(new_pl_values).forEach(([key, val]) => (draft[key] = val));
    });
    setPlFiltersIndexes((draft) => {
      Object.entries(new_pl_filters_indexes).forEach(
        ([key, val]) => (draft[key] = val),
      );
    });
    setPlFilters((draft) => {
      Object.entries(new_pl_filters).forEach(
        ([key, val]) => (draft[key] = val),
      );
    });
    setPlFiltersMode((draft) => {
      Object.entries(new_pl_filters_mode).forEach(
        ([key, val]) => (draft[key] = val),
      );
    });
    setPlNames((draft) => {
      Object.entries(new_pl_names).forEach(([key, val]) => (draft[key] = val));
    });
    setNumPLs(newNumPLs);
    setPlShow([...Array(newNumPLs).keys()].map((i) => i + 1));
  };

  const retryPL = (numPL) => {
    setPlsToRefresh((plsToRefresh) => [...plsToRefresh, numPL]);
    setPlsOnError((plsOnError) =>
      plsOnError.filter((item) => item !== parseInt(numPL)),
    );
    refreshPL(
      numPL,
      commonFilters,
      commonFiltersMode,
      commonFiltersSequence,
      plFilters[numPL],
      plFiltersMode[numPL],
      plFiltersSequence[numPL],
      true,
    );
  };
  const refreshPL = (
    numPL,
    common_filters = commonFilters,
    common_filters_mode = commonFiltersMode,
    common_filters_sequence = commonFiltersSequence,
    pl_filters = plFilters[numPL],
    pl_filters_mode = plFiltersMode[numPL],
    pl_filters_sequence = plFiltersSequence[numPL],
    immediate = false,
    currency_unit = currencyUnit,
  ) => {
    if (!pl_filters) return false; // avoids calling API during load
    if (
      refreshDelayHandler[numPL] &&
      refreshDelayHandler[numPL].delay_handler
    ) {
      clearTimeout(refreshDelayHandler[numPL].delay_handler);
      refreshDelayHandler[numPL].controller.abort();
    }
    let controller = new AbortController();
    let params = {
      currency_unit,
      common_filters,
      common_filters_mode,
      common_filters_sequence,
      pl_filters,
      pl_filters_mode,
      pl_filters_sequence,
    };
    setPlsOnError((plsOnError) =>
      plsOnError.filter((item) => item !== parseInt(numPL)),
    );
    setRefreshDelayHandler((draft) => {
      draft[numPL] = {
        delay_handler: setTimeout(
          () => {
            client
              .post("api/results_pl_explorer_read_pl/", params, {
                signal: controller.signal,
              })
              .then((response) => {
                setPlValues((draft) => {
                  draft[numPL] = response.data.pl;
                });
                setPlsToRefresh((plsToRefresh) =>
                  plsToRefresh.filter((item) => item !== parseInt(numPL)),
                );
              })
              .catch((error) => {
                if (!axios.isCancel(error)) {
                  setPlsOnError((plsOnError) =>
                    plsOnError.includes(parseInt(numPL))
                      ? plsOnError
                      : [...plsOnError, parseInt(numPL)],
                  );
                  setPlsToRefresh((plsToRefresh) =>
                    plsToRefresh.filter((item) => item !== parseInt(numPL)),
                  );
                  console.log(error);
                }
              });
          },
          immediate ? 0 : refreshPLDelay,
        ),
        controller: controller,
      };
    });
  };

  const handleDrillDownClick = (meta_name) => {
    if (drillDownsOpen.includes(meta_name)) {
      setDrillDownsOpen(drillDownsOpen.filter((item) => item !== meta_name));
    } else {
      setDrillDownsOpen((drillDownsOpen) => [...drillDownsOpen, meta_name]);
    }
    return false;
  };
  const handleMouseOverPLLine = (meta_name) => {
    setMouseOverPLLine(meta_name);
    setDelayHandler(
      setTimeout(() => {
        setMouseOverPLLineFade(meta_name);
      }, 1000),
    );
  };
  const handleMouseLeavePLLine = () => {
    clearTimeout(delayHandler);
    clearTimeout(delayHandlerDetails);
    setMouseOverPLLine(null);
    setMouseOverPLLineFade(null);
  };

  const clearTimeouts = () => {
    clearTimeout(delayHandler);
    clearTimeout(delayHandlerDetails);
    setMouseOverVal(false);
  };
  const allowTimers = () => {
    timersAllowed.current = true;
  };
  const disallowTimers = () => {
    timersAllowed.current = false;
    clearTimeouts();
  };

  const handleMouseOverPLVal = (meta_name, numPL) => {
    if (revenueAnchors.current.includes(meta_name)) return false;
    if (plsToRefresh.length > 0) return false;
    if (!timersAllowed.current) return false;
    setMouseOverPLLine(meta_name);
    setMouseOverVal(true);
    setDelayHandlerDetails(
      setTimeout(() => {
        if (timersAllowed.current) {
          setLoadingPlValDetails(true);
          setPlValDetailsPl(numPL);
          setPlValDetailsName(meta_name);
          setPlValDetailsVars([]);
          setPlValDetailsPromoters({});
          setPlValDetailsDetractors({});
          setDetailsMonthlyEvolution({
            ...linePercentageChart,
            data: {
              labels: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
              ],
              series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
            },
          });
          getPlValDetails(meta_name, numPL);
        }
      }, 3000),
    );
  };
  const handleMouseLeavePLVal = (meta_name) => {
    if (revenueAnchors.current.includes(meta_name)) return false;
    clearTimeout(delayHandlerDetails);
    setMouseOverPLLine(null);
    setMouseOverVal(false);
  };
  const getPlValName = (meta_name) => {
    let name;
    let parent, parent_name;
    const getName = (mn) => {
      let _name, _parent;
      plStructure.current.forEach((item) => {
        if (item.meta_name === mn) {
          _name = item.name;
          _parent = item.parent;
        }
      });
      return [_name, _parent];
    };
    [name, parent] = getName(meta_name);
    if (parent) {
      parent_name = getName(parent)[0];
      return parent_name + " > " + name;
    }
    return name;
  };
  const getPlValAmount = (meta_name) => {
    if (!meta_name) return "";
    let val = plValues[plValDetailsPl][meta_name];
    return currencyUnit + oom.x + " " + (val / oom.div).toFixed(2);
  };
  const getPlValAmountPerc = (meta_name) => {
    if (!meta_name) return "";
    let net_revenue = plValues[plValDetailsPl][netRevenueAnchor.current];
    if (net_revenue <= 0) return "-";
    let val = plValues[plValDetailsPl][meta_name];
    return ((100 * val) / net_revenue).toFixed(1) + "%";
  };
  const getPlValAmountTotalCompPerc = (meta_name) => {
    if (!meta_name) return "";
    let val = "-";
    if (meta_name) {
      plStructure.current.forEach((line) => {
        if (line.meta_name === meta_name)
          val = (100 * line.ref_value).toFixed(1) + "%";
      });
    }
    return val;
  };
  const getPlValAmountPerVol = (meta_name) => {
    if (!meta_name) return "";
    let vol = plValues[plValDetailsPl].volume;
    if (vol <= 0) return "-";
    let val = plValues[plValDetailsPl][meta_name];
    return (
      (val / vol).toFixed(0) + " " + currencyUnit + "/" + volumeUnit.current
    );
  };

  const getPlValDetails = (meta_name, numPL) => {
    let params = {
      currency_unit: currencyUnit,
      meta_name,
      common_filters: commonFilters,
      common_filters_mode: commonFiltersMode,
      common_filters_sequence: commonFiltersSequence,
      pl_filters: plFilters[numPL],
      pl_filters_mode: plFiltersMode[numPL],
      pl_filters_sequence: plFiltersSequence[numPL],
    };
    client
      .post("api/results_pl_explorer_read_pl_val_details/", params)
      .then((response) => {
        setPlValDetailsVars(response.data.vars);
        setPlValDetailsPromoters(response.data.promoters);
        setPlValDetailsDetractors(response.data.detractors);
        setDetailsMonthlyEvolution({
          ...detailsMonthlyEvolution,
          data: response.data.evolution,
        });
        setLoadingPlValDetails(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.log(error);
        }
      });
  };
  const getDetailsMonthlyEvolutionData = () => {
    let data = structuredClone(detailsMonthlyEvolution.data);
    data.labels = data.labels.map((month) => t("months." + month));
    data.series = [data.series];
    return data;
  };

  const handleChangePLNameClick = (numPL) => {
    setPLNameModalCurrentNum(numPL);
    setPlNameModalInput(plNames[numPL]);
    setPlNameModalOpen(true);
    if (plNameInput.current)
      setTimeout(() => plNameInput.current.firstChild.focus(), 100);
  };
  const handlePLNameChange = (event) => {
    setPlNameModalInput(event.target.value);
  };
  const handlePLNameChangeApply = () => {
    let name = stripText(plNameModalInput);
    setPlNames((draft) => {
      draft[plNameModalCurrentNum] = name;
    });
    setPlNameModalOpen(false);
  };

  const plSpinner = (
    <GridContainer
      justifyContent={"flex-end"}
      alignItems={"center"}
      style={{ height: "100%" }}
    >
      <GridItem>
        <ThreeDots
          color={grayColor[0]}
          ariaLabel="loading"
          width={"20px"}
          height={"12px"}
          wrapperStyle={{}}
          wrapperClass={classes.plSpinner}
        />
      </GridItem>
    </GridContainer>
  );

  const getCriticalityLevel = (numPL, meta_name) => {
    let revenue = plValues[numPL][netRevenueAnchor.current];
    if (revenue <= 0) return "8"; // 8 = neutral value
    let val = plValues[numPL][meta_name] / revenue;
    let is_total = false;
    let ref = 0;
    plStructure.current.forEach((line) => {
      if (line.meta_name === meta_name) {
        ref = line.ref_value;
        is_total = line.level === 1;
      }
    });
    let min = ref * 0.8;
    let max = ref * 1.2;
    if (is_total) {
      return (
        1 +
        Math.min(Math.max(Math.round((14 * (val - min)) / (max - min)), 1), 14)
      ).toString();
    } else {
      return (
        15 -
        Math.min(Math.max(Math.round((14 * (val - min)) / (max - min)), 1), 14)
      ).toString();
    }
  };
  const getPLMdWidth = (numPL) => {
    if (numPL === 1) {
      if (numPLs <= 4) {
        return 4;
      } else {
        return 3;
      }
    } else {
      if (numPLs > 3) {
        return true;
      } else {
        return 4;
      }
    }
  };
  const getPL = (numPL) => {
    numPL += 1;
    if (plValues[numPL]) {
      return (
        <GridItem
          sm={12}
          md={getPLMdWidth(numPL)}
          key={numPL}
          onClick={() => clearTimeouts()}
          onContextMenu={() => clearTimeouts()}
          zeroMinWidth
          className={cx({
            [classes.plDisplay]: true,
            show: plShow.includes(numPL),
          })}
        >
          <GridContainer>
            <GridItem xs={12} className={classes.plCardWrapper}>
              <Card
                className={classes.plCard}
                onMouseEnter={() => allowTimers()}
                onMouseLeave={() => disallowTimers()}
              >
                <CardBody className={classes.plCardBody}>
                  {/* P&L Header */}
                  <div
                    className={cx({
                      [classes.plHeader]: true,
                      ["first"]: numPL === 1,
                    })}
                  >
                    <div
                      className={classes.plName}
                      onClick={() => handleChangePLNameClick(numPL)}
                    >
                      {plNames[numPL] && plNames[numPL] !== ""
                        ? plNames[numPL]
                        : "P&L " + numPL.toString()}
                    </div>
                    <div className={classes.plActionWrapper}>
                      {numPLs <= 4 && (
                        <Tooltip title={"Add/edit name"}>
                          <div
                            className={classes.plAction}
                            onClick={() => handleChangePLNameClick(numPL)}
                          >
                            <FontAwesomeIcon icon="pen-to-square" />
                          </div>
                        </Tooltip>
                      )}
                      <div className={classes.plAction}>
                        <FilterDropdown
                          button={<FontAwesomeIcon icon="filter" />}
                          dropPlacement={"bottom-start"}
                          hoverColor={"client"}
                          dropdownHeader={t("results.add_change_filter")}
                          dropdownList={filtersListTranslated()}
                          dropdownActiveItems={filtersActiveList("pl", numPL)}
                          dropdownFilterSequence={filtersSequence(numPL)}
                          tooltip={t("results.add_change_filter")}
                          onClick={filterMenuClickHandler("pl", numPL)}
                        />
                      </div>
                      {numPLs < maxPLs && (
                        <Tooltip title={"Clone P&L"}>
                          <div
                            className={classes.plAction}
                            onClick={() => addPL(numPL)}
                          >
                            <FontAwesomeIcon icon="clone" />
                          </div>
                        </Tooltip>
                      )}
                      <Tooltip title={"Remove"}>
                        <div
                          className={classes.plAction}
                          onClick={() => removePL(numPL)}
                        >
                          <FontAwesomeIcon icon="x" />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  {/* End P&L Header */}
                  {/* P&L Lines */}
                  <div className={classes.plOuterWrapper}>
                    {plsOnError.includes(numPL) && (
                      <div className={classes.plErrorCover}>
                        <div
                          className={classes.plErrorRefreshIcon}
                          onClick={() => retryPL(numPL)}
                        >
                          <Sync />
                        </div>
                        <div className={classes.plErrorMessage}>
                          {t("results.error_on_load")}
                        </div>
                      </div>
                    )}
                    {/* P&L Volume */}
                    <div className={classes.plLineWrapper}>
                      <div
                        className={cx({
                          [classes.plLineDescription]: true,
                          ["hidden"]: numPL > 1 && numPLs >= 4,
                        })}
                      >
                        {t("results.volume")}
                      </div>
                      <div
                        className={cx({
                          [classes.plValue]: true,
                          [classes.plNumber]: true,
                          [classes.plVolumeValue]: true,
                          ["expanded"]: numPL > 1 && numPLs >= 4,
                        })}
                      >
                        {!plsToRefresh.includes(numPL) &&
                          (plValues[numPL].volume ? (
                            <>
                              {
                                formatter
                                  .format(plValues[numPL].volume)
                                  .split(".")[0]
                              }
                              <span className={classes.plUnit}>
                                {" " + volumeUnit.current}
                              </span>
                            </>
                          ) : (
                            "-"
                          ))}
                        {plsToRefresh.includes(numPL) && plSpinner}
                      </div>
                    </div>
                    <div className={classes.plLineWrapper}>
                      <div
                        className={cx({
                          [classes.plLineDescription]: true,
                          [classes.plPercTotalVolume]: true,
                          ["hidden"]: numPL > 1 && numPLs >= 4,
                        })}
                      >
                        {t("results.percent_of_total_volume")}
                      </div>
                      <div
                        className={cx({
                          [classes.plValue]: true,
                          [classes.plNumber]: true,
                          [classes.plPercTotalVolume]: true,
                          [classes.plVolumeValue]: true,
                          ["expanded"]: numPL > 1 && numPLs >= 4,
                        })}
                      >
                        {!plsToRefresh.includes(numPL) &&
                          (plValues[numPL].volume && volumeL12M.current
                            ? (
                                (100 * plValues[numPL].volume) /
                                volumeL12M.current
                              ).toFixed(1) + "%"
                            : "-")}
                        {plsToRefresh.includes(numPL) && plSpinner}
                      </div>
                    </div>
                    {/* End P&L Volume */}
                    {/* P&L Table Header */}
                    <div className={classes.plLineWrapper}>
                      <div
                        className={cx({
                          [classes.plLineDescription]: true,
                          [classes.plTableHeader]: true,
                          ["hidden"]: numPL > 1 && numPLs >= 4,
                        })}
                      >
                        {"P&L"}
                      </div>
                      <div
                        className={cx({
                          [classes.plValue]: true,
                          [classes.plTableHeader]: true,
                          ["currency"]: true,
                          ["expanded"]: numPL > 1 && numPLs >= 4,
                        })}
                      >
                        {currencyUnit && !plsToRefresh.includes(numPL)
                          ? showValueType === "value"
                            ? currencyUnit + oom.x
                            : currencyUnit + "/" + volumeUnit.current
                          : "-"}
                      </div>
                      <div
                        className={cx({
                          [classes.plValue]: true,
                          [classes.plTableHeader]: true,
                          ["percentual"]: true,
                          ["expanded"]: numPL > 1 && numPLs >= 4,
                        })}
                      >
                        {"%"}
                      </div>
                    </div>
                    {/* End P&L Table Header */}
                    {/* P&L Financials */}
                    <div onMouseLeave={() => handleMouseLeavePLLine()}>
                      {plStructure.current.map((val, key) => {
                        return (
                          <div
                            key={key}
                            className={cx({
                              [classes.plLineWrapper]: true,
                              [classes.plTotal]: val.level === 1,
                              [classes.plSubTotal]: val.level === 2,
                              [classes.plLevel3]: val.level === 3,
                              [classes.highlighted]:
                                mouseOverPLLine &&
                                mouseOverPLLine === val.meta_name &&
                                timersAllowed.current,
                              [classes.faded]:
                                mouseOverPLLineFade &&
                                mouseOverPLLineFade !== val.meta_name &&
                                timersAllowed.current &&
                                numPLs > 1,
                              [classes.hidden]:
                                val.parent &&
                                !drillDownsOpen.includes(val.parent),
                              [classes.plLoadingDetails]:
                                mouseOverPLLine === val.meta_name &&
                                mouseOverVal &&
                                timersAllowed.current,
                            })}
                            onMouseOut={() => handleMouseLeavePLLine()}
                            onMouseOver={() =>
                              handleMouseOverPLLine(val.meta_name)
                            }
                          >
                            <div
                              className={cx({
                                [classes.plLineDescription]: true,
                                [classes.plDrillDown]: val.is_parent,
                                [classes.plDrillDownOpen]:
                                  val.is_parent &&
                                  drillDownsOpen.includes(val.meta_name),
                                [classes.plDrillDownClosed]:
                                  val.is_parent &&
                                  !drillDownsOpen.includes(val.meta_name),
                                [classes.plIndentL2]: val.level === 3,
                                ["hidden"]: numPL > 1 && numPLs >= 4,
                              })}
                            >
                              {val.is_parent && (
                                <span
                                  onClick={() =>
                                    handleDrillDownClick(val.meta_name)
                                  }
                                ></span>
                              )}
                              {val.parent && <span></span>}
                              {val.name}
                            </div>
                            <div
                              className={cx({
                                [classes.plValue]: true,
                                [classes.plNumber]: true,
                                ["currency"]: true,
                                ["negative"]:
                                  val.level === 1 &&
                                  plValues[numPL][val.meta_name] &&
                                  plValues[numPL][val.meta_name] < 0,
                                ["expanded"]: numPL > 1 && numPLs >= 4,
                                // [classes.plLoadingDetails]: mouseOverPLLine === val.meta_name,
                              })}
                              onMouseOut={() =>
                                handleMouseLeavePLVal(val.meta_name)
                              }
                              onMouseOver={() =>
                                handleMouseOverPLVal(val.meta_name, numPL)
                              }
                            >
                              {!plsToRefresh.includes(numPL) &&
                                (plValues[numPL][val.meta_name] ? (
                                  <span>
                                    {showValueType === "value"
                                      ? (
                                          plValues[numPL][val.meta_name] /
                                          oom.div
                                        ).toFixed(2)
                                      : showValueByVolumeDecimals
                                        ? formatter
                                            .format(
                                              plValues[numPL][val.meta_name] /
                                                plValues[numPL]["volume"],
                                            )
                                            .split(".")[0] +
                                          "." +
                                          formatter
                                            .format(
                                              plValues[numPL][val.meta_name] /
                                                plValues[numPL]["volume"],
                                            )
                                            .split(".")[1]
                                            .substring(0, 2)
                                        : formatter
                                            .format(
                                              plValues[numPL][val.meta_name] /
                                                plValues[numPL]["volume"],
                                            )
                                            .split(".")[0]}
                                  </span>
                                ) : (
                                  "-"
                                ))}
                              {plsToRefresh.includes(numPL) && plSpinner}
                            </div>
                            <div
                              className={cx({
                                [classes.plValue]: true,
                                [classes.plNumber]: true,
                                ["percentual"]: true,
                                ["negative"]:
                                  val.level === 1 &&
                                  plValues[numPL][val.meta_name] &&
                                  plValues[numPL][val.meta_name] < 0,
                                ["expanded"]: numPL > 1 && numPLs >= 4,
                                [classes.plComparison]:
                                  !plsToRefresh.includes(numPL) &&
                                  val.ref_value !== null &&
                                  plValues[numPL][val.meta_name] &&
                                  !revenueAnchors.current.includes(
                                    val.meta_name,
                                  ),
                                ["n" +
                                getCriticalityLevel(numPL, val.meta_name)]:
                                  !plsToRefresh.includes(numPL) &&
                                  val.ref_value !== null &&
                                  !revenueAnchors.current.includes(
                                    val.meta_name,
                                  ),
                                // [classes.plLoadingDetails]: mouseOverPLLine === val.meta_name,
                              })}
                              onMouseOut={() =>
                                handleMouseLeavePLVal(val.meta_name)
                              }
                              onMouseOver={() =>
                                handleMouseOverPLVal(val.meta_name, numPL)
                              }
                            >
                              {!plsToRefresh.includes(numPL) &&
                                (plValues[numPL][val.meta_name] ? (
                                  <span>
                                    {getPlPercentValue(numPL, val.meta_name)}
                                  </span>
                                ) : (
                                  "-"
                                ))}
                              {plsToRefresh.includes(numPL) && plSpinner}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* End P&L Financials */}
                  </div>
                  {/* End P&L Lines */}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      );
    } else {
      return null;
    }
  };
  const PLs = [...Array(numPLs)].map((_, key) => getPL(key));

  const plAppliedFilters = (numPL, context) => {
    return !Object.keys(plValues).includes(numPL.toString()) ? null : (
      <>
        <div className={classes.plAppliedFiltersWrapper}>
          <div className={classes.plAppliedFiltersHeader + " " + context}>
            {t("results.filters_applied_to_pl")}
          </div>
          <GridItem xs={12}>
            {getFiltersNamesInSequence(numPL).map((filter, key) => (
              <FilterTag
                key={key}
                filtersList={filtersList.current}
                filtersNames={filtersNames.current}
                filtersGroups={filtersGroups.current}
                filter={filter}
                items={getFilterItems(
                  plFilters[numPL][filter],
                  filter,
                  plFiltersMode[numPL][filter],
                )}
                indexes={plFiltersIndexes[numPL][filter]}
                mode={plFiltersMode[numPL][filter]}
                removeItemHandler={removeCommonFilter}
                color={"light"}
                viewOnly
              />
            ))}
          </GridItem>
        </div>
      </>
    );
  };
  const filtersDialog = (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={filterModalOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setFilterModalOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={cx({
            [classes.modalHeader]: true,
            // [classes.center]: true,
          })}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setFilterModalOpen(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            <span className={classes.filterModalHeaderScope}>
              {filterModalHeader}
            </span>
            {" - "}
            <span className={classes.filterModalHeaderFilterName}>
              {filterModalFilterName}
            </span>
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer alignItems={"center"}>
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={12}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.marginBottomSmall
                    }
                  >
                    <InputLabel
                      htmlFor="filter-items"
                      className={classes.selectLabel}
                    >
                      {t("results.filter_modal.select_items")}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={filterModalItemsSelected}
                      onChange={handleFilterModalItemsMenuSelect}
                      // onClick={() => setFilterModalFilterMode("items")}
                      inputProps={{
                        name: "filterItems",
                        id: "filter-items",
                      }}
                      multiple
                    >
                      {filterModalItemsMenuItems()}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem className={classes.filterModalActionContainer}>
                      <div
                        className={classes.filterModalAction}
                        onClick={handleFilterModalItemsSelectAll}
                      >
                        {t("results.filter_modal.select_all")}
                      </div>
                    </GridItem>
                    <GridItem className={classes.filterModalActionContainer}>
                      <div
                        className={classes.filterModalAction}
                        onClick={handleFilterModalItemsSelectNone}
                      >
                        {t("results.filter_modal.select_none")}
                      </div>
                    </GridItem>
                    <GridItem className={classes.filterModalActionContainer}>
                      <div
                        className={classes.filterModalAction}
                        onClick={handleFilterModalItemsInvertSelection}
                      >
                        {t("results.filter_modal.invert_selection")}
                      </div>
                    </GridItem>
                    <GridItem xs={true}>
                      <div className={classes.filterModalCounter}>
                        {filterModalItemsSelected.length.toString() +
                          " " +
                          t("results.filter_modal.items_selected")}
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
            {filterModalGroupChoices.length > 0 && (
              <>
                <GridItem
                  xs={12}
                  className={cx({
                    [classes.arrow]: true,
                    [classes.firstArrow]: true,
                    [classes.arrowSelected]: filterModalFilterMode === "items",
                  })}
                >
                  <FontAwesomeIcon icon="chevron-up" />
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.orDivider}>
                    {t("results.filter_modal.or")}
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  className={cx({
                    [classes.arrow]: true,
                    [classes.arrowSelected]: filterModalFilterMode === "group",
                  })}
                >
                  <FontAwesomeIcon icon="chevron-down" />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: "-7px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="filter-group"
                      className={classes.selectLabel}
                    >
                      {t("results.filter_modal.select_a_range")}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={filterModalGroupSelected}
                      onChange={handleFilterModalGroupMenuSelect}
                      inputProps={{
                        name: "filterGroup",
                        id: "filter-group",
                      }}
                    >
                      {filterModalGroupMenuItems()}
                    </Select>
                  </FormControl>
                </GridItem>
              </>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            {(filterModalCurrentFilter !== "period" ||
              filterModalScope === "pl") && (
              <GridItem>
                <Button
                  onClick={handleFilterModalClearFilterButton}
                  color="info"
                  simple
                >
                  {t("results.filter_modal.clear_filter")}
                </Button>
              </GridItem>
            )}
            <GridItem xs={true}></GridItem>
            <GridItem>
              <Button
                onClick={handleFilterModalApplyButton}
                color="info"
                simple
              >
                {t("results.filter_modal.apply")}
              </Button>
              <Button
                onClick={() => setFilterModalOpen(false)}
                color="danger"
                simple
              >
                {t("results.filter_modal.close")}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </Dialog>
    </>
  );
  const plNameDialog = (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={plNameModalOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setPlNameModalOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={cx({
            [classes.modalHeader]: true,
            // [classes.center]: true,
          })}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setPlNameModalOpen(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            <span className={classes.modalHeader}>
              {t("results.alias_modal.alias_to_pl") +
                " " +
                plNameModalCurrentNum}
            </span>
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ paddingTop: "0" }}
        >
          <GridContainer alignItems={"center"}>
            <GridItem xs={12}>
              <CustomInput
                labelText={t("results.alias_modal.set_a_name_for_the_pl")}
                id="pl_name"
                value={plNameModalInput}
                onChange={handlePLNameChange}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  autoComplete: "off",
                  maxLength: "20",
                }}
                client
                helperText={
                  plNameModalInput.length + "/20 " + t("results.characters")
                }
                ref={plNameInput}
                onEnterKey={() => handlePLNameChangeApply()}
              />
            </GridItem>
            {plAppliedFilters(plNameModalCurrentNum, "modal")}
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            <GridItem xs={true}></GridItem>
            <GridItem>
              <Button
                onClick={() => handlePLNameChangeApply()}
                color="info"
                simple
              >
                {t("results.filter_modal.apply")}
              </Button>
              <Button
                onClick={() => setPlNameModalOpen(false)}
                color="danger"
                simple
              >
                {t("results.filter_modal.close")}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </Dialog>
    </>
  );
  const scenarioNameDialog = (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={scenarioNameModalOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setScenarioNameModalOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={cx({
            [classes.modalHeader]: true,
            // [classes.center]: true,
          })}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setScenarioNameModalOpen(false)}
            disabled={scenarioBusy}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            <span className={classes.modalHeader}>
              {t("results.scenarios.scenario_name")}
            </span>
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer alignItems={"center"}>
            <GridItem xs={12}>
              <CustomInput
                labelText={t("results.scenarios.set_a_name_for_the_scenario")}
                id="scenario_name"
                value={scenarioNameModalInput}
                onChange={handleScenarioNameChange}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  autoComplete: "off",
                  maxLength: "50",
                  disabled: scenarioBusy,
                }}
                client
                helperText={
                  scenarioNameModalInput.length +
                  "/50 " +
                  t("results.characters")
                }
                ref={scenarioNameInput}
                onEnterKey={() => handleScenarioNameChangeApply()}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            <GridItem>
              <GridContainer alignItems={"center"} style={{ height: "100%" }}>
                <GridItem>
                  <ThreeDots
                    visible={scenarioBusy}
                    color={userDetails.implementation_color}
                    ariaLabel="loading"
                    height={"12px"}
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={true}></GridItem>
            <GridItem>
              <Button
                onClick={() => handleScenarioNameChangeApply()}
                color="info"
                simple
                disabled={
                  scenarioBusy || stripText(scenarioNameModalInput) === ""
                }
              >
                {t("results.scenarios.save")}
              </Button>
              <Button
                onClick={() => setScenarioNameModalOpen(false)}
                color="danger"
                simple
                disabled={scenarioBusy}
              >
                {t("results.filter_modal.close")}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </Dialog>
    </>
  );
  const shareViewDialog = (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={shareViewModalOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setShareViewModalOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={cx({
            [classes.modalHeader]: true,
            // [classes.center]: true,
          })}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShareViewModalOpen(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            <span className={classes.modalHeader}>
              {t("results.scenarios.share_view")}
            </span>
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer alignItems={"center"}>
            <GridItem xs={true} style={{ paddingRight: "0 !important" }}>
              <CustomInput
                labelText={t("results.scenarios.url_to_share")}
                id="scenario_url"
                value={buildScenarioString(true)}
                // onChange={handleScenarioNameChange}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  autoComplete: "off",
                  maxLength: "20",
                  readOnly: true,
                  onClick: () => copyScenarioURL(),
                }}
                integration
              />
            </GridItem>
            <GridItem>
              <Button
                onClick={() => copyScenarioURL()}
                color="info"
                simple
                style={{ paddingLeft: "15px", paddingRight: "15px" }}
              >
                {t("results.scenarios.copy")}
              </Button>
            </GridItem>
          </GridContainer>
          <div className={classes.modalFootnote}>
            <ul>
              <li>{t("results.scenarios.share_footnote1")}</li>
              <li>{t("results.scenarios.share_footnote2")}</li>
            </ul>
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            <GridItem></GridItem>
            <GridItem xs={true}></GridItem>
            <GridItem>
              <Button
                onClick={() => setShareViewModalOpen(false)}
                color="danger"
                simple
              >
                {t("results.filter_modal.close")}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </Dialog>
    </>
  );
  const detailsDrawer = (
    <>
      <Drawer
        anchor={"right"}
        open={plValDetailsName !== null}
        onClose={() => setPlValDetailsName(null)}
        BackdropProps={{ style: { backgroundColor: "#000", opacity: "0.1" } }}
        PaperProps={{
          style: {
            // opacity: "0.95",
            borderRadius: "10px",
            margin: "1vh 1vh 1vh 0",
            height: "98vh",
          },
        }}
      >
        <div className={classes.drawerSpinner}>
          <ThreeDots
            visible={loadingPlValDetails}
            color={userDetails.implementation_color}
            ariaLabel="loading"
            height={"20px"}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={cx({
            [classes.drawerHeader]: true,
          })}
        >
          <h6
            className={cx({
              [classes.modalTitle]: true,
              [classes.drawerHeaderScope]: true,
            })}
          >
            {"P&L " +
              plValDetailsPl +
              (plNames[plValDetailsPl] ? " - " + plNames[plValDetailsPl] : "")}
          </h6>
          <div
            className={cx({
              [classes.modalTitle]: true,
              [classes.drawerHeaderScope]: true,
            })}
          >
            <div>
              <span className={classes.drawerHeaderValues}>
                {getPlValName(plValDetailsName)}
              </span>
              {" — "}
              {getPlValAmount(plValDetailsName)}
              {" = "}
              {getPlValAmountPerVol(plValDetailsName)}
              {" = "}
              {getPlValAmountPerc(plValDetailsName)}{" "}
              {t("results.details_modal.of_the_revenue")}
              {" (vs "}
              {getPlValAmountTotalCompPerc(plValDetailsName)}{" "}
              {t("results.details_modal.total_company_12m")}
              {")"}
            </div>
          </div>
        </DialogTitle>
        <div className={classes.drawerContentWrapper}>
          <div className={classes.drawerPlAppliedFiltersWrapper}>
            {plAppliedFilters(plValDetailsPl, "drawer")}
          </div>
          <h6 className={classes.drawerSectionTitle}>
            {t("results.details_modal.main_promoters")}
            <span
              className={cx({
                [classes.drawerSectionTitleIcon]: true,
                ["positiveTrend"]: true,
              })}
            >
              <FontAwesomeIcon icon="arrow-trend-up" />
            </span>
            <HelpTooltip
              tooltip={t("results.details_modal.promoter_detractor_tooltip")}
            />
          </h6>
          <div className={classes.drawerTable}>
            <div>
              <GridContainer
                className={cx({
                  [classes.drawerTableContent]: true,
                  [classes.drawerTableHeader]: true,
                })}
              >
                <GridItem>{"#"}</GridItem>
                <GridItem>
                  {plValDetailsVars.length > 0
                    ? plValDetailsVars[0][i18n.language]
                    : "-"}
                </GridItem>
                <GridItem>
                  {plValDetailsVars.length > 1
                    ? plValDetailsVars[1][i18n.language]
                    : "-"}
                </GridItem>
                <GridItem>
                  {plValDetailsVars.length > 2
                    ? plValDetailsVars[2][i18n.language]
                    : "-"}
                </GridItem>
              </GridContainer>
            </div>
            {[0, 1, 2, 3, 4].map((val) => (
              <div key={val}>
                <GridContainer
                  className={cx({
                    [classes.drawerTableContent]: true,
                    [classes.drawerTableData]: true,
                  })}
                >
                  <GridItem>{val + 1}</GridItem>
                  <GridItem>
                    {plValDetailsPromoters["vals1"] &&
                      plValDetailsPromoters["vals1"].length > val && (
                        <>
                          {(plValDetailsPromoters["vals1"][val].item
                            ? plValDetailsPromoters["vals1"][val].item.slice(
                                0,
                                15,
                              )
                            : "● " + t("results.blank") + " ●") + " ("}
                          <span
                            className={cx({
                              [classes.positive]: true,
                            })}
                          >
                            {plValDetailsPromoters["vals1"][val].impact > 0
                              ? "+"
                              : ""}
                            {(
                              100 * plValDetailsPromoters["vals1"][val].impact
                            ).toFixed(2) + " pp"}
                          </span>
                          {")"}
                        </>
                      )}
                  </GridItem>
                  <GridItem>
                    {plValDetailsPromoters["vals2"] &&
                      plValDetailsPromoters["vals2"].length > val && (
                        <>
                          {(plValDetailsPromoters["vals2"][val].item
                            ? plValDetailsPromoters["vals2"][val].item.slice(
                                0,
                                15,
                              )
                            : "● " + t("results.blank") + " ●") + " ("}
                          <span
                            className={cx({
                              [classes.positive]: true,
                            })}
                          >
                            {plValDetailsPromoters["vals2"][val].impact > 0
                              ? "+"
                              : ""}
                            {(
                              100 * plValDetailsPromoters["vals2"][val].impact
                            ).toFixed(2) + " pp"}
                          </span>
                          {")"}
                        </>
                      )}
                  </GridItem>
                  <GridItem>
                    {plValDetailsPromoters["vals3"] &&
                      plValDetailsPromoters["vals3"].length > val && (
                        <>
                          {(plValDetailsPromoters["vals3"][val].item
                            ? plValDetailsPromoters["vals3"][val].item.slice(
                                0,
                                15,
                              )
                            : "● " + t("results.blank") + " ●") + " ("}
                          <span
                            className={cx({
                              [classes.positive]: true,
                            })}
                          >
                            {plValDetailsPromoters["vals3"][val].impact > 0
                              ? "+"
                              : ""}
                            {(
                              100 * plValDetailsPromoters["vals3"][val].impact
                            ).toFixed(2) + " pp"}
                          </span>
                          {")"}
                        </>
                      )}
                  </GridItem>
                </GridContainer>
              </div>
            ))}
          </div>
          <h6 className={classes.drawerSectionTitle}>
            {t("results.details_modal.main_detractors")}
            <span
              className={cx({
                [classes.drawerSectionTitleIcon]: true,
                ["negativeTrend"]: true,
              })}
            >
              <FontAwesomeIcon icon="arrow-trend-down" />
            </span>
            <HelpTooltip
              tooltip={t("results.details_modal.promoter_detractor_tooltip")}
            />
          </h6>
          <div className={classes.drawerTable}>
            <div>
              <GridContainer
                className={cx({
                  [classes.drawerTableContent]: true,
                  [classes.drawerTableHeader]: true,
                })}
              >
                <GridItem>{"#"}</GridItem>
                <GridItem>
                  {plValDetailsVars.length > 0
                    ? plValDetailsVars[0][i18n.language]
                    : "-"}
                </GridItem>
                <GridItem>
                  {plValDetailsVars.length > 1
                    ? plValDetailsVars[1][i18n.language]
                    : "-"}
                </GridItem>
                <GridItem>
                  {plValDetailsVars.length > 2
                    ? plValDetailsVars[2][i18n.language]
                    : "-"}
                </GridItem>
              </GridContainer>
            </div>
            {[0, 1, 2, 3, 4].map((val) => (
              <div key={val}>
                <GridContainer
                  className={cx({
                    [classes.drawerTableContent]: true,
                    [classes.drawerTableData]: true,
                  })}
                >
                  <GridItem>{val + 1}</GridItem>
                  <GridItem>
                    {plValDetailsDetractors["vals1"] &&
                      plValDetailsDetractors["vals1"].length > val && (
                        <>
                          {(plValDetailsDetractors["vals1"][val].item
                            ? plValDetailsDetractors["vals1"][val].item.slice(
                                0,
                                15,
                              )
                            : "● " + t("results.blank") + " ●") + " ("}
                          <span
                            className={cx({
                              [classes.negative]: true,
                            })}
                          >
                            {(
                              100 * plValDetailsDetractors["vals1"][val].impact
                            ).toFixed(2) + " pp"}
                          </span>
                          {")"}
                        </>
                      )}
                  </GridItem>
                  <GridItem>
                    {plValDetailsDetractors["vals2"] &&
                      plValDetailsDetractors["vals2"].length > val && (
                        <>
                          {(plValDetailsDetractors["vals2"][val].item
                            ? plValDetailsDetractors["vals2"][val].item.slice(
                                0,
                                15,
                              )
                            : "● " + t("results.blank") + " ●") + " ("}
                          <span
                            className={cx({
                              [classes.negative]: true,
                            })}
                          >
                            {(
                              100 * plValDetailsDetractors["vals2"][val].impact
                            ).toFixed(2) + " pp"}
                          </span>
                          {")"}
                        </>
                      )}
                  </GridItem>
                  <GridItem>
                    {plValDetailsDetractors["vals3"] &&
                      plValDetailsDetractors["vals3"].length > val && (
                        <>
                          {(plValDetailsDetractors["vals3"][val].item
                            ? plValDetailsDetractors["vals3"][val].item.slice(
                                0,
                                15,
                              )
                            : "● " + t("results.blank") + " ●") + " ("}
                          <span
                            className={cx({
                              [classes.negative]: true,
                            })}
                          >
                            {(
                              100 * plValDetailsDetractors["vals3"][val].impact
                            ).toFixed(2) + " pp"}
                          </span>
                          {")"}
                        </>
                      )}
                  </GridItem>
                </GridContainer>
              </div>
            ))}
          </div>
          <h6 className={classes.drawerSectionTitle}>
            {t("results.details_modal.12m_evolution")}
          </h6>
          <div className={classes.drawerGraphWrapper}>
            <div className={classes.drawerGraph}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={getDetailsMonthlyEvolutionData()}
                type="Line"
                options={detailsMonthlyEvolution.options}
                listener={detailsMonthlyEvolution.animation}
              />
            </div>
          </div>
        </div>
        <div className={classes.drawerFootnote}>
          <ul>
            <li>{t("results.details_modal.footnote1")}</li>
            <li>{t("results.details_modal.footnote2")}</li>
          </ul>
        </div>
      </Drawer>
    </>
  );

  const plActions = (
    <>
      {/* Add P&L */}
      <GridItem className={classes.actionWrapperOuter} onClick={() => addPL(0)}>
        <div
          className={cx({
            [classes.actionWrapper]: true,
            [classes.actionDisabled]:
              !metaLoaded || numPLs === maxPLs || plsToRefresh.length > 0,
          })}
        >
          <div className={classes.actionIcon}>
            <AddCircle />
          </div>
          <h6 className={classes.actionText}>{t("results.add_pl")}</h6>
        </div>
      </GridItem>
      {/* Scenarios */}
      <ActionDropdown
        button={
          <GridItem className={classes.actionWrapperOuter}>
            <div
              className={cx({
                [classes.actionWrapper]: true,
                [classes.actionDisabled]:
                  !metaLoaded || plsToRefresh.length > 0 || scenarioBusy,
              })}
            >
              <div className={classes.actionIcon}>
                <BookmarkBorder />
              </div>
              <h6 className={classes.actionText}>
                {t("results.scenarios.scenarios")}
              </h6>
            </div>
          </GridItem>
        }
        dropdownList={getScenariosOptions()}
        onClick={handleScenarioSelection}
      ></ActionDropdown>
      {/* Unit */}
      <ActionDropdown
        button={
          <GridItem className={classes.actionWrapperOuter}>
            <div
              className={cx({
                [classes.actionWrapper]: true,
                [classes.actionDisabled]: !metaLoaded,
              })}
            >
              <div className={classes.actionIcon}>
                <AttachMoney />
              </div>
              <h6 className={classes.actionText}>{t("results.unit")}</h6>
            </div>
          </GridItem>
        }
        dropdownList={getUnitOptions()}
        onClick={handleUnitSelection}
      />
      {/* Download data */}
      <GridItem className={classes.actionWrapperOuter}>
        <div
          className={cx({
            [classes.actionWrapper]: true,
            [classes.actionDisabled]: !metaLoaded,
            [classes.actionDisabled]: true,
          })}
        >
          <div className={classes.actionIcon}>
            <GetApp />
          </div>
          <h6 className={classes.actionText}>{t("results.download_data")}</h6>
        </div>
      </GridItem>
      {/* Take photo */}
      <GridItem
        className={classes.actionWrapperOuter}
        onClick={downloadScreenshot}
      >
        <div
          className={cx({
            [classes.actionWrapper]: true,
            [classes.actionDisabled]: !metaLoaded,
            // [classes.actionDisabled]: true,
          })}
        >
          <div className={classes.actionIcon}>
            <PhotoCamera />
          </div>
          <h6 className={classes.actionText}>{t("results.take_photo")}</h6>
        </div>
      </GridItem>
      {/* Help */}
      <GridItem className={classes.actionWrapperOuter}>
        <div
          className={cx({
            [classes.actionWrapper]: true,
            [classes.actionDisabled]: !metaLoaded,
            [classes.actionDisabled]: true,
          })}
        >
          <div className={classes.actionIcon}>
            <HelpOutline />
          </div>
          <h6 className={classes.actionText}>{t("results.help")}</h6>
        </div>
      </GridItem>
    </>
  );

  return (
    <div>
      <Hidden smDown>
        <ActionBar
          actions={plActions}
          spinnerIn={!metaLoaded || plsToRefresh.length > 0 || scenarioBusy}
        />
      </Hidden>
      <div ref={resultsRef}>
        <GridContainer style={{ marginTop: "-28px" }}>
          <Hidden mdUp>
            <GridItem xs={12} onMouseEnter={() => clearTimeouts()}>
              <ActionBar
                actions={plActions}
                spinnerIn={
                  !metaLoaded || plsToRefresh.length > 0 || scenarioBusy
                }
                sm
              />
            </GridItem>
          </Hidden>
          <GridItem xs={12}>
            <Card className={classes.topCardShortSpacing}>
              <CardBody className={classes.topCardBody}>
                <GridContainer alignItems={"center"}>
                  <GridItem>
                    <div className={classes.topCardName}>
                      <span>{t("results.common_filters")}</span>
                      {metaLoaded && (
                        <FilterDropdown
                          dropPlacement={"bottom-start"}
                          hoverColor={"client"}
                          dropdownHeader={t("results.add_change_filter")}
                          dropdownList={filtersListTranslated()}
                          dropdownActiveItems={filtersActiveList("common")}
                          dropdownFilterSequence={filtersSequence("common")}
                          tooltip={t("results.add_change_filter")}
                          onClick={filterMenuClickHandler("common")}
                        />
                      )}
                    </div>
                  </GridItem>
                  <GridItem xs={true}>
                    {currentCommonFilters()}
                    {!metaLoaded && (
                      <ThreeDots
                        color={userDetails.implementation_color}
                        ariaLabel="loading"
                        height={"22px"}
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <GridContainer className={classes.plWrapperOuter}>
              {PLs}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
      {filtersDialog}
      {plNameDialog}
      {scenarioNameDialog}
      {shareViewDialog}
      {detailsDrawer}
    </div>
  );
}

PLExplorer.propTypes = {
  location: PropTypes.object,
};
