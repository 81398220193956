import {
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  clientBoxShadow,
  infoBoxShadow,
  successColor,
  successBoxShadow,
  warningColor,
  warningBoxShadow,
  dangerColor,
  dangerBoxShadow,
  roseColor,
  roseBoxShadow,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const customDropdownStyle = (theme, clientColor) => ({
  button: {
    color: grayColor[0],
    fontSize: "16px",
    cursor: "pointer",
    marginLeft: "8px",
    transition: "opacity 150ms",
    "&:hover": {
      color: grayColor[0],
      opacity: "0.5",
    },
    "&:before": {
      fontFamily: "'Font Awesome 5 Free'",
      content: "'\\f0b0'",
      padding: "0 0 0 2px",
      fontWeight: "900",
    },
  },
  popperClose: {
    pointerEvents: "none",
    display: "none !important",
  },
  dropdown: {
    borderRadius: "3px",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.26)",
    top: "100%",
    zIndex: "1000",
    minWidth: "160px",
    padding: "5px 0",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: whiteColor,
    backgroundClip: "padding-box",
  },
  menuList: {
    padding: "0",
  },
  popperResponsive: {
    zIndex: "1200",
    [theme.breakpoints.down("sm")]: {
      zIndex: "1640",
      position: "static",
      float: "none",
      width: "auto",
      marginTop: "0",
      backgroundColor: "transparent",
      border: "0",
      boxShadow: "none",
      color: "black",
    },
  },
  dropdownItem: {
    ...defaultFont,
    fontSize: "13px",
    padding: "6px 16px",
    margin: "0 5px",
    borderRadius: "2px",
    position: "relative",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    height: "100%",
    color: grayColor[7],
    whiteSpace: "nowrap",
    minHeight: "unset",
  },
  dropdownItemActive: {
    "&:before": {
      color: clientColor,
      position: "absolute",
      left: "3px",
      // content: "'\\25cf'",
      // fontSize: "14px",
      fontSize: "10px",
    },
    "&.sequence1": {
      "&:before": {
        content: "'1'",
      },
    },
    "&.sequence2": {
      "&:before": {
        content: "'2'",
      },
    },
    "&.sequence3": {
      "&:before": {
        content: "'3'",
      },
    },
    "&.sequence4": {
      "&:before": {
        content: "'4'",
      },
    },
    "&.sequence5": {
      "&:before": {
        content: "'5'",
      },
    },
    "&.sequence6": {
      "&:before": {
        content: "'6'",
      },
    },
    "&.sequence7": {
      "&:before": {
        content: "'7'",
      },
    },
    "&.sequence8": {
      "&:before": {
        content: "'8'",
      },
    },
    "&.sequence9": {
      "&:before": {
        content: "'9'",
      },
    },
    "&.sequenceN": {
      "&:before": {
        content: "'+'",
      },
    },
  },
  darkHover: {
    "&:hover": {
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(grayColor[16]) +
        ", 0.4)",
      backgroundColor: grayColor[16],
      color: whiteColor,
    },
  },
  clientHover: {
    "&:hover": {
      backgroundColor: clientColor,
      color: whiteColor,
      ...clientBoxShadow(clientColor),
      "&:before": {
        color: whiteColor,
      },
    },
  },
  primaryHover: {
    "&:hover": {
      backgroundColor: primaryColor[0],
      color: whiteColor,
      ...primaryBoxShadow,
    },
  },
  infoHover: {
    "&:hover": {
      backgroundColor: infoColor[0],
      color: whiteColor,
      ...infoBoxShadow,
    },
  },
  successHover: {
    "&:hover": {
      backgroundColor: successColor[0],
      color: whiteColor,
      ...successBoxShadow,
    },
  },
  warningHover: {
    "&:hover": {
      backgroundColor: warningColor[0],
      color: whiteColor,
      ...warningBoxShadow,
    },
  },
  dangerHover: {
    "&:hover": {
      backgroundColor: dangerColor[0],
      color: whiteColor,
      ...dangerBoxShadow,
    },
  },
  roseHover: {
    "&:hover": {
      backgroundColor: roseColor[0],
      color: whiteColor,
      ...roseBoxShadow,
    },
  },
  dropdownDividerItem: {
    margin: "5px 0",
    backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.12)",
    height: "1px",
    overflow: "hidden",
  },
  dropdownHeader: {
    display: "block",
    padding: "0 8px 6px 12px",
    fontSize: "0.75rem",
    lineHeight: "1.428571",
    color: grayColor[1],
    whiteSpace: "nowrap",
    fontWeight: "inherit",
    marginTop: "10px",
    minHeight: "unset",
    "&:hover,&:focus": {
      backgroundColor: "transparent",
      cursor: "auto",
    },
    pointerEvents: "none",
  },
  noLiPadding: {
    padding: "0",
  },
});

export default customDropdownStyle;
