/*eslint-disable*/
import React, { useContext } from "react";
import PropTypes from "prop-types";
import AuthContext from "../../contexts/AuthProvider";
import Heading from "../Heading/Heading";
import { useTranslation } from "react-i18next";

export default function NoAccessText(props) {
  let { userDetails } = useContext(AuthContext);
  const { t } = useTranslation();
  return (
    <div>
      <Heading
        title={t("no_permission.you_do_not_have_permission")}
        textAlign="center"
        category={
          <span>
            {t("no_permission.contact_your_admin")}
          </span>
        }
      />
      <div style={{ textAlign: "center" }}>
        {userDetails.implementation_admins.map((val, key) => {
          return (
            <h4 key={key}>
              {`${val[0]} - ${val[1]}`}
            </h4>
          )
        })}
        <div style={{marginTop: "30px"}}>
          <p>
            {t("no_permission.login_after_change")}
            {" "}
            {t("no_permission.click_to")} <a href={"/logout"}>logout.</a>
          </p>
        </div>
      </div>
    </div>
  );
}

NoAccessText.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
    "client",
  ]),
};
