const infoChangerStyle = {
  fader: {
    transition: ["opacity"],
    transitionDuration: 200,
  },
  faded: {
    opacity: 0,
  },
};

export default infoChangerStyle;
