import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "../../assets/jss/material-dashboard-pro-react/components/infoChangerStyle.js";
const useStyles = makeStyles(styles);

export default function InfoChanger(props) {
  const { info, interval } = props;
  const classes = useStyles();

  const [index, setIndex] = useState(0);
  const [faded, setFaded] = React.useState(false);
  const fade_interval = 200;
  let fader_interval = interval - fade_interval;

  const delayedChangeTimer = () => {
    setFaded(false);
    setIndex((prevIndex) => {
      if (prevIndex === info.length - 1) {
        return 0;
      }
      return prevIndex + 1;
    });
  };

  useEffect(() => {
    const change_timer = () => {
      setFaded(true);
      setTimeout(delayedChangeTimer, fade_interval);
    };
    setInterval(change_timer, fader_interval);

    // cleanup function in order clear the interval timer when the component unmounts
    return () => {
      clearInterval(change_timer);
    };
  }, []);

  const divClassNames = classNames({
    [classes.fader]: true,
    [classes.faded]: faded,
  });

  return <div className={divClassNames}>{info[index]}</div>;
}

InfoChanger.propTypes = {
  info: PropTypes.array,
  interval: PropTypes.number,
};
