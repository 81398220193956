import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

export default function Footer(props) {
  const { fluid, white, integrationGray, loginPage } = props;
  const altStyles = () => ({
    ...styles(!loginPage),
  });
  const useStyles = makeStyles(altStyles);

  const classes = useStyles();
  const { t } = useTranslation();
  const container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
    [classes.integrationGray]: integrationGray,
  });
  const anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white,
      [" " + classes.integrationGray]: integrationGray,
    });
  const block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white,
    [classes.integrationGray]: integrationGray,
  });
  const integrationLink = (
    <>
      &copy; {1900 + new Date().getYear()}{" "}
      <a
        href="https://www.integrationconsulting.com"
        className={anchor}
        target="_blank"
        rel="noreferrer"
      >
        {"Integration Consulting"}
      </a>
    </>
  );
  return (
    <div className={cx({ [classes.footerContainer]: !loginPage })}>
      <footer className={classes.footer}>
        <div className={container}>
          <div className={loginPage ? classes.center : classes.left}>
            {loginPage && (
              <div>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#" className={block}>
                      {t("footer.contact")}
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#" className={block}>
                      {t("footer.about")}
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a href="/extras/terms-of-use" className={block}>
                      {t("footer.terms_of_use")}
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://integrationconsulting.com/en/privacy-policy/"
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                      className={block}
                    >
                      {t("footer.privacy_policy")}
                    </a>
                  </ListItem>
                </List>
                <div
                  style={{
                    marginBottom: "18px",
                  }}
                >
                  {integrationLink}
                </div>
              </div>
            )}
          </div>
          {!loginPage && <p className={classes.right}>{integrationLink}</p>}
        </div>
      </footer>
    </div>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  integrationGray: PropTypes.bool,
  rtlActive: PropTypes.bool,
  loginPage: PropTypes.bool,
};
