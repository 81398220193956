import React from "react";
import { useContext } from "react";
import classNames from "classnames";
import LanguageButton from "../CustomButtons/LanguageButton";
import { makeStyles } from "@material-ui/core/styles";
import cx from "classnames";
import PropTypes from "prop-types";
import AuthContext from "../../contexts/AuthProvider";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

export default function HeaderLinks(props) {
  let { userDetails } = useContext(AuthContext);
  let defaultColor = "#808080";
  const client_styles = (theme) => ({
    ...styles(
      theme,
      userDetails ? userDetails.implementation_color : defaultColor,
    ),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();
  const navbarWrapperClass =
    classes.wrapper +
    " " +
    cx({
      [classes.whiteAfter]: props.bgColor === "white",
    });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });
  return (
    <div className={navbarWrapperClass}>
      <div className={managerClasses}>
        <LanguageButton bgColor={props.bgColor} />
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
};
