import React from "react";
import { useState, useEffect, useContext } from "react";
import cx from "classnames";
import { client } from "../services/axiosClient";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../reducers/authReducer";
import AuthContext from "../contexts/AuthProvider";
import { ThreeDots } from "react-loader-spinner";

import { makeStyles } from "@material-ui/core/styles";

import styles from "../assets/jss/material-dashboard-pro-react/layouts/welcomeStyle";
import { grayColor } from "../assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);

export default function Welcome() {
  const classes = useStyles();
  const history = useHistory();

  const [init, setInit] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [splashColor, setSplashColor] = useState("#fff");
  const [clientLogo, setClientLogo] = useState(null);
  const [clientLogoFade, setClientLogoFade] = useState(false);

  const dispatch = useDispatch();
  let { logoutUser } = useContext(AuthContext);

  useEffect(() => {
    setTimeout(() => {
      setInit(false);
    }, 0);
    setTimeout(() => {
      client
        .post(
          "api/post_login_info/",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // JWT token in localStorage
            },
          },
        )
        .then((response) => {
          dispatch(setUserDetails(response.data));
          setSplashColor("#fff");
          // setClientLogo(atob(response.data.implementation_logo));
          setClientLogo(response.data.implementation_logo);
          setTimeout(() => {
            setIsLoaded(true);
            setTimeout(() => {
              setSplashColor("#eee");
              setClientLogoFade(true);
              setTimeout(() => history.push("/main/overview"), 250);
            }, 1500);
          }, 500);
        })
        .catch((error) => {
          console.log(error);
          logoutUser(null, "Error fetching project data. Please try again.");
        });
    }, 0);
  }, []);

  return (
    <>
      <div className={classes.wrapper}>
        <div
          className={cx({
            [classes.welcomeText]: true,
            ["init"]: init,
            ["loaded"]: isLoaded,
          })}
        >
          {"welcome"}
        </div>
        <ThreeDots
          color={grayColor[0]}
          ariaLabel="loading"
          height={"12px"}
          wrapperStyle={{}}
          wrapperClass={cx({
            [classes.welcomeSpinner]: true,
            ["init"]: init,
          })}
        />
      </div>
      <div
        className={cx({
          [classes.clientSplash]: true,
          ["loaded"]: isLoaded,
        })}
        style={{ backgroundColor: splashColor }}
      >
        <img
          src={`data:image/png;base64,${encodeURIComponent(clientLogo)}`}
          alt="logo"
          className={cx({
            [classes.clientLogo]: true,
            ["faded"]: clientLogoFade,
          })}
        />
      </div>
    </>
  );
}
