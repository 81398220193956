import React, { useContext } from "react";
import cx from "classnames";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { ToastContainer } from "react-toastify";
import routes from "../routes";
import "assets/css/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { client } from "services/axiosClient";

import { makeStyles } from "@material-ui/core/styles";

// core components
import MainNavbar from "../components/Navbars/MainNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import MenuItem from "@material-ui/core/MenuItem";
import AuthContext from "../contexts/AuthProvider";
import { integrationColor } from "../assets/jss/material-dashboard-pro-react";

import ChangeProjectDialog from "../components/Dialog/ChangeProjectDialog";

let ps;

export default function Extras(props) {
  let { userDetails, logoutUser, authTokens } = useContext(AuthContext);
  let defaultColor = integrationColor[2];
  const client_styles = (theme) => ({
    ...styles(
      theme,
      userDetails ? userDetails.implementation_color : defaultColor,
    ),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();

  const { t } = useTranslation();
  const { ...rest } = props;

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(true);
  const [changeProjectModalOpen, setChangeProjectModalOpen] =
    React.useState(false);
  const [currentProject, setCurrentProject] = React.useState(
    userDetails ? userDetails.implementation_code : null,
  );
  const [changingProject, setChangingProject] = React.useState(false);
  const logo = userDetails ? userDetails.implementation_logo_white : null;
  // styles
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/extras") {
        return prop.external_link ? (
          <Link to={prop.external_link} target={"_blank"} />
        ) : (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const projectOptions = () => {
    if (userDetails && userDetails.implementation_ids.length > 1) {
      return userDetails.implementation_ids.map((imp, key) => (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={imp[0]}
          key={key}
        >
          {imp[1] +
            (imp[0] === userDetails && userDetails.implementation_code
              ? " (" + t("change_project.current") + ")"
              : "")}
        </MenuItem>
      ));
    } else {
      return null;
    }
  };
  const handleChangeProjectSelect = (event) => {
    setCurrentProject(event.target.value);
  };
  const handleChangeProjectConfirm = () => {
    let params = {
      implementation_code: currentProject,
      refresh_token: authTokens.refresh,
    };
    setChangingProject(true);
    client
      .post("api/change_project/", params)
      .then((response) => {
        if (response.data.success) {
          logoutUser();
        } else {
          console.error(response.data);
        }
        setChangingProject(false);
      })
      .catch((error) => {
        console.log(error);
        setChangingProject(false);
      });
  };

  const changeProjectDialog = (
    <ChangeProjectDialog
      open={changeProjectModalOpen}
      setOpen={setChangeProjectModalOpen}
      projectOptions={projectOptions}
      currentProject={currentProject}
      changingProject={changingProject}
      handleChangeProjectSelect={handleChangeProjectSelect}
      handleChangeProjectConfirm={handleChangeProjectConfirm}
      userDetails={userDetails}
    />
  );

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={userDetails ? userDetails.implementation_name : null}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={userDetails ? "client" : "integration"}
        miniActive={miniActive}
        {...rest}
        setChangeProjectModalOpen={setChangeProjectModalOpen}
        layout={"/extras"}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <MainNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <ToastContainer />
          <div className={classes.container}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/main" to="/main/no-permission" />
            </Switch>
          </div>
        </div>
        <Footer fluid />
        {userDetails && changeProjectDialog}
      </div>
    </div>
  );
}
