import { grayColor } from "assets/jss/material-dashboard-pro-react.js";

const helpTooltipStyle = {
  helpTag: {
    fontSize: "16px",
    color: grayColor[6],
    margin: "0 8px",
    cursor: "help",
  },
};

export default helpTooltipStyle;
