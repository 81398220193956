import React from "react";
import { useContext, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import { useLocation, NavLink } from "react-router-dom";
import cx from "classnames";
import AuthContext from "../../contexts/AuthProvider";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";

import Icon from "@material-ui/core/Icon";
import LanguageIcon from "@material-ui/icons/Language";

import { useTranslation } from "react-i18next";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";

import default_avatar from "assets/img/default-avatar.png";
import logo_integration from "assets/img/logo_integration_white.png";
import logo_insights from "assets/img/logo_profit_insights_white.png";
import i18n from "i18next";
import { integrationColor } from "../../assets/jss/material-dashboard-pro-react";

let ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
function SidebarWrapper({
  className,
  user,
  headerLinks,
  languages,
  links,
  miniActive,
}) {
  const useStyles = makeStyles(sidebarStyle);
  const classes = useStyles();
  const sidebarWrapper = useRef();
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  return (
    <>
      <div className={className} ref={sidebarWrapper}>
        {user}
        {languages}
        {headerLinks}
        {links}
      </div>
      <div style={{ marginTop: "38px" }}>
        <div
          className={cx({
            [classes.sidebarLogosWrapper]: true,
            ["hidden"]: miniActive,
          })}
        >
          <img
            src={logo_insights}
            className={cx({
              [classes.logoInsights]: true,
              ["hidden"]: miniActive,
            })}
            alt={"logo_insights"}
          />
          <img
            src={logo_integration}
            className={cx({
              [classes.logoIntegration]: true,
              ["hidden"]: miniActive,
            })}
            alt={"logo_integration"}
          />
        </div>
      </div>
    </>
  );
}

function Sidebar(props) {
  let { userDetails, logoutUser } = useContext(AuthContext);
  let defaultColor = integrationColor[2];
  const client_styles = (theme) => ({
    ...sidebarStyle(
      theme,
      userDetails ? userDetails.implementation_color : defaultColor,
    ),
  });
  const useStyles = makeStyles(client_styles);

  const classes = useStyles();
  const [miniActive, setMiniActive] = useState(true);
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the user collapse
  const [openAvatar, setOpenAvatar] = useState(false);
  // this is for the rest of the collapses
  const [state, setState] = useState({});
  useEffect(() => {
    setState(getCollapseStates(props.routes));
  }, []);
  const mainPanel = useRef();
  // this creates the initial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname === routes[i].layout + routes[i].path) {
        return true;
      }
    }
    return false;
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  const activeLanguage = (language) => {
    return language === i18n.language;
  };
  const setLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  // this function creates the language selector in the sidebar (left menu)
  const createLanguageSelector = (views) => {
    const { color } = props;
    const languages = views || [
      {
        collapse: true,
        state: "languageCollapse",
        name: t("navbar.change_language"),
        icon: LanguageIcon,
        views: [
          { name: "English", mini: "ENG", language: "en" },
          { name: "Español", mini: "ESP", language: "es" },
          { name: "Português", mini: "POR", language: "pt" },
        ],
      },
    ];
    return languages.map((prop, key) => {
      if (prop.collapse) {
        let st = {};
        st[prop["state"]] = !state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: true,
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]: props.miniActive && miniActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]: props.miniActive && miniActive,
          });
        const itemIcon = classes.itemIcon;
        const caret = classes.caret;
        const collapseItemMini = classes.collapseItemMini;
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined },
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>{prop.mini}</span>
              )}
              <ListItemText
                primary={prop.name}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined },
                )}
              />
            </NavLink>
            <Collapse in={state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {createLanguageSelector(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: activeLanguage(prop.language),
        });
      const collapseItemMini = classes.collapseItemMini;
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]: props.miniActive && miniActive,
        });
      return (
        <ListItem key={key} className={classes.collapseItem}>
          <div
            onClick={() => setLanguage(prop.language)}
            className={innerNavLinkClasses}
          >
            <span className={collapseItemMini}>{prop.mini}</span>
            <ListItemText
              primary={prop.name}
              disableTypography={true}
              className={collapseItemText}
            />
          </div>
        </ListItem>
      );
    });
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    const { color, layout } = props;
    return routes.map((prop, key) => {
      if (prop.redirect || (!prop.collapse && prop.layout !== layout)) {
        return null;
      }
      if (
        prop.allowedRoles &&
        !prop.allowedRoles.includes(userDetails ? userDetails.user_role : null)
      ) {
        return null;
      }
      if (
        prop.showOnlyWhenRedirected &&
        window.location.href.indexOf(prop.layout + prop.path) === -1
      ) {
        return null;
      }
      if (prop.collapse) {
        if (prop.layout && prop.layout !== layout) return null;
        let st = {};
        st[prop["state"]] = !state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: getCollapseInitialState(prop.views),
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]: props.miniActive && miniActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]: props.miniActive && miniActive,
          });
        const itemIcon = classes.itemIcon;
        const caret = classes.caret;
        const collapseItemMini = classes.collapseItemMini;
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined },
            )}
          >
            <div
              // to={"#"}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>{t(prop.mini)}</span>
              )}
              <ListItemText
                primary={t(prop.name)}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined },
                )}
              />
            </div>
            <Collapse in={state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path),
        });
      const collapseItemMini = classes.collapseItemMini;
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path),
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]: props.miniActive && miniActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]: props.miniActive && miniActive,
        });
      const itemIcon = classes.itemIcon;
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined },
          )}
        >
          {prop.external_link ? (
            <a
              // to={prop.external_link}
              href={prop.external_link}
              target={"_blank"}
              rel={"noopener noreferrer"}
              className={cx(
                { [navLinkClasses]: prop.icon !== undefined },
                { [innerNavLinkClasses]: prop.icon === undefined },
                { [classes.disabled]: prop.disabled },
              )}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>{t(prop.mini)}</span>
              )}
              <ListItemText
                primary={t(prop.name)}
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined },
                )}
              />
            </a>
          ) : (
            <NavLink
              to={prop.layout + prop.path}
              className={cx(
                { [navLinkClasses]: prop.icon !== undefined },
                { [innerNavLinkClasses]: prop.icon === undefined },
                { [classes.disabled]: prop.disabled },
              )}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>{t(prop.mini)}</span>
              )}
              <ListItemText
                primary={t(prop.name)}
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined },
                )}
              />
            </NavLink>
          )}
        </ListItem>
      );
    });
  };
  const { logo, image, logoText, routes, bgColor } = props;
  const itemText =
    classes.itemText +
    " " +
    cx({
      [classes.itemTextMini]: props.miniActive && miniActive,
    });
  const collapseItemText =
    classes.collapseItemText +
    " " +
    cx({
      [classes.collapseItemTextMini]: props.miniActive && miniActive,
    });
  const userWrapperClass =
    classes.user +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white",
    });
  const languagesWrapperClass =
    classes.languages +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white",
    });
  const caret = classes.caret;
  const collapseItemMini = classes.collapseItemMini;
  const photo = classes.photo;
  const { t } = useTranslation();
  let userWrapper = userDetails ? (
    <div className={userWrapperClass}>
      <div className={photo}>
        <img
          src={
            !userDetails.user_avatar
              ? default_avatar
              : `data:image/png;base64,${userDetails.user_avatar}`
          }
          className={classes.avatarImg}
          alt="..."
        />
      </div>
      <List className={classes.list}>
        <ListItem className={classes.item + " " + classes.userItem}>
          <div
            className={classes.itemLink + " " + classes.userCollapseButton}
            onClick={() => setOpenAvatar(!openAvatar)}
          >
            <ListItemText
              primary={userDetails.name}
              secondary={
                <b
                  className={
                    caret +
                    " " +
                    classes.userCaret +
                    " " +
                    (openAvatar ? classes.caretActive : "")
                  }
                />
              }
              disableTypography={true}
              className={itemText + " " + classes.userItemText}
            />
          </div>
          <Collapse in={openAvatar} unmountOnExit>
            <List className={classes.list + " " + classes.collapseList}>
              {userDetails.social_login === false && (
                <ListItem className={classes.collapseItem}>
                  <div
                    className={
                      classes.itemLink + " " + classes.userCollapseLinks
                    }
                    onClick={() => props.setChangePasswordModalOpen(true)}
                  >
                    <span className={collapseItemMini}>
                      {t("sidebar_cts.profile_menu_mini.change_password")}
                    </span>
                    <ListItemText
                      primary={t("sidebar_cts.profile_menu.change_password")}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </div>
                </ListItem>
              )}
              {userDetails.implementation_ids.length > 1 && (
                <ListItem className={classes.collapseItem}>
                  <div
                    className={
                      classes.itemLink + " " + classes.userCollapseLinks
                    }
                    onClick={() => props.setChangeProjectModalOpen(true)}
                  >
                    <span className={collapseItemMini}>
                      {t("sidebar_cts.profile_menu_mini.change_project")}
                    </span>
                    <ListItemText
                      primary={t("sidebar_cts.profile_menu.change_project")}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </div>
                </ListItem>
              )}
              <ListItem className={classes.collapseItem}>
                <div
                  className={classes.itemLink + " " + classes.userCollapseLinks}
                  onClick={(e) => logoutUser(e)}
                >
                  <span className={collapseItemMini}>
                    {t("sidebar_cts.profile_menu_mini.logout")}
                  </span>
                  <ListItemText
                    primary={t("sidebar_cts.profile_menu.logout")}
                    disableTypography={true}
                    className={collapseItemText}
                  />
                </div>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
      </List>
    </div>
  ) : null;
  let languages = (
    <List className={classes.list + " " + languagesWrapperClass}>
      {createLanguageSelector()}
    </List>
  );
  let links = <List className={classes.list}>{createLinks(routes)}</List>;

  const logoNormal =
    classes.logoNormal +
    " " +
    cx({
      [classes.logoNormalSidebarMini]: props.miniActive && miniActive,
    });
  const logoMini = classes.logoMini;
  const logoClasses =
    classes.logo +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white",
    });
  let brand = logo ? (
    <div className={logoClasses}>
      <div className={logoMini}>
        <img
          // src={logo}
          // src={`data:image/svg+xml;utf8,${encodeURIComponent(logo)}`}
          src={`data:image/png;base64,${logo}`}
          alt="logo"
          className={classes.img}
        />
      </div>
      <div className={logoNormal}>{logoText}</div>
    </div>
  ) : null;
  const drawerPaper =
    classes.drawerPaper +
    " " +
    cx({
      [classes.drawerPaperMini]: props.miniActive && miniActive,
    });
  const sidebarWrapper = cx({
    [classes.sidebarWrapper]: true,
    [classes.drawerPaperMini]: props.miniActive && miniActive,
    [classes.sidebarWrapperWithPerfectScrollbar]:
      navigator.platform.indexOf("Win") > -1,
  });

  return (
    <div ref={mainPanel}>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={props.open}
          classes={{
            paper: drawerPaper + " " + classes[bgColor + "Background"],
            root: classes.customRoot,
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <SidebarWrapper
            className={sidebarWrapper}
            user={userWrapper}
            // headerLinks={<AdminNavbarLinks bgColor={bgColor} />}
            languages={languages}
            links={links}
            miniActive={props.miniActive && miniActive}
          />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          onMouseOver={() => setMiniActive(false)}
          onMouseOut={() => setMiniActive(true)}
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: drawerPaper + " " + classes[bgColor + "Background"],
          }}
        >
          {brand}
          <SidebarWrapper
            className={sidebarWrapper}
            user={userWrapper}
            links={links}
            miniActive={props.miniActive && miniActive}
          />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.defaultProps = {
  bgColor: "black",
  layout: "/main",
};

Sidebar.propTypes = {
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  color: PropTypes.oneOf([
    "integration",
    "client",
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  disabled: PropTypes.bool,
  setChangePasswordModalOpen: PropTypes.func,
  setChangeProjectModalOpen: PropTypes.func,
  layout: PropTypes.string,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
  languages: PropTypes.object,
  miniActive: PropTypes.bool,
};

export default Sidebar;
