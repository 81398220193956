import { grayColor, primaryColor } from "../../material-dashboard-pro-react";

const customTextFieldStyle = (userDetails) => ({
  root: {
    "& > div": {
      backgroundColor: grayColor[13],
      "& > textarea": {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "12px",
      },
    },
    "& > label": {
      color: grayColor[0],
      paddingLeft: "8px",
      zIndex: "9999",
    },
    "& label.Mui-focused": {
      color: grayColor[0],
    },
    "& .MuiInput-underline": {
      "&:before": {
        borderColor: grayColor[4] + " !important",
        borderWidth: "1px !important",
      },
      "&:after": {
        borderColor: userDetails
          ? userDetails.implementation_color
          : primaryColor[0],
      },
      "& + p": {
        fontWeight: "300",
      },
      // borderBottomColor:
      //   client && userDetails
      //     ? userDetails.implementation_color
      //     : primaryColor[0],
    },
  },
});

export default customTextFieldStyle;
