import {
  whiteColor,
  // blackColor,
  // integrationColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const pagesStyle = (theme) => ({
  wrapper: {
    // height: "100vh",
    [theme.breakpoints.up("lg")]: {
      height: "100vh",
    },
    minHeight: "100vh",
    position: "relative",
    top: "0",
  },
  fullPage: {
    padding: "120px 0",
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",
    margin: "0",
    border: "0",
    color: whiteColor,
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    overflowX: "hidden",
    [theme.breakpoints.down("lg")]: {
      padding: "80px 0 110px !important",
    },
    [theme.breakpoints.down("md")]: {
      padding: "40px 0 100px !important",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "700px!important",
      padding: "40px 0 !important",
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important",
    },
    "&:before": {
      // backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.5)",
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.5)",
      // backgroundColor: "rgba(" + hexToRgb(integrationColor[1]) + ", 0)",
      // backgroundColor: integrationColor[1],
    },
    "&:before,&:after": {
      display: "block",
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "2",
    },
  },
});

export default pagesStyle;
