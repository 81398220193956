import React from "react";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../../contexts/AuthProvider";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import cx from "classnames";
import LoaderBar from "../../components/Loaders/LoaderBar";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";

import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Tooltip from "@material-ui/core/Tooltip";

import MicrosoftAuth from "../../services/MicrosoftAuth";
import GoogleAuth from "../../services/GoogleAuth";
import PropTypes from "prop-types";
import qs from "query-string";

const useStyles = makeStyles(styles);

export default function Login(props) {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [cardAnimation, setCardAnimation] = useState("cardHidden");
  const [loginError, setLoginError] = useState(
    props.history.location.state?.error || "",
  );
  const [loggingIn, setLoggingIn] = useState(false);
  const [socialLoggingIn, setSocialLoggingIn] = useState(false);

  const classes = useStyles();
  let { loginUser } = useContext(AuthContext);

  const get_params = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const redir = get_params?.redir;

  if (user && userDetails && !loggingIn && !socialLoggingIn) {
    history.push("/main/overview");
  }

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  useEffect(() => {
    if (loginError !== "") setLoggingIn(false);
  }, [loginError]);

  return (
    <>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={8} md={4}>
            <form
              onSubmit={(e) => {
                if (loggingIn) {
                  e.preventDefault();
                  return false;
                } else {
                  setLoginError("");
                  loginUser(e, setLoginError, setIsLoggedIn, redir);
                  setLoggingIn(true);
                }
              }}
            >
              <Card login className={classes[cardAnimation]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="integration"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                  <div className={classes.socialLine}>
                    {[
                      {
                        tooltip: "Login with Google",
                        btn: (
                          <GoogleAuth
                            setLoginError={setLoginError}
                            setIsLoggedIn={setIsLoggedIn}
                            setSocialLoggingIn={setSocialLoggingIn}
                            redir={redir}
                          />
                        ),
                      },
                      {
                        tooltip: "Login with Microsoft",
                        btn: (
                          <MicrosoftAuth
                            setLoginError={setLoginError}
                            setIsLoggedIn={setIsLoggedIn}
                            setSocialLoggingIn={setSocialLoggingIn}
                            redir={redir}
                          />
                        ),
                      },
                    ].map((prop, key) => {
                      return (
                        <Tooltip title={prop.tooltip} key={key}>
                          <Button
                            color="transparent"
                            justIcon
                            className={classes.customButtonClass}
                          >
                            {prop.btn}
                          </Button>
                        </Tooltip>
                      );
                    })}
                  </div>
                </CardHeader>
                <CardBody style={{ paddingBottom: "6px" }}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                      style: { marginBottom: "10px" },
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      type: "email",
                      disabled: loggingIn || socialLoggingIn,
                    }}
                    integration
                  />
                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                      disabled: loggingIn || socialLoggingIn,
                    }}
                    integration
                  />
                  <div
                    className={cx({
                      [classes.loginErrorMessage]: true,
                      ["nonCritical"]: socialLoggingIn,
                    })}
                  >
                    {loginError}
                  </div>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Button
                        type="submit"
                        color="integration"
                        simple
                        size="lg"
                        block
                        className={cx({
                          [classes.buttonDisabled]:
                            loggingIn || socialLoggingIn,
                        })}
                      >
                        {"Login"}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardFooter>
                <div
                  className={cx({
                    [classes.invisible]: !loggingIn && !socialLoggingIn,
                  })}
                >
                  <LoaderBar />
                </div>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
      <div
        className={cx({
          [classes.preSplash]: true,
          ["loggedIn"]: isLoggedIn,
        })}
      ></div>
    </>
  );
}

Login.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
