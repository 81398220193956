import React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";
import AuthContext from "../../contexts/AuthProvider";

import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

import LanguageIcon from "@material-ui/icons/Language";

import Button from "components/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

export default function LanguageButton(props) {
  let { userDetails } = useContext(AuthContext);
  let defaultColor = "#808080";
  const client_styles = (theme) => ({
    ...styles(
      theme,
      userDetails ? userDetails.implementation_color : defaultColor,
    ),
  });
  const useStyles = makeStyles(client_styles);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [openLanguage, setOpenLanguage] = React.useState(null);
  const { t, i18n } = useTranslation();
  const handleClickLanguage = (event) => {
    setOpen(false);
    if (openLanguage && openLanguage.contains(event.target)) {
      setOpenLanguage(null);
    } else {
      setOpenLanguage(event.currentTarget);
    }
  };
  const handleSetLanguage = (lang) => () => {
    i18n.changeLanguage(lang);
    setOpenLanguage(null);
  };
  const handleCloseLanguage = () => {
    setOpenLanguage(null);
  };
  const dropdownItem = classNames(classes.dropdownItem, classes.clientHover, {
    [classes.whiteColor]: props.bgColor !== "white",
    [classes.grayColor]: props.bgColor === "white",
  });
  const currentLanguageText = classes.currentLanguageText;

  return (
    <>
      <Tooltip
        open={open}
        id="tooltip-top"
        title={t("navbar.change_language")}
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <Button
          color="transparent"
          aria-label="Language"
          justIcon
          aria-owns={openLanguage ? "language-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickLanguage}
          className={classes.buttonLink}
          muiClasses={{
            label: "",
          }}
        >
          <LanguageIcon
            className={classes.headerLinksSvg + " " + classes.links}
          />
          <span className={currentLanguageText}>{t("navbar.language")}</span>
          <Hidden mdUp implementation="css">
            <span onClick={handleClickLanguage} className={classes.linkText}>
              {t("navbar.change_language")}
            </span>
          </Hidden>
        </Button>
      </Tooltip>
      <Popper
        open={Boolean(openLanguage)}
        anchorEl={openLanguage}
        transition
        disablePortal
        placement="bottom"
        className={classNames({
          [classes.popperClose]: !openLanguage,
          [classes.popperResponsive]: true,
          [classes.popperNav]: true,
        })}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="language-menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseLanguage}>
                <MenuList
                  role="menu"
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <MenuItem
                    onClick={handleSetLanguage("en")}
                    className={dropdownItem}
                  >
                    {"English"}
                  </MenuItem>
                  <MenuItem
                    onClick={handleSetLanguage("es")}
                    className={dropdownItem}
                  >
                    {"Español"}
                  </MenuItem>
                  <MenuItem
                    onClick={handleSetLanguage("pt")}
                    className={dropdownItem}
                  >
                    {"Português"}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

LanguageButton.propTypes = {
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
};
