import {
  whiteColor,
  integrationColor,
} from "assets/jss/material-dashboard-pro-react.js";

const welcomeStyle = () => ({
  wrapper: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    background: integrationColor[2] + "05",
  },
  clientSplash: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    background: "#fff",
    zIndex: "1",
    opacity: "0",
    transition: "all 800ms",
    "&.loaded": {
      opacity: "1",
    },
  },
  welcomeText: {
    textTransform: "lowercase",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "40px",
    zIndex: "2",
    transition: "all 600ms",
    "&.init": {
      opacity: "0",
    },
    "&:not(.loaded)": {
      top: "45%",
      color: integrationColor[1],
    },
    "&.loaded": {
      top: "0%",
      color: whiteColor,
      opacity: "0",
    },
  },
  welcomeSpinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    transition: "all 600ms",
    "&:not(.init)": {
      transitionDelay: "400ms",
    },
    "&.init": {
      opacity: "0",
    },
    "&.loaded": {
      opacity: "0",
      transitionDelay: "0",
    },
  },
  clientLogo: {
    position: "absolute",
    top: "47%",
    left: "50%",
    maxHeight: "250px",
    maxWidth: "90%",
    transform: "translate(-50%, -50%)",
    transition: "all 400ms ease",
    "&.faded": {
      opacity: "0",
    },
  },
});

export default welcomeStyle;
