/*eslint-disable*/
/*!

=========================================================
* Material Main PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "./i18n";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// const msClientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
const msClientId = "27919373-3c5d-433c-ac5c-9d6c9dc4dcb7";
// const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const googleClientId =
  "326066756623-dl18bv8vgl16idvgj3if5u87qj99l8ol.apps.googleusercontent.com";

import { persistor, store } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Suspense } from "react";

import { AuthProvider } from "./contexts/AuthProvider";
import AuthLayout from "layouts/Auth.js";
import MainLayout from "./layouts/Main.js";
import Welcome from "./layouts/Welcome.js";
import Extras from "./layouts/Extras";
import LogOut from "./logout";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./services/authConfig";

const msalInstance = new PublicClientApplication(msalConfig(msClientId));

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={null}>
          <AuthProvider>
            <MsalProvider instance={msalInstance}>
              <GoogleOAuthProvider clientId={googleClientId}>
                <Switch>
                  <Route path="/auth" component={AuthLayout} />
                  <Route path="/main" component={MainLayout} />
                  <Route path="/welcome" component={Welcome} />
                  <Route path="/extras" component={Extras} />
                  <Route path="/logout" component={LogOut} />
                  <Redirect from="/" to="/main/overview" />
                </Switch>
              </GoogleOAuthProvider>
            </MsalProvider>
          </AuthProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
);
