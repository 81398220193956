import {
  clientCardHeader,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const cardIconStyle = (clientColor) => ({
  cardIcon: {
    "&$clientCardHeader,&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader":
      {
        borderRadius: "3px",
        backgroundColor: grayColor[0],
        padding: "5px",
        marginTop: "-10px",
        marginRight: "15px",
        float: "left",
      },
  },
  clientCardHeader: {
    ...clientCardHeader(clientColor),
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
});

export default cardIconStyle;
