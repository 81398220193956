import { createSlice } from "@reduxjs/toolkit";

export const fileTransferSlice = createSlice({
  name: "file_transfer",
  initialState: {
    progress: 0,
  },
  reducers: {
    setProgress(state, action) {
      state.progress = action.payload;
    },
  },
});

export const { setProgress } = fileTransferSlice.actions;

export default fileTransferSlice.reducer;
