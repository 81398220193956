import React, { useContext } from "react";
import PropTypes from "prop-types";
import AuthContext from "../../contexts/AuthProvider";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import { makeStyles } from "@mui/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/badgeStyle.js";

export default function Badge(props) {
  let { userDetails } = useContext(AuthContext);
  const client_styles = () => ({
    ...styles(userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const { color, children } = props;
  const classes = useStyles();
  return (
    <span className={classes.badge + " " + classes[color]}>{children}</span>
  );
}

Badge.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
    "client",
  ]),
  children: PropTypes.node,
};
