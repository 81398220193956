import {
  defaultFont,
  clientBoxShadow,
  whiteColor,
  grayColor,
  hexToRgb,
  blackColor,
} from "assets/jss/material-dashboard-pro-react.js";

const buttonDropdownStyle = (theme, clientColor) => ({
  dropdown: {
    borderRadius: "3px",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.26)",
    top: "100%",
    zIndex: "-1",
    minWidth: "160px",
    padding: "3px 0",
    margin: "12px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: grayColor[12],
    backgroundClip: "padding-box",
  },
  dropdownItem: {
    ...defaultFont,
    fontSize: "12px",
    padding: "2px 20px",
    margin: "0 3px",
    borderRadius: "2px",
    position: "relative",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    height: "100%",
    color: grayColor[7],
    whiteSpace: "nowrap",
    minHeight: "unset",
  },
  clientHover: {
    "&:hover": {
      backgroundColor: clientColor,
      color: whiteColor,
      ...clientBoxShadow(clientColor),
      "&:before": {
        color: whiteColor,
      },
    },
  },
  menuList: {
    padding: "0",
  },
  dropdownHeader: {
    display: "block",
    padding: "0 8px 6px 12px",
    fontSize: "0.75rem",
    lineHeight: "1.428571",
    color: grayColor[1],
    whiteSpace: "nowrap",
    fontWeight: "inherit",
    marginTop: "10px",
    minHeight: "unset",
    "&:hover,&:focus": {
      backgroundColor: "transparent",
      cursor: "auto",
    },
    pointerEvents: "none",
  },
  dropdownExplainer: {
    fontStyle: "italic",
  },
  dropdownItemActive: {
    "&:before": {
      color: clientColor,
      position: "absolute",
      left: "5px",
      content: "'\\25cf'",
      fontSize: "14px",
    },
  },
  dropdownDividerItem: {
    margin: "5px 0",
    backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.12)",
    height: "1px",
    overflow: "hidden",
  },
  popperClose: {
    pointerEvents: "none",
    display: "none !important",
  },
  popperResponsive: {
    zIndex: "1200",
    [theme.breakpoints.down("sm")]: {
      zIndex: "1640",
      position: "static",
      float: "none",
      width: "auto",
      marginTop: "0",
      backgroundColor: "transparent",
      border: "0",
      boxShadow: "none",
      color: "black",
    },
  },
});

export default buttonDropdownStyle;
