import React from "react";
import { createContext } from "react";
import PropTypes from "prop-types";
import { client } from "../services/axiosClient";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setAuthTokens,
  setUser,
  setUserDetails,
} from "../reducers/authReducer";
import { baseUrl } from "utils/utils";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const authTokens = useSelector((state) => state.auth.authTokens);
  const user = useSelector((state) => state.auth.user);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const dispatch = useDispatch();
  const history = useHistory();

  let loginUser = async (e, setLoginError, setIsLoggedIn, redir) => {
    e.preventDefault();

    const cred = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    const { data } =
      (await client
        .post(baseUrl + "token/", cred, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch(function (error) {
          if (error.response) {
            setLoginError(error.response.data.detail);
          } else {
            setLoginError("Error on login request");
          }
          return null;
        })) || {};

    if (data) {
      dispatch(setAuthTokens(data));
      dispatch(setUser(jwtDecode(data.access)));
      setIsLoggedIn(true);
      if (redir && userDetails) {
        history.push(redir);
      } else {
        dispatch(setUserDetails(null));
        setTimeout(() => history.push("/welcome"), 600);
      }
    }
  };

  let logoutUser = (e, error) => {
    if (e) e.preventDefault();
    dispatch(setAuthTokens(null));
    dispatch(setUser(null));
    dispatch(setUserDetails(null));
    history.push("/auth/login", error ? { error } : {});
  };

  let contextData = {
    user: user,
    userDetails: userDetails,
    authTokens: authTokens,
    loginUser: loginUser,
    logoutUser: logoutUser,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
