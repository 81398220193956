import {
  // whiteColor,
  integrationColor,
} from "../../material-dashboard-pro-react";

const useStyles = {
  loaderBar: {
    position: "relative",
    width: "100%",
    height: "3px",
    overflow: "hidden",
    borderRadius: "0 0 10px 10px",
  },
  loader: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: integrationColor[3], // Color of the loader
    animation: "$loading 1.5s infinite cubic-bezier(0.95, 0.05, 0.05, 0.95)",
  },
  "@keyframes loading": {
    "0%": {
      transform: "translateX(-100%)",
    },
    "50%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(100%)",
    },
  },
};

export default useStyles;
