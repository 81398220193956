import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import cx from "classnames";
import { Close } from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Muted from "../Typography/Muted";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../CustomInput/CustomInput";
import CustomLinearProgress from "../CustomLinearProgress/CustomLinearProgress";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { dangerColor } from "../../assets/jss/material-dashboard-pro-react";
import { client } from "../../services/axiosClient";
import { toast } from "react-toastify";
import { Slide as ToastSlide } from "react-toastify";

const stripText = (text) => {
  if (!text) return "";
  return text.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
};

const styles = (theme, clientColor) => ({
  ...modalStyle(theme),
  ...customSelectStyle(theme, clientColor),
  fontCenter: {
    // textAlign: "center !important",
  },
  errorMessage: {
    color: dangerColor[0],
    marginBottom: "24px",
  },
});

const ChangePasswordModal = ({
  open,
  setOpen,
  // changingPassword,
  // changePasswordError,
  userDetails,
}) => {
  const client_styles = (theme) => ({
    ...styles(theme, userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();
  const { t } = useTranslation();

  const oldPasswordInput = useRef(null);
  const newPasswordInput1 = useRef(null);
  const newPasswordInput2 = useRef(null);

  const [changingPassword, setChangingPassword] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState(null);

  const handleOldPasswordChange = (e) => {
    oldPasswordInput.current.value = e.target.value;
    setChangePasswordError(null);
  };
  const handleNewPasswordChange1 = (e) => {
    newPasswordInput1.current.value = e.target.value;
    setChangePasswordError(null);
  };
  const handleNewPasswordChange2 = (e) => {
    newPasswordInput2.current.value = e.target.value;
    setChangePasswordError(null);
  };

  const handleChangePasswordConfirm = () => {
    setChangePasswordError(null);
    oldPasswordInput.current.value = stripText(oldPasswordInput.current.value);
    newPasswordInput1.current.value = stripText(
      newPasswordInput1.current.value,
    );
    newPasswordInput2.current.value = stripText(
      newPasswordInput2.current.value,
    );
    if (
      oldPasswordInput.current.value === "" ||
      newPasswordInput1.current.value === "" ||
      newPasswordInput2.current.value === ""
    ) {
      setChangePasswordError("all_fields_must_be_filled");
      return false;
    }
    if (newPasswordInput1.current.value !== newPasswordInput2.current.value) {
      setChangePasswordError("password_mismatch");
      return false;
    }
    if (newPasswordInput1.current.value === oldPasswordInput.current.value) {
      setChangePasswordError("same_password");
      return false;
    }
    setChangingPassword(true);
    let params = {
      old_password: oldPasswordInput.current.value,
      new_password1: newPasswordInput1.current.value,
      new_password2: newPasswordInput2.current.value,
    };
    client
      .post("accounts/change_password/", params)
      .then(() => {
        notify();
        setChangingPassword(false);
        setOpen(false);
      })
      .catch((error) => {
        if (error.response.request.status === 400 && error.response.data) {
          setChangePasswordError(error.response.data.error);
        } else {
          setChangePasswordError("system_error");
          console.log(error);
        }
        setChangingPassword(false);
      });
  };

  const notify = () =>
    toast(t("change_password.success"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      type: "info",
      transition: ToastSlide,
    });

  useEffect(() => {
    if (
      open &&
      oldPasswordInput.current &&
      newPasswordInput1.current &&
      newPasswordInput2.current
    ) {
      oldPasswordInput.current.firstChild.value = "";
      newPasswordInput1.current.firstChild.value = "";
      newPasswordInput2.current.firstChild.value = "";
      setTimeout(() => oldPasswordInput.current.firstChild.focus(), 200);
    }
  }, [open]);

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle
        id="content-modal-slide-title"
        disableTypography
        className={cx({
          [classes.modalHeader]: true,
          // [classes.fontCenter]: true,
        })}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setOpen(false)}
          disabled={changingPassword}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>
          <span className={classes.modalHeader}>
            {t("sidebar_cts.profile_menu.change_password")}
          </span>
        </h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <FormControl fullWidth className={classes.selectFormControl}>
          <CustomInput
            ref={oldPasswordInput}
            onChange={handleOldPasswordChange}
            labelText={t("change_password.current_password")}
            id="old_password"
            formControlProps={{
              fullWidth: true,
              style: { marginTop: "-20px" },
            }}
            inputProps={{
              type: "password",
              autoComplete: "off",
              disabled: changingPassword,
            }}
            integration
          />
          <CustomInput
            ref={newPasswordInput1}
            onChange={handleNewPasswordChange1}
            labelText={t("change_password.new_password")}
            id="password1"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "password",
              autoComplete: "off",
              disabled: changingPassword,
            }}
            integration
          />
          <CustomInput
            ref={newPasswordInput2}
            onChange={handleNewPasswordChange2}
            onEnterKey={() => handleChangePasswordConfirm()}
            labelText={t("change_password.password_confirm")}
            id="password2"
            formControlProps={{
              fullWidth: true,
              style: { marginTop: "-15px" },
            }}
            inputProps={{
              type: "password",
              autoComplete: "off",
              disabled: changingPassword,
            }}
            integration
          />
        </FormControl>
        {changePasswordError && (
          <div className={classes.errorMessage}>
            {t("change_password." + changePasswordError)}
          </div>
        )}
        <div style={{ marginTop: "5px", marginBottom: "-5px" }}>
          <Muted>
            <span>{t("change_password.password_requirements")}</span>
            {": "}
            {[...Array(4).keys()].map((key, val) => {
              return (
                <React.Fragment key={key}>
                  <br />
                  <span>
                    {"• "}
                    {t("change_password.requirement" + (val + 1))}
                  </span>
                </React.Fragment>
              );
            })}
          </Muted>
        </div>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={() => handleChangePasswordConfirm()}
          color="info"
          simple
          disabled={false}
        >
          {t("change_project.confirm")}
        </Button>
      </DialogActions>
      {changingPassword && (
        <CustomLinearProgress
          style={{
            margin: "0",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
          variant={"indeterminate"}
          color="client"
          value={null}
        />
      )}
    </Dialog>
  );
};

ChangePasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // changingPassword: PropTypes.bool.isRequired,
  // handleChangePasswordConfirm: PropTypes.func.isRequired,
  // changePasswordError: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.oneOf([null]),
  // ]).isRequired,
  userDetails: PropTypes.object.isRequired,
};

export default ChangePasswordModal;
