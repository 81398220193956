import {
  cardTitle,
  grayColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";

const documentationStyle = (theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardBody: {
    padding: "0 15px 3px 15px",
  },
  tableSpinner: {
    position: "absolute",
    marginLeft: "-5px",
    backgroundColor: whiteColor,
    width: "100%",
    minHeight: "50px",
    "& > div": {
      position: "absolute",
      top: "45%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  accordionSummary: {
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  accordionSummaryContent: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  accordionDetails: {
    padding: theme.spacing(2),
  },
  expanded: {},
  inputsCardWrapper: {
    width: "100%",
    overflow: "hidden",
    "@media (max-width: 1320px)": {
      overflow: "auto !important",
    },
  },
  subAccordion: {
    paddingLeft: "1rem",
  },
  accordionTitle: {
    color: grayColor[7],
    "&:hover,&:focus": {
      color: grayColor[6],
    },
    paddingRight: "40px",
  },
  expandIcon: {
    color: grayColor[7],
    "&:hover,&:focus": {
      color: grayColor[6],
    },
  },
  accordionContent: {
    whiteSpace: "pre-wrap",
    paddingRight: "50px",
  },
  // subtopic: {
  //   whiteSpace: "pre-wrap",
  //   wordWrap: "break-word",
  //   width: "100%",
  //   overflow: "auto",
  // },
});

export default documentationStyle;
