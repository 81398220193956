/*eslint-disable*/
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

const topRightCardButtonStyle = (clientColor) => ({
  ...buttonStyle(clientColor),
  topRightCardButton: {
    padding: "0",
    width: "35px",
    height: "25px",
    transition: "all 0.2s",
    "&:hover": {
      opacity: "0.9",
      // transform: "scale(1.5)",
      // boxShadow: "none",
    },
  },
  iconWrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
    marginTop: "-1px",
  },
  icon: {
    "& > svg": {
      height: "20px",
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      margin: "0",
    },
  },
});

export default topRightCardButtonStyle;
