export const msalConfig = (clientId) => {
  return {
    auth: {
      clientId: clientId,
      authority: "https://login.microsoftonline.com/organizations/",
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  };
};
