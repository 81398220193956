import buttonGroupStyle from "assets/jss/material-dashboard-pro-react/buttonGroupStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle";
import {
  cardTitle,
  grayColor,
  successColor,
  warningColor,
  dangerColor,
  infoColor,
  whiteColor,
  blendColors,
} from "assets/jss/material-dashboard-pro-react.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const modelingInputsStyle = (theme, clientColor) => ({
  ...customSelectStyle(theme, clientColor),
  ...customCheckboxRadioSwitch,
  ...customDropdownStyle(theme, clientColor),
  ...buttonGroupStyle,
  right: {
    textAlign: "right",
  },
  center: {
    textAlign: "center",
  },
  description: {
    maxWidth: "220px",
  },
  connections: {
    maxWidth: "200px",
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px !important",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px !important",
    },
  },
  icon: {
    verticalAlign: "middle",
    width: "17px",
    height: "17px",
    top: "-1px",
    position: "relative",
  },
  imgContainer: {
    width: "120px",
    maxHeight: "160px",
    overflow: "hidden",
    display: "block",
  },
  img: {
    width: "100%",
    height: "auto",
    verticalAlign: "middle",
    border: "0",
  },
  tdName: {
    minWidth: "200px",
    fontWeight: "400",
    fontSize: "1.5em",
  },
  tdNameAnchor: {
    color: grayColor[2],
  },
  tdNameSmall: {
    color: grayColor[0],
    fontSize: "0.75em",
    fontWeight: "300",
  },
  tdNumber: {
    textAlign: "right",
    minWidth: "145px",
    fontWeight: "300",
    fontSize: "1.3em !important",
  },
  tdNumberSmall: {
    marginRight: "3px",
  },
  tdNumberAndButtonGroup: {
    lineHeight: "1 !important",
  },
  positionAbsolute: {
    position: "absolute",
    right: "0",
    top: "0",
  },
  customFont: {
    fontSize: "16px !important",
  },
  actionButtonRound: {
    width: "auto",
    height: "auto",
    minWidth: "auto",
  },
  smoothHighlight: {
    transition: "all 0.2s ease",
  },
  highlightedInputKey: {
    color: clientColor,
    transform: "scale(2)",
    textShadow: "#999 0 0 4px",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  chipWaiting: {
    background:
      "linear-gradient(60deg, " + grayColor[5] + ", " + grayColor[4] + ")",
  },
  chipInProgress: {
    // color: whiteColor,
    background:
      "linear-gradient(60deg, " +
      successColor[5] +
      ", " +
      successColor[6] +
      ")",
  },
  chipSuccess: {
    color: whiteColor,
    background:
      "linear-gradient(60deg, " +
      successColor[1] +
      ", " +
      successColor[2] +
      ")",
  },
  chipWarning: {
    color: whiteColor,
    // backgroundColor: warningColor[1],
    background:
      "linear-gradient(60deg, " +
      warningColor[1] +
      ", " +
      warningColor[2] +
      ")",
  },
  chipError: {
    color: whiteColor,
    background:
      "linear-gradient(60deg, " + dangerColor[1] + ", " + dangerColor[2] + ")",
  },
  chipIcon: {
    color: whiteColor,
  },
  stages: {
    // fontSize: "14px",
    display: "inline-flex",
    lineHeight: "22px",
    "& svg": {
      position: "relative",
      top: "3px",
      width: "16px",
      height: "16px",
      marginRight: "5px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "3px",
      fontSize: "16px",
      marginRight: "3px",
    },
  },
  stageWaiting: {
    color: grayColor[4],
  },
  stageRunning: {
    color: grayColor[0],
  },
  stageError: {
    color: dangerColor[0],
  },
  stageIconWaiting: {
    color: grayColor[4],
    marginBottom: "1px",
  },
  stageIconRunning: {
    color: grayColor[0],
    "-webkit-animation": "$rotating 6s linear infinite",
    "-moz-animation": "$rotating 6s linear infinite",
    "-ms-animation": "$rotating 6s linear infinite",
    "-o-animation": "$rotating 6s linear infinite",
    animation: "$rotating 6s linear infinite",
    marginBottom: "1px",
  },
  "@keyframes rotating": {
    from: {
      "-ms-transform": "rotate(0deg)",
      "-moz-transform": "rotate(0deg)",
      "-webkit-transform": "rotate(0deg)",
      "-o-transform": "rotate(0deg)",
      transform: "rotate(0deg)",
    },
    to: {
      "-ms-transform": "rotate(360deg)",
      "-moz-transform": "rotate(360deg)",
      "-webkit-transform": "rotate(360deg)",
      "-o-transform": "rotate(360deg)",
      transform: "rotate(360deg)",
    },
  },
  stageIconSuccess: {
    color: successColor[0],
    marginBottom: "1px",
  },
  stageIconWarning: {
    color: warningColor[0],
    marginBottom: "1px",
  },
  stageIconError: {
    color: dangerColor[0],
    marginBottom: "1px",
  },
  fader: {
    transition: ["opacity"],
    transitionDelay: 200,
    transitionDuration: 200,
  },
  faded: {
    opacity: 0,
  },
  connectionTag: {
    ":first-of-type": {
      marginLeft: "0",
    },
    letterSpacing: "unset",
    border: "0",
    borderRadius: "26px !important",
    transition: "all .3s ease-in-out",
    padding: "0px 10px",
    margin: "2px",
    minWidth: "26px",
    height: "26px",
    minHeight: "auto",
    lineHeight: "26px",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    "&.ready": {
      backgroundColor: successColor[6],
    },
    "&:not(.ready)": {
      backgroundColor: grayColor[8],
    },
    position: "relative",
    float: "left",
    textDecoration: "none",
    boxSizing: "border-box",
    "&:hover,&:focus": {
      zIndex: "3",
      transform: "scale(1.3)",
      // "&:not(.ready)": {
      //   backgroundColor: blendColors(grayColor[8], grayColor[1], 0.9),
      // },
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  attention: {
    animation: "$attention 3s ease infinite",
  },
  "@keyframes attention": {
    "10%": {
      transform: "scale(1.1)",
    },
    "13.33%,20%": {
      transform: "rotate(-10deg) scale(1.1)",
    },
    "16.67%": {
      transform: "rotate(10deg) scale(1.1)",
    },
    "23.33%": {
      transform: "rotate(0deg) scale(1.1)",
    },
    "33%": {
      transform: "scale(1)",
    },
  },
  monthStatusWrapper: {
    justifyContent: "center",
    // marginTop: "3px",
    paddingRight: "12px",
    // [theme.breakpoints.down("sm")]: {
    //   paddingBottom: "16px",
    // },
  },
  monthStatus: {
    width: "100%",
    margin: "4px 15px 0 0",
    padding: "6px",
    justifyContent: "left",
    backgroundColor: grayColor[13],
    cursor: "help",
    borderBottom: "1px solid " + grayColor[4],
    [theme.breakpoints.down("sm")]: {
      marginLeft: "12px",
    },
  },
  notesContainer: {
    textAlign: "left",
  },
  notesTopic: {
    fontSize: "0.9em",
    textTransform: "uppercase",
    fontWeight: "500",
    marginTop: "16px",
    marginBottom: "0",
  },
  notesTopicComment: {
    fontSize: "0.8em",
    textTransform: "uppercase",
    fontWeight: "500",
    marginTop: "0",
    marginBottom: "3px",
    color: grayColor[0],
    lineHeight: "1",
  },
  notesItemContainer: {
    marginTop: "0",
    marginBottom: "16px",
  },
  modalBodyText: {
    textAlign: "left",
    paddingLeft: "2px",
    marginBottom: "0",
  },
  inputDetailsWrapper: {
    backgroundColor: grayColor[8],
    // paddingLeft: "6px",
    // paddingRight: "6px",
  },
  modalBodyMultilineText: {
    textAlign: "left",
    fontSize: "0.9em",
    height: "300px",
    overflowY: "auto",
    fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace;',
    // whiteSpace: "pre-wrap",
    "&>div": {
      whiteSpace: "pre-wrap",
    },
  },
  uploadProgressText: {
    textAlign: "center",
    color: grayColor[0],
    marginTop: "0",
    marginBottom: "1px",
  },
  cardOptions: {
    position: "absolute",
    right: "-5px",
    top: "5px",
    "& > div > :not(:last-child)": {
      marginRight: "5px",
    },
  },
  inputName: {
    fontSize: "16px",
    fontWeight: "400",
  },
  tagContainer: {
    display: "block",
    marginRight: "6px",
    marginBottom: "3px",
    fontSize: "12px",
    textTransform: "uppercase",
    color: grayColor[0],
  },
  dangerTag: {
    color: whiteColor,
    backgroundColor: dangerColor[0],
    borderRadius: "3px",
    padding: "1px 3px",
  },
  restrictedSign: {
    color: dangerColor[0],
    margin: 0,
  },
  pipelineRunningBadge: {
    color: clientColor,
    backgroundColor: grayColor[13],
    fontSize: "12px",
    lineHeight: "1",
    marginTop: "10px",
    padding: "4px 6px",
    height: "20px",
    fontStyle: "italic",
    borderRadius: "3px",
  },
  syncIcon: {
    color: grayColor[1],
    transition: "all 0.2s ease",
    "&:hover": {
      transform: "scale(1.3) rotate(180deg)",
    },
  },
  inputCheckIcon: {
    fontSize: "18px",
    color: successColor[0],
    paddingLeft: "6px",
  },
  invisible: {
    visibility: "hidden",
  },
  hidden: {
    display: "none",
  },
  ...modalStyle(theme),
  modalTitleSecondary: {
    color: grayColor[0],
  },
  displayNotesTable: {
    width: "100%",
    fontSize: "12px",
  },
  displayNotesTableHeader: {
    color: whiteColor,
    backgroundColor: grayColor[7],
  },
  displayNotesTableRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    lineHeight: "1.25",
    minHeight: "28px",
    padding: "12px 0",
    "&:not($displayNotesTableHeader)": {
      borderBottom: "1px solid " + grayColor[11],
    },
    "& > div": {
      position: "relative",
    },
  },
  displayNotesTableColCheckStage: {
    width: "14%",
    textAlign: "center",
  },
  displayNotesTableColSeverity: {
    width: "9%",
    textAlign: "center",
    "&.severity1": {
      color: infoColor[0],
    },
    "&.severity2": {
      color: warningColor[0],
    },
    "&.severity3": {
      color: dangerColor[0],
    },
  },
  displayNotesTableColDescription: {
    width: "10%",
    textAlign: "center",
  },
  displayNotesTableColComments: {
    width: "57%",
    textAlign: "left",
  },
  displayNotesTableColCopyValues: {
    width: "10%",
    textAlign: "center",
  },
  displayNotesTableIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "16px",
    height: "16px",
    cursor: "pointer",
    color: grayColor[1],
    transition: "all 0.2s",
    "&:hover": {
      color: grayColor[6],
    },
  },
  downloadInProgress: {
    backgroundColor: blendColors(whiteColor, clientColor, 0.25),
    backgroundImage:
      "linear-gradient(-45deg, rgba(255, 255, 255, 0.25) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 75%, transparent 75%, transparent)",
    backgroundSize: "30px 30px",
    animation: "$move 3s linear infinite",
    color: whiteColor,
    pointerEvents: "none",
  },
  "@keyframes move": {
    "0%": {
      backgroundPosition: "0 0",
    },
    "100%": {
      backgroundPosition: "30px 30px",
    },
  },
  inputsCardWrapper: {
    width: "100%",
    overflow: "hidden",
    "@media (max-width: 1320px)": {
      overflow: "auto !important",
    },
  },
  lowlight: {
    color: grayColor[3],
  },
  firstArrow: {
    marginTop: "10px",
  },
  arrow: {
    textAlign: "center",
    fontSize: "22px",
    color: grayColor[5],
    opacity: "0.6",
    transition: "all 0.2s",
  },
  arrowSelected: {
    color: clientColor,
    transform: "scale(2)",
    opacity: "1",
  },
  replicateFileRemark: {
    color: grayColor[0],
    fontSize: "11px",
    lineHeight: "1.25",
    textAlign: "left",
    paddingLeft: "10px",
  },
  successText: {
    color: successColor[0],
  },
  warningText: {
    color: warningColor[0],
  },
  connectionDescription: {
    "&>div": {
      paddingTop: "12px",
      paddingBottom: "12px",
      paddingLeft: "6px",
      // marginBottom: "20px",
      borderBottom: "1px solid white",
    },
  },
});

export default modelingInputsStyle;
