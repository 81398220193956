import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import cx from "classnames";
import { Close } from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Muted from "../Typography/Muted";
import Button from "../../components/CustomButtons/Button";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const styles = (theme, clientColor) => ({
  ...modalStyle(theme),
  ...customSelectStyle(theme, clientColor),
  fontCenter: {
    textAlign: "center !important",
  },
});

const ChangeProjectModal = ({
  open,
  setOpen,
  changingProject,
  projectOptions,
  handleChangeProjectSelect,
  handleChangeProjectConfirm,
  userDetails,
  currentProject,
}) => {
  const client_styles = (theme) => ({
    ...styles(theme, userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={open}
      // TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle
        id="content-modal-slide-title"
        disableTypography
        className={cx({
          [classes.modalHeader]: true,
          // [classes.fontCenter]: true,
        })}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setOpen(false)}
          disabled={changingProject}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>
          <span className={classes.modalHeader}>
            {t("sidebar_cts.profile_menu.change_project")}
          </span>
        </h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="modelling-month" className={classes.selectLabel}>
            {t("change_project.select_project")}
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            classes={{
              select: classes.select,
            }}
            value={currentProject}
            onChange={handleChangeProjectSelect}
            inputProps={{
              name: "projectSelect",
              id: "project-select",
            }}
            disabled={changingProject}
          >
            {projectOptions()}
          </Select>
        </FormControl>
        <div style={{ marginTop: "5px", marginBottom: "-5px" }}>
          <Muted>
            <small>{t("change_project.confirmation_note")}</small>
          </Muted>
        </div>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={() => handleChangeProjectConfirm()}
          color="info"
          simple
          disabled={
            userDetails &&
            (currentProject === userDetails.implementation_code ||
              changingProject)
          }
        >
          {t("change_project.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangeProjectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  changingProject: PropTypes.bool.isRequired,
  projectOptions: PropTypes.func.isRequired,
  handleChangeProjectSelect: PropTypes.func.isRequired,
  handleChangeProjectConfirm: PropTypes.func.isRequired,
  currentProject: PropTypes.string.isRequired,
  userDetails: PropTypes.object.isRequired,
};

export default ChangeProjectModal;
