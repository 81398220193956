import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../contexts/AuthProvider";
import { client } from "../../services/axiosClient";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import { Description } from "@material-ui/icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
library.add(faCheck);

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CustomAccordion from "../../components/Accordion/Accordion.js";
import { ThreeDots } from "react-loader-spinner";

import documentationStyle from "../../assets/jss/material-dashboard-pro-react/views/documentationStyle";

import { useTranslation } from "react-i18next";
import moment from "moment/moment";

const useStyles = makeStyles(documentationStyle);

export default function Documentation() {
  let { userDetails } = useContext(AuthContext);
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [documentationData, setDocumentationData] = useState([]);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.post("api/documentation/");
        const data = response.data;

        const transformedData = data
          .sort((a, b) => a.position - b.position)
          .map((topic, topicIndex) => ({
            title: `${topicIndex + 1}. ${topic.title}`,
            content: (
              <CustomAccordion
                customRootClass={classes.subAccordion}
                customTitleClass={classes.accordionTitle}
                customExpandIcon={classes.expandIcon}
                customContentClass={classes.accordionContent}
                active={topic.subtopics.length === 1 ? 0 : -1}
                collapses={topic.subtopics
                  .sort((a, b) => a.position - b.position)
                  .map((subtopic, subtopicIndex) => ({
                    title: `${topicIndex + 1}.${subtopicIndex + 1}. ${subtopic.title}`,
                    content: (
                      <div className={classes.subtopic}>{subtopic.content}</div>
                    ),
                  }))}
              />
            ),
          }));
        setDocumentationData(transformedData);
        setDataLoaded(true);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchData();
  }, [i18n.language]);

  const loadingSpinner = () => {
    return (
      <ThreeDots
        width="100px"
        height="100px"
        color={userDetails.implementation_color}
        ariaLabel="loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    );
  };

  return (
    <div>
      <GridContainer style={{ marginTop: "-40px" }}>
        <GridItem xs={12}>
          <div>
            <Card>
              <CardHeader color="client" icon>
                <CardIcon color="client">
                  <Description />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("modeling_documentation.documentation")}
                </h4>
              </CardHeader>
              <CardBody className={classes.cardBody}>
                <GridContainer justifyContent="center">
                  {dataLoaded && (
                    <GridItem
                      container
                      justifyContent="center"
                      style={{ marginLeft: "-1rem" }}
                    >
                      <CustomAccordion
                        collapses={documentationData}
                        customRootClass={classes.subAccordion}
                        customTitleClass={classes.accordionTitle}
                        customExpandIcon={classes.expandIcon}
                      />
                    </GridItem>
                  )}
                  {!dataLoaded && loadingSpinner()}
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
