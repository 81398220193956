import {
  blendColors,
  dangerColor,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";

const filterTagStyle = (clientColor) => ({
  color1: {
    backgroundColor: "#ff1b6b",
  },
  color2: {
    backgroundColor: "#4258d0",
  },
  color3: {
    backgroundColor: "#286408",
  },
  color4: {
    backgroundColor: "#ff930f",
  },
  color5: {
    backgroundColor: "#663177",
  },
  color6: {
    backgroundColor: "#a6ab00",
  },
  color7: {
    backgroundColor: "#a7b9fb",
  },
  color8: {
    backgroundColor: "#B89C93",
  },
  color9: {
    backgroundColor: "#588f4d",
  },
  color10: {
    backgroundColor: "#7F0012",
  },
  color11: {
    backgroundColor: "#0061ff",
  },
  color12: {
    backgroundColor: "#a77C00",
  },
  categoryWrapper: {
    // borderBottom: "1px " + grayColor[5] + " solid",
    display: "inline-block",
    marginRight: "10px",
    marginTop: "3px",
    marginBottom: "3px",
    // borderBottomRightRadius: "15px",
  },
  categoryTag: {
    color: grayColor[0],
    fontSize: "12px",
    marginRight: "6px",
    textTransform: "lowercase",
    "&.cursorPointer": {
      cursor: "pointer",
    },
  },
  itemTag: {
    color: whiteColor,
    fontSize: "11px",
    display: "inline-block",
    textTransform: "lowercase",
    borderRadius: "20px",
    padding: "0 8px",
    marginRight: "3px",
    marginBottom: "2px",
    "& a": {
      color: whiteColor,
      cursor: "pointer",
      marginLeft: "2px",
      transition: "opacity 150ms",
      "&:hover": {
        color: whiteColor,
        opacity: "0.5",
      },
      "&:before": {
        fontFamily: "'Font Awesome 5 Free'",
        content: "'\\f00d'",
        padding: "0 0 0 2px",
        fontWeight: "900",
      },
    },
  },
  itemTagAIcon: {
    height: "12px",
    width: "12px",
    paddingTop: "2px",
  },
  primary: {
    background:
      "linear-gradient(60deg," + primaryColor[1] + "," + primaryColor[0] + ")",
  },
  info: {
    background:
      "linear-gradient(60deg," + infoColor[1] + "," + infoColor[0] + ")",
  },
  success: {
    background:
      "linear-gradient(60deg," + successColor[1] + "," + successColor[0] + ")",
  },
  warning: {
    background:
      "linear-gradient(60deg," + warningColor[1] + "," + warningColor[0] + ")",
  },
  danger: {
    background:
      "linear-gradient(60deg," + dangerColor[1] + "," + dangerColor[0] + ")",
  },
  rose: {
    background:
      "linear-gradient(60deg," + roseColor[1] + "," + roseColor[0] + ")",
  },
  client: {
    backgroundColor: clientColor,
  },
  gray: {
    backgroundColor: blendColors(grayColor[6], clientColor, 0.5),
  },
  light: {
    backgroundColor: grayColor[3],
  },
});

export default filterTagStyle;
