import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-dashboard-pro-react/views/termsOfUseStyle";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import Button from "../../components/CustomButtons/Button";
import { useHistory } from "react-router-dom";
import AuthContext from "../../contexts/AuthProvider";

const useStyles = makeStyles(styles);

const TermsOfUse = () => {
  let { userDetails } = useContext(AuthContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const topics = [
    "about_the_platform",
    "modifications_to_terms_of_use",
    "access_and_use_of_the_platform",
    "intellectual_property",
    "limitation_of_liability",
    "privacy_and_cookie_policy",
    "violation_of_rights",
    "general_provisions",
  ];
  const backButton = (
    <Button
      color={userDetails ? "client" : "integration"}
      onClick={() => history.goBack()}
    >
      <KeyboardArrowLeft />
      {t("terms_of_use.back")}
    </Button>
  );
  return (
    <div className={classes.container}>
      {backButton}
      <h1>{t("terms_of_use.title")}</h1>
      {topics.map((topic, key) => {
        return (
          <section key={key}>
            <h2 style={{ marginBottom: "8px" }}>
              {t("terms_of_use." + topic + ".title")}
            </h2>
            <p>{t("terms_of_use." + topic + ".content")}</p>
          </section>
        );
      })}
      <footer style={{ marginTop: "20px" }}>
        <p>{t("terms_of_use.footer")}</p>
      </footer>
      {backButton}
    </div>
  );
};

export default TermsOfUse;
