import React from "react";
import styles from "../../assets/jss/material-dashboard-pro-react/components/loaderBarStyle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const LoaderBar = () => {
  const classes = useStyles();
  return (
    <div className={classes.loaderBar}>
      <div className={classes.loader}></div>
    </div>
  );
};

export default LoaderBar;
