import React from "react";
import "moment/locale/es";
import "moment/locale/pt";
import NoAccessText from "../../components/NoAccessText/NoAccessText";

export default function NoPermission() {
  return (
    <div style={{ marginTop: "60px" }}>
      <NoAccessText />
    </div>
  );
}
