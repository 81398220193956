import React from "react";
import { useEffect, useState, useContext, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import AuthContext from "../../contexts/AuthProvider";
import fileDownload from "js-file-download";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Popper from "@material-ui/core/Popper";

// material-ui icons
import {
  AccessTime,
  Alarm,
  Assignment,
  Check,
  CheckCircle,
  Close,
  CloudDownload,
  CloudUpload,
  DeleteSweep,
  ErrorOutline,
  FilterNone,
  Help,
  HighlightOff,
  HourglassEmpty,
  Info,
  Memory,
  ReportProblem,
  Search,
  Settings,
  Sync,
  TimerOff,
} from "@material-ui/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
library.add(faCheck, faChevronDown, faChevronUp);

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Table from "../../components/Table/Table.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardHeader from "../../components/Card/CardHeader.js";
import FileUpload from "../../components/CustomUpload/FileUpload";
import FloatingLoading from "../../components/FloatingItems/FloatingLoading";
import TopRightCardButton from "../../components/CustomButtons/TopRightCardButton";
import { CopyToClipboard } from "react-copy-to-clipboard";

import styles from "../../assets/jss/material-dashboard-pro-react/views/modelingInputsStyle.js";

import { Translation, useTranslation } from "react-i18next";
import {
  client,
  fileUploadClient,
  fileDownloadClient,
} from "../../services/axiosClient";
import cx from "classnames";
import moment from "moment/moment";

import { ThreeDots } from "react-loader-spinner";
import { toast, Slide as ToastSlide } from "react-toastify";
import CustomLinearProgress from "../../components/CustomLinearProgress/CustomLinearProgress";
import { store } from "../../store";
import { setProgress } from "../../reducers/fileTransferReducer";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import classNames from "classnames";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import NavPills from "../../components/NavPills/NavPills";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ModelingInputs() {
  let { userDetails } = useContext(AuthContext);
  const client_styles = (theme) => ({
    ...styles(theme, userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const controller = useRef(null);

  const notifyNoNotes = () =>
    toast(t("modeling_inputs.no_notes_notification"), {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      type: "info",
      transition: ToastSlide,
    });

  const notifyCopy = () =>
    toast(t("tasks.values_copied"), {
      position: "bottom-center",
      autoClose: 1200,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      type: "info",
      transition: ToastSlide,
    });

  const notify = (text, time = 3000, type = "info") =>
    toast(text, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      type: type,
      transition: ToastSlide,
    });

  const [modelingMonth, setModelingMonth] = React.useState("");
  const [modelingMonthsList, setModelingMonthsList] = React.useState([]);
  const [loadingMonths, setLoadingMonths] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [loadingStatusSilent, setLoadingStatusSilent] = useState(false);
  const autoRefreshTimeout = useRef(null);

  const [inputsStatus, setInputsStatus] = useState([]);
  const [monthStatus, setMonthStatus] = useState("verifying");
  const [verificationError, setVerificationError] = useState(false);

  const [resetInputsModalOpen, setResetInputsModalOpen] = useState(false);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [uploadSource, setUploadSource] = useState(null);
  const [replicatingFile, setReplicatingFile] = useState(false);
  const [inputDetailsModalOpen, setInputDetailsModalOpen] = useState(false);
  const [displayNotesModalOpen, setDisplayNotesModalOpen] = useState(false);
  const [inputToDisplayNotes, setInputToDisplayNotes] = useState({
    input_control_id: null,
    input_name: null,
  });

  const [downloadButtonPopperOpen, setDownloadButtonPopperOpen] =
    useState(null);
  const [currentDownloadButtonId, setCurrentDownloadButtonId] = useState(null);
  const [downloadButtonTooltipIdOpen, setDownloadButtonTooltipIdOpen] =
    useState(null);
  const [downloadInProgress, setDownloadInProgress] = useState(null);

  const [inputToUpload, setInputToUpload] = useState({
    input_control_id: null,
    input_name: null,
    expected_file_type: null,
    input_details: null,
    input_connections: null,
    replicate_data_from: null,
  });
  const [fileToUpload, setFileToUpload] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [fileUploadKey, setFileUploadKey] = useState(Date.now());
  const activeInputDetailTab = useRef(0);

  const [highlightedInput, setHighLightedInput] = useState(null);

  const handleModelingMonthSelect = (event) => {
    controller.current.abort();
    clearTimeout(autoRefreshTimeout.current);
    setModelingMonth(event.target.value);
  };

  const month_to_string = (month) => {
    return (
      t("months." + month[0].toString()) + "-" + month[1].toString().slice(-2)
    );
  };

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  const modelingMonthsOptions = () => {
    if (modelingMonth !== "") {
      return modelingMonthsList.map((month, key) => (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={key}
          key={key}
        >
          {month_to_string(month)}
        </MenuItem>
      ));
    } else {
      return null;
    }
  };

  useEffect(() => {
    i18n.changeLanguage(i18n.language.slice(0, 2));
    client
      .post("api/inputs_months/")
      .then((response) => {
        setModelingMonthsList(response.data.inputs_months);
        setModelingMonth(response.data.inputs_months.length - 1);
        setLoadingMonths(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingMonths(false);
      });
  }, []);

  const refreshInputStatus = (silent = false) => {
    if (!silent) {
      setLoadingStatus(true);
      setInputsStatus([]);
      setMonthStatus("verifying");
    } else {
      setLoadingStatusSilent(true);
    }
    let params = {
      month: modelingMonthsList[modelingMonth][0],
      year: modelingMonthsList[modelingMonth][1],
    };
    controller.current = new AbortController();
    client
      .post("api/inputs_status/", params, { signal: controller.current.signal })
      .then((response) => {
        setMonthStatus(response.data.month_status);
        setVerificationError(response.data.verification_error);
        setInputsStatus(response.data.inputs_status || []);
        if (
          isPipelineRunning(
            response.data.inputs_status || [],
            response.data.month_status,
          )
        ) {
          // if (!silent) notify(t("modeling_inputs.input_check_running"), 5000);
          autoRefreshTimeout.current = setTimeout(() => {
            refreshInputStatus(true);
          }, 3000);
        } else {
          clearTimeout(autoRefreshTimeout.current);
        }
        if (!silent) {
          setLoadingStatus(false);
        } else {
          setLoadingStatusSilent(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!silent) {
          setMonthStatus("unknown");
          setLoadingStatus(false);
        } else {
          setLoadingStatusSilent(false);
        }
        clearTimeout(autoRefreshTimeout.current);
      });
  };

  useEffect(() => {
    if (modelingMonth !== "") {
      refreshInputStatus();
    }
  }, [modelingMonth]);

  const getMonthProgressIcon = (progress) => {
    switch (progress) {
      case "verifying":
        return <HourglassEmpty />;
      case "unknown":
        return <Help className={classes.chipIcon} />;
      case "waiting":
        return <Alarm />;
      case "running":
        return <Memory />;
      case "complete":
        return <CheckCircle className={classes.chipIcon} />;
      case "error":
        return <ErrorOutline className={classes.chipIcon} />;
      case "system_error":
        return <TimerOff className={classes.chipIcon} />;
    }
  };

  const isInputFulfilled = (pos) => {
    return ["success", "warning"].includes(
      inputsStatus[pos - 1]?.stages.data_structure,
    );
  };
  const isPipelineRunning = (newInputStatus, newMonthStatus) => {
    let response = false;
    let ms = newMonthStatus || monthStatus;
    let is = newInputStatus || inputsStatus;
    if (ms === "running") return true;
    is.forEach((input) => {
      if (
        input.stages.data_structure === "running" ||
        input.stages.cross_linking === "running"
      )
        response = true;
    });
    return response;
  };
  const isPipelineRunningMemo = useMemo(
    () => isPipelineRunning(),
    [monthStatus, inputsStatus],
  );

  const handleTableUploadFileButton = (input) => {
    if (isPipelineRunningMemo) {
      notify(t("modeling_inputs.wait_pipeline"));
    } else {
      let input_connections = input.connections.map((conn, key) => {
        return (
          <div key={key}>
            <span style={{ fontSize: "14px" }}>
              <strong>
                {conn[0]}
                {" - "}
                {inputsStatus[conn[0] - 1].input_name[i18n.language]}
              </strong>
            </span>
            <br />
            <span>{conn[1]}</span>
          </div>
        );
      });
      setInputToUpload({
        input_control_id: input.input_control_id,
        input_name: input.input_name[i18n.language],
        expected_file_type: "." + input.expected_file_type,
        input_details: input.input_details,
        input_connections: input_connections,
        replicate_data_from: input.available_data_from,
      });
      setFileToUpload(null);
      setUploadSource(null);
      setFileUploadKey(Date.now()); // resets the input
      store.dispatch(setProgress(0));
      setUploadFileModalOpen(true);
    }
  };
  const handleDownloadLastUploadedFileButton = (event, id) => {
    setDownloadButtonTooltipIdOpen(null);
    if (
      downloadButtonPopperOpen &&
      downloadButtonPopperOpen.contains(event.target)
    ) {
      setCurrentDownloadButtonId(null);
      setDownloadButtonPopperOpen(null);
    } else {
      setCurrentDownloadButtonId(id);
      setDownloadButtonPopperOpen(event.currentTarget);
    }
  };
  const handleChooseFileToUpload = (event) => {
    setFileToUpload(event.target.files[0]);
    setUploadSource("new_file");
  };
  const handleUploadFileButton = () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", fileToUpload);
    formData.append("input_control_id", inputToUpload.input_control_id);
    client.post("api/awake/").then(() => {
      fileUploadClient
        .post("api/inputs_upload/", formData)
        .then((response) => {
          if (response.data.success) {
            setUploading(false);
            setUploadFileModalOpen(false);
            notify(t("modeling_inputs.upload_completed"), 3000);
            refreshInputStatus();
          } else {
            setUploading(false);
            store.dispatch(setProgress(0));
            notify(
              t("modeling_inputs.errors." + response.data.error_code),
              5000,
              "error",
            );
          }
        })
        .catch((error) => {
          setUploading(false);
          store.dispatch(setProgress(0));
          console.log(error);
        });
    });
  };
  const handleReplicateFileButton = () => {
    setUploadSource("replication");
    setUploading(true);
    setReplicatingFile(true);
    let params = {
      input_control_id: inputToUpload.input_control_id,
    };
    client
      .post("api/inputs_replicate_file/", params)
      .then((response) => {
        if (response.data.success) {
          setUploading(false);
          setReplicatingFile(false);
          setUploadFileModalOpen(false);
          notify(t("modeling_inputs.upload_completed"), 3000);
          refreshInputStatus();
        } else {
          setUploading(false);
          setReplicatingFile(false);
          notify(
            t("modeling_inputs.errors." + response.data.error_code),
            5000,
            "error",
          );
        }
      })
      .catch((error) => {
        setUploading(false);
        setReplicatingFile(false);
        console.log(error);
      });
  };
  const handleDownloadFileSelection = (input_control_id, option) => {
    if (downloadInProgress !== null) return false;
    const params = { input_control_id, option };
    setDownloadInProgress(option);
    client.post("api/awake/").then(() => {
      fileDownloadClient
        .post("api/inputs_download/", params)
        .then((response) => {
          fileDownload(
            response.data,
            response.headers["content-disposition"].split("filename=")[1],
          );
          setDownloadButtonPopperOpen(null);
          setDownloadInProgress(null);
        })
        .catch((error) => {
          console.log(error);
          setDownloadButtonPopperOpen(null);
          setDownloadInProgress(null);
        });
    });
  };
  const handleClickAwayDownloadFileSelection = (event) => {
    if (downloadInProgress !== null) return false;
    if (typeof event.target.className === "string") {
      if (event.target.className.includes("dropdownItem")) return false;
    }
    setDownloadButtonPopperOpen(null);
  };

  const uploadButton = (input) => {
    return {
      color: "success",
      icon: CloudUpload,
      tooltip: "modeling_inputs.actions.upload_new_file",
      disabled: input.restricted && userDetails.restricted_data_privilege,
      attention:
        input.source === "file_upload" &&
        (input.stages.data_source === "waiting" ||
          (input.notes.length > 0 && input.notes[0].severity === 3)) &&
        !(input.restricted && userDetails.restricted_data_privilege),
      onClick: () => handleTableUploadFileButton(input),
    };
  };
  const downloadLastUploadedFile = (input) => {
    return {
      color:
        input.stages.data_source === "waiting" ||
        (input.stages.data_source === "system_error" &&
          input.source === "system_integration")
          ? "gray"
          : "info",
      icon: CloudDownload,
      tooltip: "modeling_inputs.actions.download_last_uploaded_file",
      disabled:
        (input.restricted && userDetails.restricted_data_privilege) ||
        input.stages.data_source === "waiting",
      onClick: (e) =>
        handleDownloadLastUploadedFileButton(e, input.input_control_id),
      aria_owns: downloadButtonPopperOpen ? "download-file-menu-list" : null,
      aria_haspopup: "true",
      popper: downloadButtonPopper(
        input.input_control_id,
        ["success", "warning"].includes(input.stages.data_structure),
      ),
      id: input.input_control_id,
    };
  };
  const lastUpdateNotes = (input) => {
    return {
      color:
        input.stages.data_structure === "waiting" ||
        input.stages.data_structure === "running" ||
        input.stages.data_structure === "system_error"
          ? "gray"
          : input.notes.length > 0 && input.notes[0].severity === 3
            ? "danger"
            : input.notes.length > 0 && input.notes[0].severity === 2
              ? "warning"
              : "info",
      icon: Info,
      tooltip: "modeling_inputs.actions.last_update_notes",
      disabled:
        (input.restricted && userDetails.restricted_data_privilege) ||
        input.stages.data_structure === "waiting" ||
        input.stages.data_structure === "running" ||
        input.stages.data_structure === "system_error",
      attention:
        input.notes.length > 0 &&
        input.notes[0].severity >= 2 &&
        input.stages.data_structure !== "running" &&
        !(input.restricted && userDetails.restricted_data_privilege),
      onClick: () => handleDisplayNotesClick(input),
    };
  };
  const inputTableActionButtons = (input) => {
    let buttons = [];
    if (["file_upload"].includes(input.source)) {
      buttons.push(uploadButton(input));
      buttons.push(downloadLastUploadedFile(input));
    }
    buttons.push(lastUpdateNotes(input));
    return buttons.map((prop, key) => {
      const btn = (
        <Button
          color={prop.color}
          className={cx({
            [classes.actionButton]: true,
            [classes.attention]: prop.attention,
          })}
          onClick={prop.onClick}
          disabled={prop.disabled}
          aria-owns={prop.aria_owns}
          aria-haspopup={prop.aria_haspopup}
        >
          <prop.icon className={classes.icon} />
        </Button>
      );
      if (prop.popper) {
        return (
          <React.Fragment key={key}>
            <Tooltip
              key={key}
              open={downloadButtonTooltipIdOpen === prop.id}
              title={t(prop.tooltip)}
              onOpen={() => setDownloadButtonTooltipIdOpen(prop.id)}
              onClose={() => setDownloadButtonTooltipIdOpen(null)}
            >
              {btn}
            </Tooltip>
            {prop.popper}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={key}>
            <Tooltip title={t(prop.tooltip)} key={key}>
              {btn}
            </Tooltip>
          </React.Fragment>
        );
      }
    });
  };
  const downloadButtonPopper = (id, cleaned_up_file) => {
    return (
      <Popper
        open={Boolean(downloadButtonPopperOpen) || downloadInProgress !== null}
        anchorEl={downloadButtonPopperOpen}
        transition
        disablePortal
        placement="bottom"
        className={classNames({
          [classes.popperClose]:
            !downloadButtonPopperOpen || id !== currentDownloadButtonId,
          [classes.popperResponsive]: true,
        })}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="download-file-menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener
                onClickAway={handleClickAwayDownloadFileSelection}
              >
                <MenuList
                  role="menu"
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <MenuItem
                    onClick={() => handleDownloadFileSelection(id, "original")}
                    className={cx({
                      [classes.dropdownItem]: true,
                      [classes.clientHover]: downloadInProgress === null,
                      [classes.downloadInProgress]:
                        downloadInProgress === "original",
                    })}
                    // disabled={downloadInProgress !== null}
                  >
                    {t("modeling_inputs.download_original")}
                  </MenuItem>
                  {cleaned_up_file && (
                    <MenuItem
                      onClick={() =>
                        handleDownloadFileSelection(id, "cleaned_up")
                      }
                      className={cx({
                        [classes.dropdownItem]: true,
                        [classes.clientHover]: downloadInProgress === null,
                        [classes.downloadInProgress]:
                          downloadInProgress === "cleaned_up",
                      })}
                      // disabled={downloadInProgress !== null}
                    >
                      {t("modeling_inputs.download_cleaned_up")}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };
  const inputsStatusMemo = useMemo(() => inputsStatus, [inputsStatus]);
  const inputsTable = inputsStatusMemo.map((input, key) => {
    return [
      // input index
      <div
        key={key}
        className={cx({
          [classes.smoothHighlight]: true,
          [classes.highlightedInputKey]: highlightedInput === key + 1,
        })}
      >
        {key + 1}
      </div>,
      // input name
      <React.Fragment key={key}>
        <span className={classes.inputName}>
          {input.input_name[i18n.language]}
        </span>
        {["success", "warning"].includes(input.stages.cross_linking) && (
          <span className={classes.inputCheckIcon}>
            <FontAwesomeIcon icon="check" />
          </span>
        )}
        <div style={{ display: "block", lineHeight: "1.4", marginTop: "5px" }}>
          <div className={classes.tagContainer}>
            <span>
              {"• "}
              {t("modeling_inputs.source." + input.source)}
            </span>
          </div>
          <div className={classes.tagContainer}>
            <span>
              {"• "}
              {t("modeling_inputs.frequency." + input.frequency)}
              {input.available_data_from && (
                <>
                  {" - "}
                  <span className={classes.successText}>
                    <strong>
                      {t("modeling_inputs.replication_of")}{" "}
                      {t("months." + input.available_data_from[0])}
                      {"/"}
                      {input.available_data_from[1] % 100}{" "}
                      {t("modeling_inputs.available")}
                    </strong>
                  </span>
                </>
              )}
            </span>
          </div>
          {input.restricted && (
            <div className={classes.tagContainer}>
              <span className={classes.dangerTag}>
                {t("modeling_inputs.restricted")}
              </span>
            </div>
          )}
        </div>
      </React.Fragment>,
      // connections
      <GridContainer justifyContent="center" key={key}>
        {input.connections.map((conn, key) => {
          return (
            // <Tooltip key={key} title={conn[1]}>
            //   <div
            //     className={cx({
            //       [classes.connectionTag]: true,
            //       ["ready"]: isInputFulfilled(conn[0]),
            //     })}
            //     onMouseOver={() => setHighLightedInput(parseInt(conn[0]))}
            //     onMouseLeave={() => setHighLightedInput(null)}
            //   >
            //     {conn[0]}
            //   </div>
            // </Tooltip>
            <div
              key={key}
              className={cx({
                [classes.connectionTag]: true,
                ["ready"]: isInputFulfilled(conn[0]),
              })}
              onMouseOver={() => setHighLightedInput(parseInt(conn[0]))}
              onMouseLeave={() => setHighLightedInput(null)}
            >
              {conn[0]}
            </div>
          );
        })}
      </GridContainer>,
      // stages
      <GridContainer direction="column" key={key}>
        <GridItem container justifyContent="center">
          <div className={classes.stages}>
            {input.stages.data_source === "waiting" && (
              <AccessTime className={classes.stageIconWaiting} />
            )}
            {input.stages.data_source === "success" && (
              <Check className={classes.stageIconSuccess} />
            )}
            {input.stages.data_source === "error" && (
              <HighlightOff className={classes.stageIconError} />
            )}
            {input.stages.data_source === "system_error" && (
              <ReportProblem className={classes.stageIconError} />
            )}
            <div
              className={cx({
                [classes.stageWaiting]: input.stages.data_source === "waiting",
                [classes.stageError]:
                  input.stages.data_source === "error" ||
                  input.stages.data_source === "system_error",
              })}
            >
              {/* The text in the line below is different according to the source of the data */}
              {t("modeling_inputs.stages.data_source." + input.source)}
            </div>
          </div>
        </GridItem>
        <GridItem style={{ marginTop: 2 }} container justifyContent="center">
          <div className={classes.stages}>
            {input.stages.data_structure === "waiting" && (
              <AccessTime className={classes.stageIconWaiting} />
            )}
            {input.stages.data_structure === "running" && (
              <Settings className={classes.stageIconRunning} />
            )}
            {input.stages.data_structure === "success" && (
              <Check className={classes.stageIconSuccess} />
            )}
            {input.stages.data_structure === "warning" && (
              <ErrorOutline className={classes.stageIconWarning} />
            )}
            {input.stages.data_structure === "error" && (
              <HighlightOff className={classes.stageIconError} />
            )}
            {input.stages.data_structure === "system_error" && (
              <ReportProblem className={classes.stageIconError} />
            )}
            <div
              className={cx({
                [classes.stageWaiting]:
                  input.stages.data_structure === "waiting",
                [classes.stageRunning]:
                  input.stages.data_structure === "running",
                [classes.stageError]:
                  input.stages.data_structure === "error" ||
                  input.stages.data_structure === "system_error",
              })}
            >
              {t("modeling_inputs.stages.data_structure")}
            </div>
          </div>
        </GridItem>
        <GridItem style={{ marginTop: 2 }} container justifyContent="center">
          <div className={classes.stages}>
            {input.stages.cross_linking === "waiting" && (
              <AccessTime className={classes.stageIconWaiting} />
            )}
            {input.stages.cross_linking === "running" && (
              <Settings className={classes.stageIconRunning} />
            )}
            {input.stages.cross_linking === "success" && (
              <Check className={classes.stageIconSuccess} />
            )}
            {input.stages.cross_linking === "warning" && (
              <ErrorOutline className={classes.stageIconWarning} />
            )}
            {input.stages.cross_linking === "error" && (
              <HighlightOff className={classes.stageIconError} />
            )}
            {input.stages.cross_linking === "system_error" && (
              <ReportProblem className={classes.stageIconError} />
            )}
            <div
              className={cx({
                [classes.stageWaiting]:
                  input.stages.cross_linking === "waiting",
                [classes.stageRunning]:
                  input.stages.cross_linking === "running",
                [classes.stageError]:
                  input.stages.cross_linking === "error" ||
                  input.stages.cross_linking === "system_error",
              })}
            >
              {t("modeling_inputs.stages.data_cross_linking")}
            </div>
          </div>
        </GridItem>
      </GridContainer>,
      // sender + time + data from
      <React.Fragment key={key}>
        {input.uploaded_by_username && (
          <React.Fragment>
            {input.uploaded_by_username}
            <br />
            <span className={classes.lowlight}>
              {t("modeling_inputs.inputs_table.in")}
            </span>{" "}
            {moment.utc(input.uploaded_in, "YYYY-MM-DD").format("l")}
            <br />
            <span className={classes.lowlight}>
              {t("modeling_inputs.inputs_table.with")}
            </span>{" "}
            {input.data_from &&
            input.data_from[0] === modelingMonthsList[modelingMonth][0] &&
            input.data_from[1] === modelingMonthsList[modelingMonth][1]
              ? t("modeling_inputs.inputs_table.new_data")
              : t("modeling_inputs.inputs_table.data_from") +
                " " +
                input.data_from[0] +
                "/" +
                input.data_from[1]}
          </React.Fragment>
        )}
        {!input.uploaded_by_username && (
          <span>{t("modeling_inputs.inputs_table.data_not_sent")}</span>
        )}
      </React.Fragment>,
      // action buttons
      inputTableActionButtons(input),
    ];
  });

  const handleDisplayNotesClick = (input) => {
    // setDisplayNotesCurrentId(input_control_id);
    setInputToDisplayNotes({
      input_control_id: input.input_control_id,
      input_name: input.input_name[i18n.language],
    });
    if (getInputNotes(input.input_control_id).length === 0) {
      notifyNoNotes();
    } else {
      setDisplayNotesModalOpen(true);
    }
  };
  const getInputNotes = (input_control_id = null) => {
    let id = input_control_id || inputToDisplayNotes.input_control_id;
    for (let i = 0; i < inputsStatus.length; i++) {
      if (inputsStatus[i].input_control_id === id) {
        return inputsStatus[i].notes;
      }
    }
    return [];
  };

  const fileUploadProgress = useSelector(
    () => store.getState().file_transfer.progress,
  );
  const uploadingText =
    uploadSource === "new_file"
      ? fileUploadProgress < 100
        ? t("modeling_inputs.modals.file_upload.uploading")
        : t("modeling_inputs.modals.file_upload.please_wait")
      : t("modeling_inputs.modals.file_upload.replicating");

  const handleViewInputDetails = () => {
    setUploadFileModalOpen(false);
    setInputDetailsModalOpen(true);
  };
  const handleCloseInputDetails = () => {
    setInputDetailsModalOpen(false);
    setUploadFileModalOpen(true);
  };

  const handleOpenResetInputModal = () => {
    setResetInputsModalOpen(true);
  };
  const handleCloseResetInputsModal = () => {
    setResetInputsModalOpen(false);
  };
  const handleConfirmResetInput = () => {
    setLoadingMonths(true);
    let params = {
      month: modelingMonthsList[modelingMonth][0],
      year: modelingMonthsList[modelingMonth][1],
    };
    client
      .post("api/inputs_reset_month_inputs/", params)
      .then(() => {
        setLoadingMonths(false);
        setResetInputsModalOpen(false);
        refreshInputStatus();
      })
      .catch((error) => {
        console.log(error);
        setLoadingMonths(false);
      });
  };

  const handleCloseDisplayNotesModal = () => {
    setDisplayNotesModalOpen(false);
  };

  const fileUploadDialog = () => {
    return (
      <Dialog
        classes={{
          // root: classes.center + " " + classes.modalRoot,
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={uploadFileModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setUploadFileModalOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setUploadFileModalOpen(false)}
            disabled={uploading}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{inputToUpload.input_name}</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h5 className={classes.modalBodyText}>
            {t(
              "modeling_inputs.modals.file_upload.choose_file_with_extension",
            ) + " "}
            <strong>{inputToUpload.expected_file_type}</strong>
            {":"}
          </h5>
          <FileUpload
            formControlProps={{
              fullWidth: true,
            }}
            inputKey={fileUploadKey}
            accept={inputToUpload.expected_file_type}
            onChange={handleChooseFileToUpload}
            disabled={uploading}
          />
          {inputToUpload.replicate_data_from && (
            <>
              <div
                className={cx({
                  [classes.arrow]: true,
                  [classes.firstArrow]: true,
                  [classes.arrowSelected]: uploadSource === "new_file",
                })}
              >
                <FontAwesomeIcon icon="chevron-up" />
              </div>
              <div>
                <div className={classes.orDivider}>
                  {t("modeling_inputs.upload_modal.or")}
                </div>
              </div>
              <div
                className={cx({
                  [classes.arrow]: true,
                  [classes.arrowSelected]: uploadSource === "replication",
                })}
              >
                <FontAwesomeIcon icon="chevron-down" />
              </div>
              <div style={{ marginTop: "16px" }}>
                <Button
                  onClick={handleReplicateFileButton}
                  color={"system_gray"}
                  disabled={uploading}
                >
                  {t("modeling_inputs.upload_modal.replicate_file_from")}{" "}
                  {String(inputToUpload.replicate_data_from[0]).padStart(
                    2,
                    "0",
                  )}
                  {"/"}
                  {inputToUpload.replicate_data_from[1]}
                </Button>
              </div>
              <ul className={classes.replicateFileRemark}>
                <li>{t("modeling_inputs.upload_modal.remark1")}</li>
                <li>{t("modeling_inputs.upload_modal.remark2")}</li>
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions
          className={classes.modalFooter}
          style={{ paddingBottom: "0" }}
        >
          <GridContainer>
            <GridItem container justifyContent={"flex-start"} xs={4}>
              <Button
                onClick={handleViewInputDetails}
                color={"client"}
                simple
                disabled={uploading}
              >
                <Translation>
                  {(t) => t("modeling_inputs.modals.file_upload.input_details")}
                </Translation>
              </Button>
            </GridItem>
            <GridItem container justifyContent={"flex-end"} xs={8}>
              <Button
                onClick={handleUploadFileButton}
                color="transparent"
                disabled={uploading || !fileToUpload}
              >
                <Translation>
                  {(t) => t("modeling_inputs.modals.file_upload.upload")}
                </Translation>
              </Button>
              <Button
                onClick={() => setUploadFileModalOpen(false)}
                color="danger"
                simple
                disabled={uploading}
              >
                <Translation>
                  {(t) => t("modeling_inputs.modals.file_upload.close")}
                </Translation>
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
        <h6
          className={cx({
            [classes.uploadProgressText]: true,
            [classes.invisible]: !uploading,
          })}
        >
          {uploadingText}
        </h6>
        <CustomLinearProgress
          style={{ margin: "0" }}
          variant={replicatingFile ? "indeterminate" : "determinate"}
          color="client"
          value={fileUploadProgress}
        />
      </Dialog>
    );
  };
  const inputDetailsDialog = () => {
    return (
      <Dialog
        classes={{
          // root: classes.center + " " + classes.modalRoot,
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={inputDetailsModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseInputDetails}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={handleCloseInputDetails}
            disabled={uploading}
          >
            <Close className={classes.modalClose} />
          </Button>
          <div
            style={{
              marginTop: "-8px",
              paddingTop: "4px",
              paddingBottom: "8px",
            }}
          >
            <h4 className={classes.modalTitle}>{inputToUpload.input_name}</h4>
            <h6 className={classes.modalTitle}>
              <Translation>
                {(t) => t("modeling_inputs.modals.file_upload.input_details")}
              </Translation>
            </h6>
          </div>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ paddingTop: "4px" }}
        >
          <NavPills
            color="client"
            active={activeInputDetailTab.current}
            alignCenter
            tabs={[
              {
                tabButton: t("modeling_inputs.stages.data_structure"),
                tabContent: (
                  <div className={classes.inputDetailsWrapper}>
                    <div className={classes.modalBodyMultilineText}>
                      <div style={{ padding: "6px" }}>
                        {inputToUpload.input_details}
                        {/*<pre>{inputToUpload.input_details}</pre>*/}
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                tabButton: t("modeling_inputs.inputs_table.connections"),
                tabContent: (
                  <div className={classes.inputDetailsWrapper}>
                    <div className={classes.modalBodyMultilineText}>
                      <div className={classes.connectionDescription}>
                        {inputToUpload.input_connections}
                      </div>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleCloseInputDetails} color="danger" simple>
            <Translation>
              {(t) => t("modeling_inputs.modals.file_upload.back")}
            </Translation>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const resetInputsDialog = () => {
    return (
      <Dialog
        classes={{
          // root: classes.center + " " + classes.modalRoot,
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={resetInputsModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseResetInputsModal}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={handleCloseResetInputsModal}
            disabled={uploading}
          >
            <Close className={classes.modalClose} />
          </Button>
          {/*<h4 className={classes.modalTitle}>{inputToUpload.input_name}</h4>*/}
          <h4 className={classes.modalTitle}>
            {t("modeling_inputs.restart_month")}
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <div style={{ marginBottom: "16px" }}>
            {t("modeling_inputs.modals.restart_inputs.notice")}
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={handleCloseResetInputsModal}
            color="info"
            simple
            disabled={loadingMonths}
          >
            {t("modeling_inputs.modals.restart_inputs.cancel")}
          </Button>
          <Button
            onClick={handleConfirmResetInput}
            color="danger"
            simple
            disabled={loadingMonths}
          >
            {t("modeling_inputs.modals.restart_inputs.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const displayNotesDialog = () => {
    return (
      <Dialog
        classes={{
          // root: classes.center + " " + classes.modalRoot,
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={displayNotesModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDisplayNotesModalOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={handleCloseDisplayNotesModal}
            disabled={uploading}
          >
            <Close className={classes.modalClose} />
          </Button>
          <div
            style={{
              marginTop: "-8px",
              paddingTop: "4px",
              paddingBottom: "8px",
            }}
          >
            <h4 className={classes.modalTitle}>
              {inputToDisplayNotes.input_name}
            </h4>
            <h6 className={classes.modalTitle}>
              {t("modeling_inputs.modals.display_notes.input_notes")}{" "}
              <span className={classes.modalTitleSecondary}>
                ({t("modeling_inputs.modals.display_notes.system_generated")})
              </span>
            </h6>
          </div>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ paddingLeft: "8px", paddingRight: "8px" }}
        >
          <div className={classes.displayNotesTable}>
            <div
              className={cx({
                [classes.displayNotesTableHeader]: true,
                [classes.displayNotesTableRow]: true,
              })}
            >
              <div className={classes.displayNotesTableColCheckStage}>
                {t("modeling_inputs.modals.display_notes.check_stage")}
              </div>
              <div className={classes.displayNotesTableColSeverity}>
                {t("modeling_inputs.modals.display_notes.severity")}
              </div>
              <div className={classes.displayNotesTableColDescription}>
                {t("modeling_inputs.modals.display_notes.description")}
              </div>
              <div className={classes.displayNotesTableColComments}>
                {t("modeling_inputs.modals.display_notes.comments")}
              </div>
              <div className={classes.displayNotesTableColCopyValues}>
                {t("modeling_inputs.modals.display_notes.copy_values")}
              </div>
            </div>
            <div className={classes.displayNotesTableContent}>
              {getInputNotes().map((note, key) => {
                return (
                  <div key={key} className={classes.displayNotesTableRow}>
                    <div className={classes.displayNotesTableColCheckStage}>
                      {t("modeling_inputs.stages." + note.type)}
                    </div>
                    <div
                      className={cx({
                        [classes.displayNotesTableColSeverity]: true,
                        ["severity" + note.severity]: true,
                      })}
                    >
                      {t(
                        "modeling_inputs.modals.display_notes.severity" +
                          note.severity,
                      )}
                    </div>
                    <div className={classes.displayNotesTableColDescription}>
                      <Tooltip title={note.description[i18n.language]}>
                        <Search className={classes.displayNotesTableIcon} />
                      </Tooltip>
                    </div>
                    <div className={classes.displayNotesTableColComments}>
                      {note.notes[i18n.language]}
                    </div>
                    <div className={classes.displayNotesTableColCopyValues}>
                      {note.values && (
                        <CopyToClipboard text={note.values} onCopy={notifyCopy}>
                          <Tooltip
                            title={t(
                              "modeling_inputs.modals.display_notes.copy_related_values",
                            )}
                          >
                            <FilterNone
                              className={classes.displayNotesTableIcon}
                            />
                          </Tooltip>
                        </CopyToClipboard>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={handleCloseDisplayNotesModal}
            color="danger"
            simple
            disabled={loadingMonths}
          >
            {t("modeling_inputs.modals.display_notes.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      <FloatingLoading visible={isPipelineRunningMemo} />
      <GridContainer style={{ marginTop: "-40px" }}>
        <GridItem xs={12}>
          <div className={classes.inputsCardWrapper}>
            <Card style={{ minWidth: "1200px" }}>
              <CardHeader color="client" icon>
                <CardIcon color="client">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("modeling_inputs.inputs_list")}
                </h4>
                <div className={classes.cardOptions}>
                  <div style={{ display: "flex" }}>
                    {isPipelineRunningMemo && (
                      <div className={classes.pipelineRunningBadge}>
                        {t("modeling_inputs.pipeline_in_progress")}
                      </div>
                    )}
                    {userDetails.allowed_to_reset_inputs && (
                      <TopRightCardButton
                        color="warning"
                        className={classes.cardButton}
                        icon={<DeleteSweep />}
                        tooltip={t("modeling_inputs.restart_month")}
                        onClick={() => handleOpenResetInputModal()}
                        disabled={
                          loadingMonths ||
                          loadingStatus ||
                          loadingStatusSilent ||
                          isPipelineRunningMemo
                        }
                      />
                    )}
                    <TopRightCardButton
                      color="client"
                      className={classes.cardButton}
                      icon={<Sync />}
                      tooltip={t("modeling_inputs.refresh")}
                      onClick={() => refreshInputStatus(true)}
                      disabled={
                        loadingMonths || loadingStatus || loadingStatusSilent
                      }
                    />
                  </div>
                </div>
              </CardHeader>
              <CardBody style={{ padding: "0 0 3px 0" }}>
                <GridContainer
                  style={{ paddingLeft: "15px" }}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <GridItem xs={3}>
                    {/* Month selection */}
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="modelling-month"
                        className={classes.selectLabel}
                      >
                        {t("modeling_inputs.month")}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={modelingMonth}
                        onChange={handleModelingMonthSelect}
                        inputProps={{
                          name: "modelingMonth",
                          id: "modeling-month",
                        }}
                        disabled={loadingMonths || loadingStatus}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                          className={cx({
                            [classes.hidden]: !loadingMonths,
                          })}
                          key={-1}
                        >
                          {loadingMonths
                            ? t("loading")
                            : t("modeling_inputs.month")}
                        </MenuItem>
                        {modelingMonthsOptions()}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={9} style={{ marginTop: "2px" }}>
                    {/* Month modeling progress */}
                    <div className={classes.monthStatusWrapper}>
                      <div className={classes.monthStatus}>
                        <GridContainer>
                          <GridItem>
                            <Translation>
                              {(t) =>
                                t(
                                  "modeling_inputs.month_status.month_calculation_progress",
                                )
                              }
                            </Translation>
                            <Tooltip
                              title={t(
                                "modeling_inputs.month_status.tooltip." +
                                  monthStatus,
                              )}
                            >
                              <Chip
                                style={{ marginLeft: "8px" }}
                                className={cx({
                                  [classes.chipWarning]:
                                    monthStatus === "unknown",
                                  [classes.chipWaiting]:
                                    monthStatus === "verifying" ||
                                    monthStatus === "waiting",
                                  [classes.chipInProgress]:
                                    monthStatus === "running",
                                  [classes.chipError]:
                                    monthStatus === "system_error" ||
                                    monthStatus === "error",
                                  [classes.chipSuccess]:
                                    monthStatus === "complete",
                                })}
                                size="small"
                                icon={getMonthProgressIcon(monthStatus)}
                                label={t(
                                  "modeling_inputs.month_status." + monthStatus,
                                )}
                              />
                            </Tooltip>
                          </GridItem>
                          <GridItem xs={true}></GridItem>
                          <GridItem>
                            <ThreeDots
                              visible={
                                !loadingMonths &&
                                (loadingStatus || loadingStatusSilent)
                              }
                              color={userDetails.implementation_color}
                              ariaLabel="loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              height={"22px"}
                              width={"26px"}
                            />
                          </GridItem>
                        </GridContainer>
                        <div></div>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
                <div style={{ padding: "0 12px" }}>
                  {(verificationError || monthStatus === "system_error") && (
                    <SnackbarContent
                      message={t(
                        "modeling_inputs.month_status.system_error_warning",
                      )}
                      color="danger"
                    />
                  )}
                  {monthStatus === "error" && (
                    <SnackbarContent
                      message={t("modeling_inputs.month_status.error_warning")}
                      color="danger"
                    />
                  )}
                </div>
                <GridContainer justifyContent="center">
                  <ThreeDots
                    visible={loadingMonths || loadingStatus}
                    height="80"
                    width="80"
                    color={userDetails.implementation_color}
                    radius="9"
                    ariaLabel="loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </GridContainer>
                <div
                  className={cx({
                    [classes.hidden]: loadingMonths || loadingStatus,
                  })}
                >
                  <Table
                    tableHead={[
                      "#",
                      t("modeling_inputs.inputs_table.input"),
                      // t("modeling_inputs.inputs_table.source"),
                      // "Freq. atual.",
                      t("modeling_inputs.inputs_table.connections"),
                      t("modeling_inputs.inputs_table.stages"),
                      t("modeling_inputs.inputs_table.updated_by"),
                      t("modeling_inputs.inputs_table.actions"),
                    ]}
                    tableData={inputsTable}
                    customCellClasses={[
                      classes.center,
                      classes.left + " " + classes.description,
                      // classes.center,
                      classes.center + " " + classes.connections,
                      classes.center,
                      classes.center,
                      classes.center,
                    ]}
                    // customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                    customClassesForCells={[0, 1, 2, 3, 4, 5]}
                    customHeadCellClasses={[
                      classes.center,
                      classes.left + " " + classes.description,
                      // classes.center,
                      classes.center + " " + classes.connections,
                      classes.center,
                      classes.center,
                      classes.center,
                    ]}
                    // customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                    customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </GridItem>
      </GridContainer>
      {fileUploadDialog()}
      {inputDetailsDialog()}
      {resetInputsDialog()}
      {displayNotesDialog()}
    </div>
  );
}
