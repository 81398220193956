import React from "react";
import { useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import AuthContext from "../../contexts/AuthProvider";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import Popper from "@material-ui/core/Popper";

import styles from "../../assets/jss/material-dashboard-pro-react/components/buttonDropdownStyle";

export default function ButtonDropdown(props) {
  let { userDetails } = useContext(AuthContext);
  const client_styles = (theme) => ({
    ...styles(theme, userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();

  const {
    button,
    dropdownList,
    dropdownHeader,
    dropPlacement,
    closeAfterClick,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };
  const handleCloseMenu = (param) => {
    if (closeAfterClick) setAnchorEl(null);
    if (props && props.onClick) {
      props.onClick(param);
    }
  };

  const dropdownItem = classNames({
    [classes.dropdownItem]: true,
    [classes.clientHover]: true,
  });
  const dropDownMenu = (
    <MenuList role="menu" className={classes.menuList}>
      {dropdownHeader !== undefined ? (
        <MenuItem
          onClick={() => handleCloseMenu(dropdownHeader)}
          className={classes.dropdownHeader}
        >
          {dropdownHeader}
        </MenuItem>
      ) : null}
      {dropdownList.map((prop, key) => {
        let itemClasses = dropdownItem;
        if (prop.divider) {
          return (
            <Divider
              key={key}
              onClick={() => handleCloseMenu("divider")}
              className={classes.dropdownDividerItem}
            />
          );
        } else if (
          prop.props !== undefined &&
          prop.props["data-ref"] === "multi"
        ) {
          return (
            <MenuItem
              key={key}
              className={itemClasses}
              style={{ overflow: "visible", padding: 0 }}
            >
              {prop}
            </MenuItem>
          );
        } else if (prop.disabled) {
          return (
            <MenuItem
              key={key}
              onClick={() => handleCloseMenu(prop.text)}
              className={itemClasses}
              disabled
            >
              {prop.text}
            </MenuItem>
          );
        } else if (prop.explainer) {
          return (
            <MenuItem
              key={key}
              onClick={() => handleCloseMenu(prop.text)}
              className={itemClasses + " " + classes.dropdownExplainer}
              disabled
            >
              {prop.text}
            </MenuItem>
          );
        } else if (prop.identified) {
          if (prop.active) {
            return (
              <MenuItem
                key={key}
                onClick={() => handleCloseMenu(prop.identified)}
                className={itemClasses + " " + classes.dropdownItemActive}
              >
                {prop.text}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                key={key}
                onClick={() => handleCloseMenu(prop.identified)}
                className={itemClasses}
              >
                {prop.text}
              </MenuItem>
            );
          }
        }
        return (
          <MenuItem
            key={key}
            onClick={() => handleCloseMenu(key)}
            className={itemClasses}
          >
            {prop}
          </MenuItem>
        );
      })}
    </MenuList>
  );
  return (
    <span>
      <span
        aria-owns={anchorEl ? "menu-list" : null}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {button}
      </span>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement={dropPlacement}
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow in={Boolean(anchorEl)} id="menu-list">
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleClose}>
                {dropDownMenu}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </span>
  );
}

ButtonDropdown.defaultProps = {
  dropPlacement: "bottom-start",
};

ButtonDropdown.propTypes = {
  button: PropTypes.object,
  dropdownList: PropTypes.array,
  dropdownHeader: PropTypes.node,
  dropPlacement: PropTypes.oneOf([
    "bottom",
    "top",
    "right",
    "left",
    "bottom-start",
    "bottom-end",
    "top-start",
    "top-end",
    "right-start",
    "right-end",
    "left-start",
    "left-end",
  ]),
  // This is a function that returns the clicked menu item
  onClick: PropTypes.func,
  closeAfterClick: PropTypes.bool,
};
