import {
  blackColor,
  grayColor,
  whiteColor,
  dangerColor,
  successColor,
  hexToRgb,
  blendColors,
} from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const plExplorerStyle = (theme, clientColor) => ({
  ...customSelectStyle(theme, clientColor),
  right: {
    textAlign: "right",
  },
  center: {
    textAlign: "center",
  },
  topCardShortSpacing: {
    marginTop: "18px",
    marginBottom: "10px",
  },
  topCardName: {
    color: grayColor[0],
    "& span:first-child": {
      lineHeight: "2.5",
    },
  },
  topCardIcon: {
    color: grayColor[0],
    fontSize: "16px",
    cursor: "pointer",
    marginLeft: "8px",
    transition: "opacity 150ms",
    "&:hover": {
      color: grayColor[0],
      opacity: "0.5",
    },
    "&:before": {
      fontFamily: "'Font Awesome 5 Free'",
      content: "'\\f0b0'",
      padding: "0 0 0 2px",
      fontWeight: "900",
    },
  },
  topCardBody: {
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
  },
  topCardButtonContainer: {
    padding: "5px !important",
  },
  topCardButton: {
    margin: "0 !important",
  },
  explorerSpinner: {
    position: "absolute",
    top: "50%",
    right: "20px",
    transform: "translate(0, -50%)",
  },
  drawerSpinner: {
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  plCardWrapper: {
    paddingLeft: "10px !important",
    paddingRight: "0 !important",
  },
  plWrapperOuter: {
    margin: "0 -10px",
    // padding: "0",
  },
  plCard: {
    marginTop: "0",
  },
  plCardBody: {
    padding: "0",
  },
  plHeader: {
    color: whiteColor,
    borderRadius: "5px 5px 0 0",
    paddingLeft: "5px",
    textAlign: "left",
    fontSize: "12px",
    paddingTop: "2px",
    paddingBottom: "2px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0",
    backgroundColor: clientColor,
  },
  plName: {
    maxWidth: "80%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  plActionWrapper: {
    fontSize: "14px",
    position: "absolute",
    right: "5px",
    top: "3px",
  },
  plAction: {
    display: "inline",
    margin: "0 5px",
    cursor: "pointer",
  },
  filterModalHeaderScope: {
    color: grayColor[0],
  },
  filterModalHeaderFilterName: {
    fontWeight: "bold",
  },
  filterModalOrDividerContainer: {
    height: "100%",
    paddingTop: "8px",
  },
  orDivider: {
    color: grayColor[0],
    fontSize: "16px",
    textAlign: "center",
    margin: "-4px 0 0",
  },
  firstArrow: {
    marginTop: "20px",
  },
  arrow: {
    textAlign: "center",
    fontSize: "22px",
    color: grayColor[5],
    opacity: "0.6",
    transition: "all 0.2s",
  },
  arrowSelected: {
    color: clientColor,
    transform: "scale(2)",
    opacity: "1",
  },
  filterModalActionContainer: {
    paddingRight: "5px !important",
  },
  filterModalAction: {
    color: grayColor[0],
    fontSize: "12px",
    cursor: "pointer",
    "&:hover": {
      color: grayColor[6],
    },
  },
  filterModalCounter: {
    textAlign: "right",
    color: grayColor[0],
    fontSize: "12px",
  },
  plNameModalCharCounter: {
    marginTop: "3px",
    color: grayColor[0],
    fontSize: "12px",
  },
  marginBottomSmall: {
    marginBottom: "2px !important",
  },
  actionWrapperOuter: {
    paddingLeft: "10px !important",
    paddingRight: "10px !important",
  },
  actionWrapper: {
    cursor: "pointer",
    opacity: "0.8",
    transition: "opacity 0.1s",
    "&:hover,&:active": {
      opacity: "1",
      "& div:first-child": {
        boxShadow: "0 0px 30px -10px rgba(" + hexToRgb(blackColor) + ", 0.3)",
      },
      "& div:first-child > svg": {
        transform: "scale(1.2)",
      },
    },
    "&:active": {
      "& div:first-child": {
        boxShadow:
          "0 0px 30px -10px rgba(" + hexToRgb(blackColor) + ", 0.7) !important",
      },
    },
  },
  actionWrapperAttention: {
    opacity: "1 !important",
  },
  actionDisabled: {
    opacity: "0.5 !important",
    pointerEvents: "none",
  },
  actionIcon: {
    color: grayColor[6],
    margin: "6px auto 0",
    width: "42px",
    height: "42px",
    border: "1px solid " + grayColor[11],
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "58px",
    transition: "box-shadow 0.2s",
    "& svg": {
      marginLeft: "1px",
      transition: "transform 0.2s",
      width: "26px",
      height: "26px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "26px",
      fontSize: "26px",
    },
  },
  actionAttention: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    borderColor: whiteColor,
    animation: "$attention 3s ease infinite",
  },
  "@keyframes attention": {
    "10%": {
      transform: "scale(1.2)",
    },
    "13.33%,20%": {
      transform: "rotate(-15deg) scale(1.2)",
    },
    "16.67%": {
      transform: "rotate(15deg) scale(1.2)",
    },
    "23.33%": {
      transform: "rotate(0deg) scale(1.2)",
    },
    "33%": {
      transform: "scale(1)",
    },
  },
  actionText: {
    color: grayColor[0],
    marginTop: "6px",
    marginBottom: "2px",
    textAlign: "center",
    fontSize: "10px",
  },
  plDisplay: {
    opacity: 0,
    transition: "opacity 0.5s",
    "&.show": {
      opacity: 1,
    },
  },
  plOuterWrapper: {
    width: "100%",
    padding: "4px 0",
    display: "inline-block",
    fontSize: "12px",
    color: grayColor[6],
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  plErrorCover: {
    width: "100%",
    height: "calc(100% - 32px)",
    position: "absolute",
    backgroundColor: "white",
    opacity: "0.7",
    "&:hover": {},
  },
  plErrorRefreshIcon: {
    color: clientColor,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& > svg": {
      height: "100px",
      width: "100px",
      cursor: "pointer",
      transition: "all 0.2s",
    },
    "&:hover": {
      "& > svg": {
        transform: "scale(1.2)",
      },
    },
  },
  plErrorMessage: {
    textAlign: "center",
    fontSize: "1.25vmin",
    padding: "0 6px",
    position: "absolute",
    left: "50%",
    bottom: "0",
    transform: "translate(-50%, -100%)",
    borderRadius: "3px",
    color: whiteColor,
    backgroundColor: dangerColor[0],
    opacity: "1",
  },
  plLineWrapper: {
    width: "100%",
    minHeight: "24px",
    display: "flex",
    lineHeight: "2",
    "& > div:first-child": {
      paddingLeft: "4px",
    },
    "& > div:last-child": {
      paddingRight: "4px",
    },
    transition: "opacity 0.2s",
    cursor: "pointer",
  },
  plPercTotalVolume: {
    fontSize: "10px",
    lineHeight: "12px",
    color: grayColor[0],
    fontStyle: "italic",
  },
  plTableHeader: {
    color: whiteColor,
    backgroundColor: grayColor[6],
  },
  plTotal: {
    backgroundColor: grayColor[8],
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  plSubTotal: {
    backgroundColor: grayColor[13],
    "& > div:first-child": {
      paddingLeft: "12px !important",
    },
  },
  plLevel3: {
    color: grayColor[1],
  },
  plDrillDown: {
    "& > span:before": {
      fontFamily: "'Font Awesome 5 Free'",
      padding: "0 4px 0 0",
      fontWeight: "900",
      fontSize: "10px",
      cursor: "pointer",
    },
  },
  plDrillDownOpen: {
    "& > span:before": {
      content: "'\\f146'",
    },
  },
  plDrillDownClosed: {
    "& > span:before": {
      content: "'\\f0fe'",
    },
  },
  plLineDescription: {
    width: "55%",
    textOverflow: "ellipsis",
    "&.hidden": {
      display: "none",
    },
  },
  plIndentL2: {
    paddingLeft: "14px !important",
    "& > span:before": {
      color: grayColor[4],
      content: "'\\2517'",
      padding: "0 3px 0 0",
      fontSize: "10px",
    },
  },
  plNumber: {
    fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace;',
  },
  plValue: {
    width: "22.5%",
    textAlign: "right",
    "&.negative": {
      color: dangerColor[0],
    },
    // "&.expanded": {
    //   width: "50%",
    // },
    "&.currency.expanded": {
      width: "44.444%",
    },
    "&.percentual.expanded": {
      width: "55.555%",
    },
    "&.currency:not(.expanded)": {
      width: "20%",
    },
    "&.percentual:not(.expanded)": {
      width: "25%",
    },
  },
  plSpinner: {
    maxWidth: "20px",
  },
  plVolumeValue: {
    width: "45%",
    "&.expanded": {
      width: "100% !important",
    },
  },
  plUnit: {
    color: grayColor[0],
  },
  plComparison: {
    "&:before": {
      content: "'\\25cf'",
      marginRight: "4px",
      fontSize: "18px",
      lineHeight: "12px",
    },
    "&.n1:before": {
      color: "#F44336",
    },
    "&.n2:before": {
      color: "#F76C24",
    },
    "&.n3:before": {
      color: "#FB9612",
    },
    "&.n4:before": {
      color: "#FFC000",
    },
    "&.n5:before": {
      color: "#F6C737",
    },
    "&.n6:before": {
      color: "#EECE6E",
    },
    "&.n7:before": {
      color: "#E5D5A5",
    },
    "&.n8:before": {
      color: "#DDDDDD",
    },
    "&.n9:before": {
      color: "#C8D6C8",
    },
    "&.n10:before": {
      color: "#B3CFB4",
    },
    "&.n11:before": {
      color: "#9EC9A0",
    },
    "&.n12:before": {
      color: "#8AC28C",
    },
    "&.n13:before": {
      color: "#75BC78",
    },
    "&.n14:before": {
      color: "#60B564",
    },
    "&.n15:before": {
      color: "#4CAF50",
    },
  },
  plLoadingDetails: {
    backgroundSize: "200% 100%",
    backgroundImage:
      "linear-gradient(to right, " +
      blendColors(grayColor[8], clientColor, 0.9) +
      " 50%, " +
      blendColors(grayColor[8], clientColor, 0.8) +
      " 50%)",
    "-webkit-transition": "background-position 3s ease-in-out",
    "-moz-transition": "background-position 3s ease-in-out",
    transition: "background-position 3s ease-in-out",
    "&:hover": {
      backgroundPosition: "-100% 0",
    },
  },
  modalFootnote: {
    marginTop: "8px",
    paddingRight: "6px",
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "1.5",
    "& > ul": {
      paddingLeft: "18px",
    },
    "& > ul > li": {
      marginTop: "4px",
    },
  },
  drawerHeader: {
    borderBottom: "none",
    padding: "12px 12px 0 12px",
  },
  drawerHeaderValues: {
    paddingTop: "6px",
    fontWeight: "bold",
  },
  drawerContentWrapper: {
    width: "40vw",
    marginTop: "6px",
    padding: "0 2px",
  },
  drawerSectionTitle: {
    padding: "0 10px",
    fontSize: "14px",
    marginBottom: "0",
  },
  drawerSectionTitleIcon: {
    marginLeft: "5px",
    fontSize: "18px",
    "&.positiveTrend": {
      color: successColor[0],
    },
    "&.negativeTrend": {
      color: dangerColor[0],
    },
  },
  drawerHeaderScope: {
    fontSize: "12px",
    color: grayColor[6],
  },
  drawerTable: {
    width: "100%",
    fontSize: "12px",
    color: grayColor[6],
    whiteSpace: "nowrap",
    overflow: "hidden",
    paddingBottom: "6px",
  },
  drawerGraph: {
    width: "100%",
    padding: "18px 12px 6px 12px",
    backgroundColor: grayColor[6],
  },
  drawerTableContent: {
    textAlign: "center",
    display: "flex",
    "& > div": {
      padding: "0 !important",
    },
    "& > div:first-child": {
      width: "10%",
    },
    "& > div:not(:first-child)": {
      width: "30%",
    },
  },
  drawerTableHeader: {
    color: whiteColor,
    backgroundColor: grayColor[6],
    // backgroundColor: blendColors(grayColor[6], clientColor, 0.7),
  },
  drawerTableData: {
    color: blackColor,
    marginTop: "2px",
  },
  drawerFootnote: {
    marginTop: "12px",
    maxWidth: "40vw",
    paddingRight: "6px",
    color: grayColor[0],
    fontSize: "10px",
    lineHeight: "1.25",
    "& > ul": {
      paddingLeft: "30px",
    },
    "& > ul > li": {
      marginTop: "3px",
    },
  },
  drawerPlAppliedFiltersWrapper: {
    marginTop: "-4px",
    width: "100%",
    paddingRight: "8px",
    marginBottom: "20px",
  },
  plAppliedFiltersWrapper: {
    width: "100%",
    minHeight: "20px",
    paddingTop: "12px",
    paddingBottom: "4px",
    borderRadius: "5px",
    position: "relative",
    margin: "24px 4px 4px 4px",
    border: grayColor[4] + " 1px solid",
  },
  plAppliedFiltersHeader: {
    display: "inline-block",
    position: "absolute",
    top: "-12px",
    left: "5px",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
    padding: "0 5px",
    background: whiteColor,
    "&.modal": {
      color: grayColor[0],
    },
    "&.drawer": {
      color: grayColor[6],
    },
  },
  positive: {
    color: successColor[0],
  },
  negative: {
    color: dangerColor[0],
  },
  highlighted: {
    backgroundColor:
      blendColors(grayColor[8], clientColor, 0.9) + " !important",
  },
  faded: {
    opacity: "0.3",
  },
  hidden: {
    display: "none",
  },
  ...modalStyle(theme),
});

export default plExplorerStyle;
