export const useBackend = true;
export const baseUrl =
  process.env.NODE_ENV && process.env.NODE_ENV === "development" && !useBackend
    ? "http://localhost:8000/"
    : "https://api.profit-insights.int.digital/";

export const getOom = (median) => {
  let oom = {};
  console.log(Math.abs(median));
  if (Math.abs(median) >= 1e9) {
    oom = {
      div: 1e9,
      x: "b",
    };
  } else if (Math.abs(median) >= 1e6) {
    oom = {
      div: 1e6,
      x: "m",
    };
  } else if (Math.abs(median) >= 1e3) {
    oom = {
      div: 1e3,
      x: "k",
    };
  } else {
    oom = {
      div: 1,
      x: "",
    };
  }
  return oom;
};
