import { createAxiosClient } from "./createAxiosClient";
import { store } from "../store";
import {
  setAuthTokens,
  setUser,
  // setUserDetails,
} from "../reducers/authReducer";
import { setProgress } from "../reducers/fileTransferReducer";
import { jwtDecode } from "jwt-decode";
import { baseUrl } from "utils/utils";

const refreshTokenUrl = baseUrl + "token/refresh/";

function getCurrentAccessToken() {
  let tokens = store.getState().auth.authTokens;
  return tokens ? tokens.access : null;
}

function getCurrentRefreshToken() {
  let tokens = store.getState().auth.authTokens;
  return tokens ? tokens.refresh : null;
}

function setRefreshedTokens(tokens) {
  store.dispatch(setAuthTokens(tokens));
  store.dispatch(setUser(jwtDecode(tokens.access)));
}

async function logout() {
  store.dispatch(setAuthTokens(null));
  store.dispatch(setUser(null));
}

export const client = createAxiosClient({
  options: {
    baseURL: baseUrl,
    timeout: 120000,
    headers: {
      "Content-Type": "application/json",
    },
  },
  getCurrentAccessToken,
  getCurrentRefreshToken,
  refreshTokenUrl: refreshTokenUrl,
  logout,
  setRefreshedTokens,
});

export const fileUploadClient = createAxiosClient({
  options: {
    baseURL: baseUrl,
    timeout: 600000,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const progress = Math.round(
        (100 * progressEvent.loaded) / progressEvent.total,
      );
      store.dispatch(setProgress(progress));
    },
  },
  getCurrentAccessToken,
  getCurrentRefreshToken,
  refreshTokenUrl: refreshTokenUrl,
  logout,
  setRefreshedTokens,
});

export const fileDownloadClient = createAxiosClient({
  options: {
    baseURL: baseUrl,
    timeout: 120000,
    responseType: "blob",
  },
  getCurrentAccessToken,
  getCurrentRefreshToken,
  refreshTokenUrl: refreshTokenUrl,
  logout,
  setRefreshedTokens,
});
