import React, { useContext } from "react";
import AuthContext from "../../contexts/AuthProvider";
import PropTypes from "prop-types";
import { ThreeDots } from "react-loader-spinner";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";

import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";

import styles from "../../assets/jss/material-dashboard-pro-react/components/actionBarStyle";
import GridItem from "../Grid/GridItem";
const useStyles = makeStyles(styles);

export default function ActionBar(props) {
  let { userDetails } = useContext(AuthContext);
  const { t } = useTranslation();
  const { actions, spinnerIn, sm } = props;
  const classes = useStyles();
  return (
    <Card
      className={cx({
        [classes.actionBarWrapper]: !sm,
        [classes.actionBarSmWrapper]: sm,
      })}
    >
      <CardBody
        className={cx({
          [classes.actionBarCardBody]: !sm,
          [classes.actionBarSmCardBody]: sm,
        })}
      >
        <GridContainer alignItems={"center"}>
          {sm && (
            <GridItem>
              <div className={classes.actionBarSmCardName}>
                <span>{t("results.actions")}</span>
              </div>
            </GridItem>
          )}
          {actions}
          {/* Spinner */}
          {spinnerIn && !sm && (
            <div className={classes.actionsBarSpinnerWrapper}>
              <div className={classes.actionsBarSpinner}>
                <ThreeDots
                  height="18px"
                  color={userDetails.implementation_color}
                  ariaLabel="loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            </div>
          )}
          {sm && (
            <div className={classes.actionBarSmSpinner}>
              <ThreeDots
                visible={spinnerIn}
                height="18px"
                color={userDetails.implementation_color}
                ariaLabel="loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          )}
        </GridContainer>
      </CardBody>
    </Card>
  );
}

ActionBar.propTypes = {
  actions: PropTypes.element.isRequired,
  spinnerIn: PropTypes.bool.isRequired,
  sm: PropTypes.bool,
};
