import {
  successColor,
  dangerColor,
  tooltip,
  cardTitle,
  grayColor,
  warningColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";

const overviewStyle = {
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center",
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0",
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: grayColor[0],
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "3px",
      width: "16px",
      height: "16px",
      marginRight: "5px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "3px",
      fontSize: "16px",
      marginRight: "3px",
    },
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0",
  },
  successText: {
    color: successColor[0],
  },
  dangerText: {
    color: dangerColor[0],
  },
  upArrowCardCategory: {
    width: 14,
    height: 14,
  },
  underChartIcons: {
    width: "17px",
    height: "17px",
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },
  successColor: {
    color: successColor[0],
  },
  warningColor: {
    color: warningColor[1],
  },
  cardSpinner: {
    display: "flex",
    justifyContent: "flex-end",
    "& > div > svg": {
      margin: "0 !important",
    },
  },
  tableSpinner: {
    position: "absolute",
    marginLeft: "-5px",
    backgroundColor: whiteColor,
    width: "100%",
    minHeight: "288px",
    "& > div": {
      position: "absolute",
      top: "45%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  cardsWrapper: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

export default overviewStyle;
