import React from "react";
import { useState, useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import AuthContext from "../../contexts/AuthProvider";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import Popper from "@material-ui/core/Popper";
import Tooltip from "@material-ui/core/Tooltip";

import styles from "../../assets/jss/material-dashboard-pro-react/components/filterDropdownStyle";

export default function FilterDropdown(props) {
  let { userDetails } = useContext(AuthContext);
  const client_styles = (theme) => ({
    ...styles(theme, userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleClick = (event) => {
    setTooltipOpen(false);
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };
  const handleCloseMenu = (param) => {
    setAnchorEl(null);
    if (props && props.onClick) {
      props.onClick(param);
    }
  };
  const {
    button,
    dropdownList,
    dropdownActiveItems,
    dropdownFilterSequence,
    dropdownHeader,
    hoverColor,
    dropPlacement,
    tooltip,
  } = props;
  const dropdownItem = (active = false, sequence = null) => {
    return classNames({
      [classes.dropdownItem]: true,
      [classes.dropdownItemActive]: active,
      ["sequence" + sequence]: sequence,
      [classes[hoverColor + "Hover"]]: true,
    });
  };
  const dropDownMenu = (
    <MenuList role="menu" className={classes.menuList}>
      {dropdownHeader !== undefined ? (
        <MenuItem
          onClick={() => handleCloseMenu(dropdownHeader)}
          className={classes.dropdownHeader}
        >
          {dropdownHeader}
        </MenuItem>
      ) : null}
      {dropdownList.map((prop, key) => {
        let itemClasses = dropdownItem(
          dropdownActiveItems.includes(key),
          dropdownFilterSequence[key],
        );
        if (prop.divider) {
          return (
            <Divider
              key={key}
              onClick={() => handleCloseMenu("divider")}
              className={classes.dropdownDividerItem}
            />
          );
        } else if (
          prop.props !== undefined &&
          prop.props["data-ref"] === "multi"
        ) {
          return (
            <MenuItem
              key={key}
              className={itemClasses}
              style={{ overflow: "visible", padding: 0 }}
            >
              {prop}
            </MenuItem>
          );
        } else if (prop.disabled) {
          return (
            <MenuItem
              key={key}
              onClick={() => handleCloseMenu(prop.text)}
              className={itemClasses}
              disabled
            >
              {prop.text}
            </MenuItem>
          );
        }
        return (
          <MenuItem
            key={key}
            onClick={() => handleCloseMenu(key)}
            className={itemClasses}
          >
            {prop}
          </MenuItem>
        );
      })}
    </MenuList>
  );
  return (
    <Tooltip
      title={tooltip}
      open={tooltipOpen}
      onMouseEnter={() => setTooltipOpen(!anchorEl)}
      onMouseOut={() => setTooltipOpen(false)}
    >
      <span>
        {button && (
          <span
            aria-owns={anchorEl ? "menu-list" : null}
            aria-haspopup="true"
            onClick={handleClick}
          >
            {button}
          </span>
        )}
        {!button && (
          <a
            aria-owns={anchorEl ? "menu-list" : null}
            aria-haspopup="true"
            className={classes.button}
            onClick={handleClick}
          ></a>
        )}
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          transition
          disablePortal
          placement={dropPlacement}
          className={classNames({
            [classes.popperClose]: !anchorEl,
            [classes.popperResponsive]: true,
          })}
        >
          {() => (
            <Grow in={Boolean(anchorEl)} id="menu-list">
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleClose}>
                  {dropDownMenu}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </span>
    </Tooltip>
  );
}

FilterDropdown.defaultProps = {
  hoverColor: "primary",
};

FilterDropdown.propTypes = {
  button: PropTypes.object,
  hoverColor: PropTypes.oneOf([
    "client",
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
  dropdownList: PropTypes.array,
  dropdownActiveItems: PropTypes.arrayOf(PropTypes.number),
  dropdownFilterSequence: PropTypes.arrayOf(PropTypes.string),
  dropdownHeader: PropTypes.node,
  dropPlacement: PropTypes.oneOf([
    "bottom",
    "top",
    "right",
    "left",
    "bottom-start",
    "bottom-end",
    "top-start",
    "top-end",
    "right-start",
    "right-end",
    "left-start",
    "left-end",
  ]),
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
