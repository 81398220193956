import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import {
  setAuthTokens,
  setUser,
  setUserDetails,
} from "../reducers/authReducer";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { baseUrl } from "../utils/utils";

const GoogleAuth = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setLoginError, setIsLoggedIn, setSocialLoggingIn, redir } = props;
  const userDetails = useSelector((state) => state.auth.userDetails);
  const login = useGoogleLogin({
    onSuccess: (response) => {
      setLoginError("Logging in with Google...");
      setSocialLoggingIn(true);
      fetch(baseUrl + "api/google/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_token: response.access_token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data && data.access && data.refresh) {
            dispatch(setAuthTokens(data));
            dispatch(setUser(jwtDecode(data.access)));
            setIsLoggedIn(true);
            if (redir && userDetails) {
              history.push(redir);
            } else {
              dispatch(setUserDetails(null));
              setTimeout(() => history.push("/welcome"), 800);
            }
          } else if (data && data.error) {
            setLoginError(data.error);
            setSocialLoggingIn(false);
          }
        })
        .catch((err) => {
          setLoginError("Error on Google login request");
          setSocialLoggingIn(false);
          console.log(err);
        });
    },
    onError: (response) => {
      setLoginError("Error on Google login request");
      setSocialLoggingIn(false);
      console.log(response);
    },
    onNonOAuthError: () => setLoginError(""),
  });

  return <i className="fab fa-google" onClick={() => login()} />;
};

export default GoogleAuth;

GoogleAuth.propTypes = {
  setLoginError: PropTypes.func.isRequired,
  setIsLoggedIn: PropTypes.func.isRequired,
  setIsLoggingIn: PropTypes.func.isRequired,
  setSocialLoggingIn: PropTypes.func.isRequired,
  redir: PropTypes.string,
};
