import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

library.add(faCircleQuestion);

import styles from "../../assets/jss/material-dashboard-pro-react/components/helpTooltipStyle";
const useStyles = makeStyles(styles);

const HelpTooltip = (props) => {
  const classes = useStyles();

  return (
    <Tooltip title={<span style={{ textAlign: "left" }}>{props.tooltip}</span>}>
      <span className={classes.helpTag}>
        <FontAwesomeIcon icon="circle-question" />
      </span>
    </Tooltip>
  );
};

HelpTooltip.propTypes = {
  tooltip: PropTypes.string,
};

export default HelpTooltip;
