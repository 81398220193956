import {
  defaultFont,
  container,
  containerFluid,
  // primaryColor,
  whiteColor,
  grayColor,
  integrationColor,
} from "assets/jss/material-dashboard-pro-react.js";

const footerStyle = (loggedIn) => ({
  footerContainer: {
    position: "relative",
    marginTop: "14px",
  },
  block: {},
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    margin: "0",
    fontSize: "14px",
    float: "right!important",
    padding: "1px 15px",
  },
  center: {
    textAlign: "center!important",
    // float: "center!important",
    display: "block",
  },
  footer: {
    position: "fixed",
    backdropFilter: "blur(8px)",
    backgroundColor: loggedIn ? "#eeeeee88" : whiteColor + "88",
    left: "0",
    right: "0",
    bottom: "0",
    borderTop: "1px solid " + grayColor[15],
    // padding: "15px 0",
    padding: "0",
    ...defaultFont,
    zIndex: 4,
  },
  container: {
    zIndex: 3,
    ...container,
    position: "relative",
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: "relative",
  },
  a: {
    color: "inherit",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&:visited": {
      color: "inherit",
    },
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto",
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: whiteColor,
    },
  },
  integrationGray: {
    "&,&:hover,&:focus": {
      color: integrationColor[1],
    },
  },
});
export default footerStyle;
