const termsStyle = (theme) => {
  return {
    container: {
      padding: "0 60px 20px 10px",
      margin: "0 auto",
      // maxWidth: "1800px",
      [theme.breakpoints.down("sm")]: {
        padding: "0 12px 20px 0",
      },
    },
    mainPanelWithPerfectScrollbar: {
      overflow: "hidden",
      position: "relative",
      float: "right",
      maxHeight: "100%",
      width: "100%",
      overflowScrolling: "touch",
    },
  };
};

export default termsStyle;
