import React from "react";
import { useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import AuthContext from "../../contexts/AuthProvider";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import styles from "assets/jss/material-dashboard-pro-react/components/topRightCardButtonStyle";

const TopRightCardButton = React.forwardRef((props, ref) => {
  let { userDetails } = useContext(AuthContext);
  const client_styles = () => ({
    ...styles(userDetails ? userDetails.implementation_color : "#eee"),
  });
  const useStyles = makeStyles(client_styles);

  const classes = useStyles();
  const {
    color,
    disabled,
    className,
    muiClasses,
    icon,
    tooltip,
    onClick,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[color]]: color,
    [classes.disabled]: disabled,
    [className]: className,
    [classes.topRightCardButton]: true,
  });
  return (
    <Tooltip
      placement={"top"}
      title={tooltip}
      PopperProps={{
        style: { marginBottom: "-8px" },
      }}
    >
      <Button
        {...rest}
        ref={ref}
        classes={muiClasses}
        className={btnClasses}
        onClick={onClick}
      >
        <div className={classes.iconWrapper}>
          <div className={classes.icon}>{icon}</div>
        </div>
      </Button>
    </Tooltip>
  );
});

TopRightCardButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent",
    "integration",
    "gray",
    "client",
  ]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  icon: PropTypes.object.isRequired,
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

TopRightCardButton.displayName = "TopRightCardButton";

export default TopRightCardButton;
