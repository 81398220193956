import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import cx from "classnames";
import Settings from "@material-ui/icons/Settings";
import {
  grayColor,
  whiteColor,
} from "../../assets/jss/material-dashboard-pro-react";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  container: {
    color: whiteColor,
    backgroundColor: grayColor[1],
    // opacity: "0.5",
    position: "fixed",
    width: "30px",
    height: "30px",
    bottom: "30px",
    right: "5px",
    borderRadius: "5px",
    zIndex: "9999",
    transform: "translate(200%)",
    transition: "all 0.5s",
    "&.visible": {
      transform: "translate(0)",
    },
  },
  icon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    width: "25px",
    height: "25px",
    animation: "$rotate 6s linear infinite",
  },
  "@keyframes rotate": {
    from: {
      "-ms-transform": "translate(-50%, -50%) rotate(0deg)",
      "-moz-transform": "translate(-50%, -50%) rotate(0deg)",
      "-webkit-transform": "translate(-50%, -50%) rotate(0deg)",
      "-o-transform": "translate(-50%, -50%) rotate(0deg)",
      transform: "translate(-50%, -50%) rotate(0deg)",
    },
    to: {
      " -ms-transform": "translate(-50%, -50%) rotate(360deg)",
      "-moz-transform": "translate(-50%, -50%) rotate(360deg)",
      "-webkit-transform": "translate(-50%, -50%) rotate(360deg)",
      "-o-transform": "translate(-50%, -50%) rotate(360deg)",
      transform: "translate(-50%, -50%), rotate(360deg)",
    },
  },
}));

const FloatingLoading = (props) => {
  const classes = useStyles();
  return (
    <div
      aria-label="loading"
      className={cx({
        [classes.container]: true,
        ["visible"]: props.visible,
      })}
    >
      <Settings className={classes.icon} />
    </div>
  );
};

FloatingLoading.propTypes = {
  visible: PropTypes.bool,
};
FloatingLoading.defaultProps = {
  visible: true,
};

export default FloatingLoading;
