/*eslint-disable*/
import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { useContext } from "react";
import AuthContext from "../contexts/AuthProvider";
import { ToastContainer } from "react-toastify";
import routes from "routes.js";
import "assets/css/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { client } from "services/axiosClient";

import { makeStyles } from "@material-ui/core/styles";

// core components
import MainNavbar from "../components/Navbars/MainNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import MenuItem from "@material-ui/core/MenuItem";

import ChangePasswordDialog from "../components/Dialog/ChangePasswordDialog";
import ChangeProjectDialog from "../components/Dialog/ChangeProjectDialog";

let ps;

export default function Main(props) {
  // ensures that the access to this content is granted only if user exists
  let { user, userDetails, logoutUser, authTokens } = useContext(AuthContext);
  if (!user || !userDetails) {
    if (!userDetails || window.location.pathname === "/auth/login") {
      return <Redirect to={`/auth/login`} />;
    } else {
      return (
        <Redirect
          to={`/auth/login?redir=${window.location.pathname + window.location.search}`}
        />
      );
    }
  }
  const client_styles = (theme) => ({
    ...styles(theme, userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();

  const { t } = useTranslation();
  const { ...rest } = props;

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(true);
  const [changePasswordModalOpen, setChangePasswordModalOpen] =
    React.useState(false);
  const [changeProjectModalOpen, setChangeProjectModalOpen] =
    React.useState(false);
  const [currentProject, setCurrentProject] = React.useState(
    userDetails.implementation_code,
  );
  const [changingProject, setChangingProject] = React.useState(false);
  const logo = userDetails.implementation_logo_white;
  // styles
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.allowedRoles &&
        !prop.allowedRoles.includes(userDetails.user_role)
      ) {
        return null;
      }
      if (
        prop.showOnlyWhenRedirected &&
        window.location.href.indexOf(prop.layout + prop.path) === -1
      ) {
        return null;
      }
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/main") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const projectOptions = () => {
    if (userDetails.implementation_ids.length > 1) {
      return userDetails.implementation_ids.map((imp, key) => (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={imp[0]}
          key={key}
        >
          {imp[1] +
            (imp[0] === userDetails.implementation_code
              ? " (" + t("change_project.current") + ")"
              : "")}
        </MenuItem>
      ));
    } else {
      return null;
    }
  };
  const handleChangeProjectSelect = (event) => {
    setCurrentProject(event.target.value);
  };
  const handleChangeProjectConfirm = () => {
    let params = {
      implementation_code: currentProject,
      refresh_token: authTokens.refresh,
    };
    setChangingProject(true);
    client
      .post("api/change_project/", params)
      .then((response) => {
        if (response.data.success) {
          logoutUser();
        } else {
          console.error(response.data);
        }
        setChangingProject(false);
      })
      .catch((error) => {
        console.log(error);
        setChangingProject(false);
      });
  };

  const changePasswordDialog = (
    <ChangePasswordDialog
      open={changePasswordModalOpen}
      setOpen={setChangePasswordModalOpen}
      userDetails={userDetails}
    />
  );
  const changeProjectDialog = (
    <ChangeProjectDialog
      open={changeProjectModalOpen}
      setOpen={setChangeProjectModalOpen}
      projectOptions={projectOptions}
      currentProject={currentProject}
      changingProject={changingProject}
      handleChangeProjectSelect={handleChangeProjectSelect}
      handleChangeProjectConfirm={handleChangeProjectConfirm}
      userDetails={userDetails}
    />
  );

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={userDetails.implementation_name}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"client"}
        miniActive={miniActive}
        {...rest}
        setChangePasswordModalOpen={setChangePasswordModalOpen}
        setChangeProjectModalOpen={setChangeProjectModalOpen}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <MainNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <ToastContainer />
          <div className={classes.container}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/main" to="/main/no-permission" />
            </Switch>
          </div>
        </div>
        <Footer fluid />
        {userDetails.social_login === false && changePasswordDialog}
        {changeProjectDialog}
      </div>
    </div>
  );
}
