import React from "react";
import { useContext } from "react";
import classNames from "classnames";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AuthContext from "../../contexts/AuthProvider";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/filterTagStyle.js";

export default function FilterTag(props) {
  let { userDetails } = useContext(AuthContext);
  const client_styles = () => ({
    ...styles(userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const {
    filtersList,
    filtersNames,
    filtersGroups,
    filter,
    items,
    indexes,
    mode,
    nameClickHandler,
    removeItemHandler,
    color,
    viewOnly,
  } = props;

  if (items.length === 0) {
    return null;
  }
  const categoryCallback =
    viewOnly || !nameClickHandler ? null : () => nameClickHandler(filter);

  const categoryTag = (
    <span
      className={cx({
        [classes.categoryTag]: true,
        ["cursorPointer"]: !viewOnly,
      })}
      onClick={categoryCallback}
    >
      {filtersNames[filter][i18n.language]}
    </span>
  );

  let itemsTags;
  const itemTagsClasses = classNames({
    [classes.itemTag]: true,
    [classes["color" + ((filtersList.indexOf(filter) % 12) + 1)]]: !color,
    [classes[color]]: color,
  });

  if (mode === "group") {
    itemsTags = (
      <span className={itemTagsClasses}>
        {filtersGroups[filter][items[0]][i18n.language]}
        {!viewOnly && (
          <a onClick={() => removeItemHandler(filter, items[0], 0, false)}></a>
        )}
      </span>
    );
  } else if (items.length <= 5) {
    itemsTags = items.map((item, key) => (
      <span key={key} className={itemTagsClasses}>
        {item === "" ? "● " + t("results.blank") + " ●" : item}
        {!viewOnly && (
          <a
            onClick={() => removeItemHandler(filter, item, indexes[key], false)}
          ></a>
        )}
      </span>
    ));
  } else {
    itemsTags = (
      <span className={itemTagsClasses}>
        {items.length + " " + t("filter_tag.items_selected")}
        {!viewOnly && (
          <a onClick={() => removeItemHandler(filter, "", 0, true)}></a>
        )}
      </span>
    );
  }

  return (
    <div className={classes.categoryWrapper}>
      {categoryTag}
      {itemsTags}
    </div>
  );
}

FilterTag.propTypes = {
  filtersList: PropTypes.arrayOf(PropTypes.string).isRequired,
  filtersNames: PropTypes.object.isRequired,
  filtersGroups: PropTypes.object,
  filter: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  indexes: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
  mode: PropTypes.string.isRequired,
  nameClickHandler: PropTypes.func,
  removeItemHandler: PropTypes.func,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "client",
    "gray",
    "light",
  ]),
  viewOnly: PropTypes.bool,
};
